export const regionsData = [
    {
      "id": "225",
      "name": "Россия",
      "count": 93608,
      "chld": [
        {
          "id": "1",
          "name": "Москва и Московская область",
          "count": 33942,
          "chld": [
            {
              "id": "213",
              "name": "Москва",
              "count": 24232
            },
            {
              "id": "10725",
              "name": "Домодедово",
              "count": 1197
            },
            {
              "id": "10716",
              "name": "Балашиха",
              "count": 392
            },
            {
              "id": "10747",
              "name": "Подольск",
              "count": 242
            },
            {
              "id": "10758",
              "name": "Химки",
              "count": 223
            },
            {
              "id": "20728",
              "name": "Королёв",
              "count": 211
            },
            {
              "id": "10743",
              "name": "Одинцово",
              "count": 207
            },
            {
              "id": "10740",
              "name": "Мытищи",
              "count": 203
            },
            {
              "id": "10750",
              "name": "Раменское",
              "count": 185
            },
            {
              "id": "10738",
              "name": "Люберцы",
              "count": 176
            },
            {
              "id": "10735",
              "name": "Красногорск",
              "count": 148
            },
            {
              "id": "10765",
              "name": "Щёлково",
              "count": 148
            },
            {
              "id": "10748",
              "name": "Пушкино",
              "count": 146
            },
            {
              "id": "10734",
              "name": "Коломна",
              "count": 127
            },
            {
              "id": "10752",
              "name": "Сергиев Посад",
              "count": 124
            },
            {
              "id": "20523",
              "name": "Электросталь",
              "count": 124
            },
            {
              "id": "21641",
              "name": "Лобня",
              "count": 120
            },
            {
              "id": "10719",
              "name": "Видное",
              "count": 111
            },
            {
              "id": "214",
              "name": "Долгопрудный",
              "count": 111
            },
            {
              "id": "10733",
              "name": "Клин",
              "count": 101
            },
            {
              "id": "20571",
              "name": "Жуковский",
              "count": 83
            },
            {
              "id": "10742",
              "name": "Ногинск",
              "count": 79
            },
            {
              "id": "10723",
              "name": "Дмитров",
              "count": 75
            },
            {
              "id": "21627",
              "name": "Дедовск",
              "count": 70
            },
            {
              "id": "215",
              "name": "Дубна",
              "count": 64
            },
            {
              "id": "21621",
              "name": "Реутов",
              "count": 60
            },
            {
              "id": "21623",
              "name": "Ивантеевка",
              "count": 52
            },
            {
              "id": "10741",
              "name": "Наро-Фоминск",
              "count": 50
            },
            {
              "id": "21651",
              "name": "Котельники",
              "count": 49
            },
            {
              "id": "21619",
              "name": "Фрязино",
              "count": 49
            },
            {
              "id": "10745",
              "name": "Орехово-Зуево",
              "count": 47
            },
            {
              "id": "10761",
              "name": "Чехов",
              "count": 47
            },
            {
              "id": "10754",
              "name": "Серпухов",
              "count": 44
            },
            {
              "id": "10756",
              "name": "Ступино",
              "count": 44
            },
            {
              "id": "10722",
              "name": "Воскресенск",
              "count": 43
            },
            {
              "id": "10731",
              "name": "Истра",
              "count": 43
            },
            {
              "id": "37144",
              "name": "Куровское",
              "count": 41
            },
            {
              "id": "21735",
              "name": "Дзержинский",
              "count": 26
            },
            {
              "id": "219",
              "name": "Черноголовка",
              "count": 24
            },
            {
              "id": "21630",
              "name": "Лыткарино",
              "count": 23
            },
            {
              "id": "10727",
              "name": "Егорьевск",
              "count": 22
            },
            {
              "id": "21647",
              "name": "Краснознаменск",
              "count": 22
            },
            {
              "id": "10746",
              "name": "Павловский Посад",
              "count": 22
            },
            {
              "id": "21625",
              "name": "Кубинка",
              "count": 21
            },
            {
              "id": "10729",
              "name": "Звенигород",
              "count": 20
            },
            {
              "id": "217",
              "name": "Пущино",
              "count": 15
            },
            {
              "id": "21645",
              "name": "Хотьково",
              "count": 14
            },
            {
              "id": "10728",
              "name": "Зарайск",
              "count": 13
            },
            {
              "id": "10755",
              "name": "Солнечногорск",
              "count": 13
            },
            {
              "id": "10717",
              "name": "Бронницы",
              "count": 10
            },
            {
              "id": "20576",
              "name": "Протвино",
              "count": 10
            },
            {
              "id": "10762",
              "name": "Шатура",
              "count": 10
            },
            {
              "id": "10739",
              "name": "Можайск",
              "count": 9
            },
            {
              "id": "10732",
              "name": "Кашира",
              "count": 8
            },
            {
              "id": "10715",
              "name": "Апрелевка",
              "count": 7
            },
            {
              "id": "10737",
              "name": "Луховицы",
              "count": 7
            },
            {
              "id": "10721",
              "name": "Волоколамск",
              "count": 6
            },
            {
              "id": "21635",
              "name": "Лосино-Петровский",
              "count": 6
            },
            {
              "id": "103819",
              "name": "Пересвет",
              "count": 6
            },
            {
              "id": "10751",
              "name": "Руза",
              "count": 6
            },
            {
              "id": "21646",
              "name": "Голицыно",
              "count": 5
            },
            {
              "id": "100471",
              "name": "Красноармейск",
              "count": 5
            },
            {
              "id": "10744",
              "name": "Озёры",
              "count": 5
            },
            {
              "id": "21656",
              "name": "Старая Купавна",
              "count": 5
            },
            {
              "id": "21632",
              "name": "Белоозерский",
              "count": 4
            },
            {
              "id": "37120",
              "name": "Электрогорск",
              "count": 3
            },
            {
              "id": "21642",
              "name": "Электроугли",
              "count": 3
            },
            {
              "id": "21648",
              "name": "Высоковск",
              "count": 2
            },
            {
              "id": "103816",
              "name": "Краснозаводск",
              "count": 1
            },
            {
              "id": "37141",
              "name": "Ликино-Дулево",
              "count": 1
            },
            {
              "id": "20735",
              "name": "Яхрома",
              "count": 1
            }
          ]
        },
        {
          "id": "10174",
          "name": "Санкт-Петербург и Ленинградская область",
          "count": 9560,
          "chld": [
            {
              "id": "2",
              "name": "Санкт-Петербург",
              "count": 8117
            },
            {
              "id": "118936",
              "name": "Мурино",
              "count": 51
            },
            {
              "id": "10867",
              "name": "Гатчина",
              "count": 39
            },
            {
              "id": "118515",
              "name": "Кудрово",
              "count": 37
            },
            {
              "id": "10865",
              "name": "Всеволожск",
              "count": 35
            },
            {
              "id": "969",
              "name": "Выборг",
              "count": 30
            },
            {
              "id": "10878",
              "name": "Отрадное",
              "count": 22
            },
            {
              "id": "10871",
              "name": "Кириши",
              "count": 20
            },
            {
              "id": "10891",
              "name": "Сосновый Бор",
              "count": 15
            },
            {
              "id": "10870",
              "name": "Кингисепп",
              "count": 14
            },
            {
              "id": "10892",
              "name": "Тихвин",
              "count": 14
            },
            {
              "id": "10887",
              "name": "Сертолово",
              "count": 13
            },
            {
              "id": "10864",
              "name": "Волхов",
              "count": 11
            },
            {
              "id": "10872",
              "name": "Кировск",
              "count": 8
            },
            {
              "id": "21666",
              "name": "Коммунар",
              "count": 4
            },
            {
              "id": "10876",
              "name": "Луга",
              "count": 4
            },
            {
              "id": "10888",
              "name": "Сланцы",
              "count": 4
            },
            {
              "id": "10893",
              "name": "Тосно",
              "count": 2
            },
            {
              "id": "10863",
              "name": "Волосово",
              "count": 1
            },
            {
              "id": "10868",
              "name": "Ивангород",
              "count": 1
            },
            {
              "id": "10874",
              "name": "Лодейное Поле",
              "count": 1
            },
            {
              "id": "10881",
              "name": "Подпорожье",
              "count": 1
            },
            {
              "id": "10883",
              "name": "Приозерск",
              "count": 1
            },
            {
              "id": "10886",
              "name": "Светогорск",
              "count": 1
            },
            {
              "id": "22914",
              "name": "Сясьстрой",
              "count": 1
            },
            {
              "id": "21795",
              "name": "Шлиссельбург",
              "count": 1
            }
          ]
        },
        {
          "id": "11079",
          "name": "Нижегородская область",
          "count": 2651,
          "chld": [
            {
              "id": "47",
              "name": "Нижний Новгород",
              "count": 2245
            },
            {
              "id": "11080",
              "name": "Арзамас",
              "count": 52
            },
            {
              "id": "20044",
              "name": "Кстово",
              "count": 39
            },
            {
              "id": "972",
              "name": "Дзержинск",
              "count": 23
            },
            {
              "id": "11083",
              "name": "Саров",
              "count": 18
            },
            {
              "id": "20040",
              "name": "Выкса",
              "count": 13
            },
            {
              "id": "20042",
              "name": "Заволжье",
              "count": 8
            },
            {
              "id": "20036",
              "name": "Богородск",
              "count": 5
            },
            {
              "id": "20037",
              "name": "Бор",
              "count": 5
            },
            {
              "id": "20035",
              "name": "Балахна",
              "count": 4
            },
            {
              "id": "20045",
              "name": "Кулебаки",
              "count": 3
            },
            {
              "id": "11082",
              "name": "Павлово",
              "count": 3
            },
            {
              "id": "20041",
              "name": "Городец",
              "count": 2
            },
            {
              "id": "20043",
              "name": "Княгинино",
              "count": 2
            },
            {
              "id": "20053",
              "name": "Чкаловск",
              "count": 2
            },
            {
              "id": "20047",
              "name": "Лысково",
              "count": 1
            },
            {
              "id": "20609",
              "name": "Перевоз",
              "count": 1
            },
            {
              "id": "20050",
              "name": "Семенов",
              "count": 1
            },
            {
              "id": "20054",
              "name": "Шахунья",
              "count": 1
            }
          ]
        },
        {
          "id": "11162",
          "name": "Свердловская область",
          "count": 2541,
          "chld": [
            {
              "id": "54",
              "name": "Екатеринбург",
              "count": 1983
            },
            {
              "id": "11168",
              "name": "Нижний Тагил",
              "count": 108
            },
            {
              "id": "37169",
              "name": "Арамиль",
              "count": 49
            },
            {
              "id": "11164",
              "name": "Каменск-Уральский",
              "count": 28
            },
            {
              "id": "11170",
              "name": "Новоуральск",
              "count": 18
            },
            {
              "id": "11160",
              "name": "Асбест",
              "count": 17
            },
            {
              "id": "11172",
              "name": "Серов",
              "count": 16
            },
            {
              "id": "20720",
              "name": "Верхняя Пышма",
              "count": 15
            },
            {
              "id": "11171",
              "name": "Первоуральск",
              "count": 15
            },
            {
              "id": "20224",
              "name": "Ревда",
              "count": 11
            },
            {
              "id": "21726",
              "name": "Полевской",
              "count": 8
            },
            {
              "id": "29397",
              "name": "Берёзовский",
              "count": 7
            },
            {
              "id": "11161",
              "name": "Верхняя Салда",
              "count": 6
            },
            {
              "id": "11165",
              "name": "Краснотурьинск",
              "count": 5
            },
            {
              "id": "20256",
              "name": "Сухой Лог",
              "count": 5
            },
            {
              "id": "20695",
              "name": "Алапаевск",
              "count": 3
            },
            {
              "id": "20657",
              "name": "Заречный",
              "count": 3
            },
            {
              "id": "20669",
              "name": "Карпинск",
              "count": 3
            },
            {
              "id": "20684",
              "name": "Реж",
              "count": 3
            },
            {
              "id": "20664",
              "name": "Камышлов",
              "count": 2
            },
            {
              "id": "20687",
              "name": "Нижняя Салда",
              "count": 2
            },
            {
              "id": "11169",
              "name": "Нижняя Тура",
              "count": 2
            },
            {
              "id": "21725",
              "name": "Среднеуральск",
              "count": 2
            },
            {
              "id": "20225",
              "name": "Талица",
              "count": 2
            },
            {
              "id": "37168",
              "name": "Артёмовский",
              "count": 1
            },
            {
              "id": "11163",
              "name": "Ирбит",
              "count": 1
            },
            {
              "id": "20604",
              "name": "Кировград",
              "count": 1
            },
            {
              "id": "20654",
              "name": "Невьянск",
              "count": 1
            },
            {
              "id": "20636",
              "name": "Новая Ляля",
              "count": 1
            },
            {
              "id": "20672",
              "name": "Североуральск",
              "count": 1
            },
            {
              "id": "20685",
              "name": "Тавда",
              "count": 1
            }
          ]
        },
        {
          "id": "10995",
          "name": "Краснодарский край",
          "count": 2087,
          "chld": [
            {
              "id": "35",
              "name": "Краснодар",
              "count": 1101
            },
            {
              "id": "239",
              "name": "Сочи",
              "count": 173
            },
            {
              "id": "970",
              "name": "Новороссийск",
              "count": 68
            },
            {
              "id": "1107",
              "name": "Анапа",
              "count": 34
            },
            {
              "id": "10987",
              "name": "Армавир",
              "count": 23
            },
            {
              "id": "20688",
              "name": "Кореновск",
              "count": 18
            },
            {
              "id": "20704",
              "name": "Славянск-на-Кубани",
              "count": 17
            },
            {
              "id": "10990",
              "name": "Геленджик",
              "count": 15
            },
            {
              "id": "10997",
              "name": "Крымск",
              "count": 11
            },
            {
              "id": "1058",
              "name": "Туапсе",
              "count": 10
            },
            {
              "id": "20183",
              "name": "Абинск",
              "count": 9
            },
            {
              "id": "11000",
              "name": "Приморско-Ахтарск",
              "count": 9
            },
            {
              "id": "10993",
              "name": "Ейск",
              "count": 7
            },
            {
              "id": "11002",
              "name": "Тихорецк",
              "count": 6
            },
            {
              "id": "10988",
              "name": "Белореченск",
              "count": 4
            },
            {
              "id": "20677",
              "name": "Темрюк",
              "count": 4
            },
            {
              "id": "20675",
              "name": "Гулькевичи",
              "count": 3
            },
            {
              "id": "20693",
              "name": "Усть-Лабинск",
              "count": 3
            },
            {
              "id": "10986",
              "name": "Апшеронск",
              "count": 1
            },
            {
              "id": "21018",
              "name": "Лабинск",
              "count": 1
            },
            {
              "id": "20231",
              "name": "Новокубанск",
              "count": 1
            },
            {
              "id": "21141",
              "name": "Тимашевск",
              "count": 1
            },
            {
              "id": "37016",
              "name": "Хадыженск",
              "count": 1
            }
          ]
        },
        {
          "id": "11316",
          "name": "Новосибирская область",
          "count": 1598,
          "chld": [
            {
              "id": "65",
              "name": "Новосибирск",
              "count": 1315
            },
            {
              "id": "11314",
              "name": "Бердск",
              "count": 39
            },
            {
              "id": "20103",
              "name": "Куйбышев",
              "count": 3
            },
            {
              "id": "20098",
              "name": "Барабинск",
              "count": 2
            },
            {
              "id": "20100",
              "name": "Искитим",
              "count": 2
            },
            {
              "id": "20107",
              "name": "Черепаново",
              "count": 2
            },
            {
              "id": "20101",
              "name": "Карасук",
              "count": 1
            },
            {
              "id": "20102",
              "name": "Каргат",
              "count": 1
            },
            {
              "id": "11317",
              "name": "Обь",
              "count": 1
            }
          ]
        },
        {
          "id": "11131",
          "name": "Самарская область",
          "count": 1511,
          "chld": [
            {
              "id": "51",
              "name": "Самара",
              "count": 1079
            },
            {
              "id": "240",
              "name": "Тольятти",
              "count": 161
            },
            {
              "id": "11135",
              "name": "Новокуйбышевск",
              "count": 8
            },
            {
              "id": "11139",
              "name": "Сызрань",
              "count": 5
            },
            {
              "id": "11141",
              "name": "Чапаевск",
              "count": 4
            },
            {
              "id": "11132",
              "name": "Жигулёвск",
              "count": 3
            },
            {
              "id": "11134",
              "name": "Нефтегорск",
              "count": 3
            },
            {
              "id": "11137",
              "name": "Отрадный",
              "count": 2
            },
            {
              "id": "11136",
              "name": "Октябрьск",
              "count": 1
            }
          ]
        },
        {
          "id": "11029",
          "name": "Ростовская область",
          "count": 1444,
          "chld": [
            {
              "id": "39",
              "name": "Ростов-на-Дону",
              "count": 993
            },
            {
              "id": "971",
              "name": "Таганрог",
              "count": 97
            },
            {
              "id": "11036",
              "name": "Волгодонск",
              "count": 36
            },
            {
              "id": "11033",
              "name": "Батайск",
              "count": 23
            },
            {
              "id": "11053",
              "name": "Шахты",
              "count": 23
            },
            {
              "id": "11038",
              "name": "Донецк",
              "count": 19
            },
            {
              "id": "11031",
              "name": "Аксай",
              "count": 17
            },
            {
              "id": "238",
              "name": "Новочеркасск",
              "count": 17
            },
            {
              "id": "11030",
              "name": "Азов",
              "count": 8
            },
            {
              "id": "11051",
              "name": "Сальск",
              "count": 6
            },
            {
              "id": "11043",
              "name": "Каменск-Шахтинский",
              "count": 4
            },
            {
              "id": "11049",
              "name": "Новошахтинск",
              "count": 3
            },
            {
              "id": "11034",
              "name": "Белая Калитва",
              "count": 2
            },
            {
              "id": "11037",
              "name": "Гуково",
              "count": 2
            },
            {
              "id": "11040",
              "name": "Зерноград",
              "count": 2
            },
            {
              "id": "11046",
              "name": "Константиновск",
              "count": 2
            },
            {
              "id": "100451",
              "name": "Зверево",
              "count": 1
            },
            {
              "id": "11045",
              "name": "Красный Сулин",
              "count": 1
            },
            {
              "id": "11047",
              "name": "Миллерово",
              "count": 1
            },
            {
              "id": "11048",
              "name": "Морозовск",
              "count": 1
            },
            {
              "id": "11052",
              "name": "Цимлянск",
              "count": 1
            }
          ]
        },
        {
          "id": "11111",
          "name": "Республика Башкортостан",
          "count": 1343,
          "chld": [
            {
              "id": "172",
              "name": "Уфа",
              "count": 1022
            },
            {
              "id": "11116",
              "name": "Стерлитамак",
              "count": 37
            },
            {
              "id": "20717",
              "name": "Туймазы",
              "count": 14
            },
            {
              "id": "11114",
              "name": "Нефтекамск",
              "count": 12
            },
            {
              "id": "11113",
              "name": "Кумертау",
              "count": 11
            },
            {
              "id": "20259",
              "name": "Белорецк",
              "count": 10
            },
            {
              "id": "20235",
              "name": "Октябрьский",
              "count": 10
            },
            {
              "id": "37172",
              "name": "Агидель",
              "count": 9
            },
            {
              "id": "11115",
              "name": "Салават",
              "count": 7
            },
            {
              "id": "20714",
              "name": "Белебей",
              "count": 5
            },
            {
              "id": "20715",
              "name": "Мелеуз",
              "count": 5
            },
            {
              "id": "20689",
              "name": "Бирск",
              "count": 4
            },
            {
              "id": "20716",
              "name": "Сибай",
              "count": 3
            },
            {
              "id": "20639",
              "name": "Давлеканово",
              "count": 2
            },
            {
              "id": "20668",
              "name": "Дюртюли",
              "count": 2
            },
            {
              "id": "20680",
              "name": "Учалы",
              "count": 2
            }
          ]
        },
        {
          "id": "11119",
          "name": "Республика Татарстан",
          "count": 1337,
          "chld": [
            {
              "id": "43",
              "name": "Казань",
              "count": 896
            },
            {
              "id": "236",
              "name": "Набережные Челны",
              "count": 185
            },
            {
              "id": "11122",
              "name": "Бугульма",
              "count": 15
            },
            {
              "id": "11127",
              "name": "Нижнекамск",
              "count": 13
            },
            {
              "id": "11121",
              "name": "Альметьевск",
              "count": 11
            },
            {
              "id": "11126",
              "name": "Лениногорск",
              "count": 10
            },
            {
              "id": "11123",
              "name": "Елабуга",
              "count": 4
            },
            {
              "id": "11125",
              "name": "Зеленодольск",
              "count": 2
            },
            {
              "id": "121642",
              "name": "Иннополис",
              "count": 2
            },
            {
              "id": "20015",
              "name": "Мамадыш",
              "count": 2
            },
            {
              "id": "11124",
              "name": "Заинск",
              "count": 1
            },
            {
              "id": "11129",
              "name": "Чистополь",
              "count": 1
            }
          ]
        },
        {
          "id": "11225",
          "name": "Челябинская область",
          "count": 1306,
          "chld": [
            {
              "id": "56",
              "name": "Челябинск",
              "count": 939
            },
            {
              "id": "235",
              "name": "Магнитогорск",
              "count": 115
            },
            {
              "id": "11207",
              "name": "Копейск",
              "count": 34
            },
            {
              "id": "11218",
              "name": "Снежинск",
              "count": 28
            },
            {
              "id": "11212",
              "name": "Миасс",
              "count": 21
            },
            {
              "id": "11214",
              "name": "Озёрск",
              "count": 17
            },
            {
              "id": "11202",
              "name": "Златоуст",
              "count": 10
            },
            {
              "id": "11210",
              "name": "Кыштым",
              "count": 6
            },
            {
              "id": "11223",
              "name": "Усть-Катав",
              "count": 5
            },
            {
              "id": "20666",
              "name": "Еманжелинск",
              "count": 3
            },
            {
              "id": "11208",
              "name": "Коркино",
              "count": 3
            },
            {
              "id": "11220",
              "name": "Троицк",
              "count": 3
            },
            {
              "id": "11200",
              "name": "Верхний Уфалей",
              "count": 2
            },
            {
              "id": "11216",
              "name": "Пласт",
              "count": 2
            },
            {
              "id": "11219",
              "name": "Трёхгорный",
              "count": 2
            },
            {
              "id": "11196",
              "name": "Аша",
              "count": 1
            },
            {
              "id": "37166",
              "name": "Бакал",
              "count": 1
            },
            {
              "id": "11203",
              "name": "Карталы",
              "count": 1
            },
            {
              "id": "11204",
              "name": "Касли",
              "count": 1
            },
            {
              "id": "11217",
              "name": "Сатка",
              "count": 1
            },
            {
              "id": "11227",
              "name": "Южноуральск",
              "count": 1
            }
          ]
        },
        {
          "id": "11309",
          "name": "Красноярский край",
          "count": 848,
          "chld": [
            {
              "id": "62",
              "name": "Красноярск",
              "count": 634
            },
            {
              "id": "11311",
              "name": "Норильск",
              "count": 34
            },
            {
              "id": "20086",
              "name": "Железногорск",
              "count": 19
            },
            {
              "id": "11310",
              "name": "Минусинск",
              "count": 15
            },
            {
              "id": "11302",
              "name": "Ачинск",
              "count": 10
            },
            {
              "id": "20088",
              "name": "Зеленогорск",
              "count": 8
            },
            {
              "id": "20091",
              "name": "Назарово",
              "count": 6
            },
            {
              "id": "20085",
              "name": "Бородино",
              "count": 4
            },
            {
              "id": "11303",
              "name": "Дивногорск",
              "count": 4
            },
            {
              "id": "11307",
              "name": "Канск",
              "count": 3
            },
            {
              "id": "20643",
              "name": "Кодинск",
              "count": 3
            },
            {
              "id": "20670",
              "name": "Сосновоборск",
              "count": 3
            },
            {
              "id": "20087",
              "name": "Заозерный",
              "count": 2
            },
            {
              "id": "20090",
              "name": "Лесосибирск",
              "count": 2
            },
            {
              "id": "20084",
              "name": "Боготол",
              "count": 1
            },
            {
              "id": "11344",
              "name": "Дудинка",
              "count": 1
            },
            {
              "id": "11304",
              "name": "Енисейск",
              "count": 1
            },
            {
              "id": "20089",
              "name": "Иланский",
              "count": 1
            },
            {
              "id": "20094",
              "name": "Шарыпово",
              "count": 1
            }
          ]
        },
        {
          "id": "10672",
          "name": "Воронежская область",
          "count": 786,
          "chld": [
            {
              "id": "193",
              "name": "Воронеж",
              "count": 690
            },
            {
              "id": "10675",
              "name": "Борисоглебск",
              "count": 5
            },
            {
              "id": "10673",
              "name": "Бобров",
              "count": 2
            },
            {
              "id": "10680",
              "name": "Павловск",
              "count": 2
            },
            {
              "id": "10678",
              "name": "Лиски",
              "count": 1
            },
            {
              "id": "20268",
              "name": "Нововоронеж",
              "count": 1
            },
            {
              "id": "10681",
              "name": "Россошь",
              "count": 1
            }
          ]
        },
        {
          "id": "977",
          "name": "Республика Крым",
          "count": 785,
          "chld": [
            {
              "id": "146",
              "name": "Симферополь",
              "count": 274
            },
            {
              "id": "959",
              "name": "Севастополь",
              "count": 188
            },
            {
              "id": "11463",
              "name": "Евпатория",
              "count": 38
            },
            {
              "id": "11464",
              "name": "Керчь",
              "count": 26
            },
            {
              "id": "11470",
              "name": "Ялта",
              "count": 26
            },
            {
              "id": "11471",
              "name": "Алушта",
              "count": 10
            },
            {
              "id": "20556",
              "name": "Саки",
              "count": 10
            },
            {
              "id": "11469",
              "name": "Феодосия",
              "count": 10
            },
            {
              "id": "27217",
              "name": "Бахчисарай",
              "count": 8
            },
            {
              "id": "27555",
              "name": "Джанкой",
              "count": 5
            },
            {
              "id": "28786",
              "name": "Щёлкино",
              "count": 3
            },
            {
              "id": "27061",
              "name": "Алупка",
              "count": 2
            },
            {
              "id": "27085",
              "name": "Белогорск",
              "count": 2
            },
            {
              "id": "29002",
              "name": "Старый Крым",
              "count": 1
            },
            {
              "id": "11472",
              "name": "Судак",
              "count": 1
            }
          ]
        },
        {
          "id": "11069",
          "name": "Ставропольский край",
          "count": 754,
          "chld": [
            {
              "id": "36",
              "name": "Ставрополь",
              "count": 524
            },
            {
              "id": "11067",
              "name": "Пятигорск",
              "count": 65
            },
            {
              "id": "11064",
              "name": "Невинномысск",
              "count": 27
            },
            {
              "id": "11056",
              "name": "Георгиевск",
              "count": 21
            },
            {
              "id": "101271",
              "name": "Михайловск",
              "count": 12
            },
            {
              "id": "11062",
              "name": "Кисловодск",
              "count": 11
            },
            {
              "id": "11057",
              "name": "Ессентуки",
              "count": 5
            },
            {
              "id": "11063",
              "name": "Минеральные Воды",
              "count": 5
            },
            {
              "id": "20261",
              "name": "Лермонтов",
              "count": 3
            },
            {
              "id": "11061",
              "name": "Ипатово",
              "count": 2
            },
            {
              "id": "11068",
              "name": "Светлоград",
              "count": 2
            },
            {
              "id": "11054",
              "name": "Благодарный",
              "count": 1
            },
            {
              "id": "11059",
              "name": "Зеленокумск",
              "count": 1
            },
            {
              "id": "11066",
              "name": "Новопавловск",
              "count": 1
            }
          ]
        },
        {
          "id": "10950",
          "name": "Волгоградская область",
          "count": 746,
          "chld": [
            {
              "id": "38",
              "name": "Волгоград",
              "count": 454
            },
            {
              "id": "10965",
              "name": "Михайловка",
              "count": 131
            },
            {
              "id": "10951",
              "name": "Волжский",
              "count": 61
            },
            {
              "id": "10959",
              "name": "Камышин",
              "count": 10
            },
            {
              "id": "10962",
              "name": "Котово",
              "count": 9
            },
            {
              "id": "10961",
              "name": "Котельниково",
              "count": 2
            },
            {
              "id": "10981",
              "name": "Урюпинск",
              "count": 2
            },
            {
              "id": "10964",
              "name": "Ленинск",
              "count": 1
            },
            {
              "id": "10982",
              "name": "Фролово",
              "count": 1
            }
          ]
        },
        {
          "id": "11409",
          "name": "Приморский край",
          "count": 721,
          "chld": [
            {
              "id": "75",
              "name": "Владивосток",
              "count": 537
            },
            {
              "id": "11426",
              "name": "Уссурийск",
              "count": 33
            },
            {
              "id": "974",
              "name": "Находка",
              "count": 22
            },
            {
              "id": "11406",
              "name": "Артём",
              "count": 15
            },
            {
              "id": "100459",
              "name": "Фокино",
              "count": 8
            },
            {
              "id": "11405",
              "name": "Арсеньев",
              "count": 4
            },
            {
              "id": "11411",
              "name": "Дальнегорск",
              "count": 4
            },
            {
              "id": "11407",
              "name": "Большой Камень",
              "count": 3
            },
            {
              "id": "11421",
              "name": "Партизанск",
              "count": 2
            },
            {
              "id": "11416",
              "name": "Лесозаводск",
              "count": 1
            },
            {
              "id": "11425",
              "name": "Спасск-Дальний",
              "count": 1
            }
          ]
        },
        {
          "id": "10819",
          "name": "Тверская область",
          "count": 717,
          "chld": [
            {
              "id": "14",
              "name": "Тверь",
              "count": 592
            },
            {
              "id": "10811",
              "name": "Кимры",
              "count": 15
            },
            {
              "id": "10812",
              "name": "Конаково",
              "count": 12
            },
            {
              "id": "10822",
              "name": "Торжок",
              "count": 10
            },
            {
              "id": "10820",
              "name": "Ржев",
              "count": 7
            },
            {
              "id": "10807",
              "name": "Вышний Волочёк",
              "count": 5
            },
            {
              "id": "10818",
              "name": "Осташков",
              "count": 4
            },
            {
              "id": "10804",
              "name": "Бежецк",
              "count": 3
            },
            {
              "id": "10809",
              "name": "Калязин",
              "count": 3
            },
            {
              "id": "10824",
              "name": "Удомля",
              "count": 3
            },
            {
              "id": "10810",
              "name": "Кашин",
              "count": 2
            },
            {
              "id": "10814",
              "name": "Лихославль",
              "count": 2
            },
            {
              "id": "10808",
              "name": "Западная Двина",
              "count": 1
            },
            {
              "id": "20601",
              "name": "Зубцов",
              "count": 1
            },
            {
              "id": "10816",
              "name": "Нелидово",
              "count": 1
            }
          ]
        },
        {
          "id": "11146",
          "name": "Саратовская область",
          "count": 713,
          "chld": [
            {
              "id": "194",
              "name": "Саратов",
              "count": 577
            },
            {
              "id": "11143",
              "name": "Балаково",
              "count": 21
            },
            {
              "id": "11145",
              "name": "Вольск",
              "count": 13
            },
            {
              "id": "11147",
              "name": "Энгельс",
              "count": 12
            },
            {
              "id": "11144",
              "name": "Балашов",
              "count": 3
            },
            {
              "id": "20058",
              "name": "Аткарск",
              "count": 1
            },
            {
              "id": "20059",
              "name": "Ершов",
              "count": 1
            },
            {
              "id": "20061",
              "name": "Красноармейск",
              "count": 1
            },
            {
              "id": "20062",
              "name": "Красный Кут",
              "count": 1
            },
            {
              "id": "20065",
              "name": "Петровск",
              "count": 1
            },
            {
              "id": "20067",
              "name": "Ртищево",
              "count": 1
            },
            {
              "id": "20068",
              "name": "Хвалынск",
              "count": 1
            }
          ]
        },
        {
          "id": "10841",
          "name": "Ярославская область",
          "count": 686,
          "chld": [
            {
              "id": "16",
              "name": "Ярославль",
              "count": 541
            },
            {
              "id": "10839",
              "name": "Рыбинск",
              "count": 80
            },
            {
              "id": "10838",
              "name": "Ростов",
              "count": 9
            },
            {
              "id": "10837",
              "name": "Переславль-Залесский",
              "count": 8
            },
            {
              "id": "10840",
              "name": "Углич",
              "count": 5
            },
            {
              "id": "21154",
              "name": "Тутаев",
              "count": 3
            },
            {
              "id": "10835",
              "name": "Данилов",
              "count": 2
            },
            {
              "id": "20596",
              "name": "Пошехонье",
              "count": 1
            }
          ]
        },
        {
          "id": "11266",
          "name": "Иркутская область",
          "count": 646,
          "chld": [
            {
              "id": "63",
              "name": "Иркутск",
              "count": 431
            },
            {
              "id": "976",
              "name": "Братск",
              "count": 31
            },
            {
              "id": "11256",
              "name": "Ангарск",
              "count": 23
            },
            {
              "id": "11273",
              "name": "Усть-Илимск",
              "count": 12
            },
            {
              "id": "20097",
              "name": "Усть-Кут",
              "count": 6
            },
            {
              "id": "11260",
              "name": "Бодайбо",
              "count": 4
            },
            {
              "id": "11267",
              "name": "Киренск",
              "count": 4
            },
            {
              "id": "11270",
              "name": "Тайшет",
              "count": 4
            },
            {
              "id": "11269",
              "name": "Саянск",
              "count": 3
            },
            {
              "id": "11271",
              "name": "Тулун",
              "count": 3
            },
            {
              "id": "11274",
              "name": "Черемхово",
              "count": 3
            },
            {
              "id": "30614",
              "name": "Свирск",
              "count": 2
            },
            {
              "id": "11272",
              "name": "Усолье-Сибирское",
              "count": 2
            },
            {
              "id": "11259",
              "name": "Бирюсинск",
              "count": 1
            },
            {
              "id": "11262",
              "name": "Вихоревка",
              "count": 1
            },
            {
              "id": "11265",
              "name": "Зима",
              "count": 1
            },
            {
              "id": "20096",
              "name": "Слюдянка",
              "count": 1
            },
            {
              "id": "11275",
              "name": "Шелехов",
              "count": 1
            }
          ]
        },
        {
          "id": "11148",
          "name": "Удмуртская Республика",
          "count": 632,
          "chld": [
            {
              "id": "44",
              "name": "Ижевск",
              "count": 503
            },
            {
              "id": "11150",
              "name": "Глазов",
              "count": 25
            },
            {
              "id": "11152",
              "name": "Сарапул",
              "count": 9
            },
            {
              "id": "11149",
              "name": "Воткинск",
              "count": 6
            },
            {
              "id": "11151",
              "name": "Можга",
              "count": 6
            },
            {
              "id": "20072",
              "name": "Камбарка",
              "count": 1
            }
          ]
        },
        {
          "id": "10832",
          "name": "Тульская область",
          "count": 622,
          "chld": [
            {
              "id": "15",
              "name": "Тула",
              "count": 484
            },
            {
              "id": "10830",
              "name": "Новомосковск",
              "count": 24
            },
            {
              "id": "10833",
              "name": "Узловая",
              "count": 6
            },
            {
              "id": "20697",
              "name": "Ясногорск",
              "count": 5
            },
            {
              "id": "10827",
              "name": "Донской",
              "count": 4
            },
            {
              "id": "20667",
              "name": "Богородицк",
              "count": 3
            },
            {
              "id": "10828",
              "name": "Ефремов",
              "count": 3
            },
            {
              "id": "20649",
              "name": "Киреевск",
              "count": 3
            },
            {
              "id": "10834",
              "name": "Щекино",
              "count": 3
            },
            {
              "id": "10825",
              "name": "Алексин",
              "count": 2
            },
            {
              "id": "37162",
              "name": "Болохово",
              "count": 1
            },
            {
              "id": "10826",
              "name": "Венёв",
              "count": 1
            },
            {
              "id": "10829",
              "name": "Кимовск",
              "count": 1
            },
            {
              "id": "37124",
              "name": "Советск",
              "count": 1
            }
          ]
        },
        {
          "id": "11108",
          "name": "Пермский край",
          "count": 610,
          "chld": [
            {
              "id": "50",
              "name": "Пермь",
              "count": 488
            },
            {
              "id": "20237",
              "name": "Березники",
              "count": 12
            },
            {
              "id": "20244",
              "name": "Лысьва",
              "count": 10
            },
            {
              "id": "20628",
              "name": "Горнозаводск",
              "count": 9
            },
            {
              "id": "11110",
              "name": "Соликамск",
              "count": 6
            },
            {
              "id": "20653",
              "name": "Александровск",
              "count": 4
            },
            {
              "id": "20243",
              "name": "Чайковский",
              "count": 4
            },
            {
              "id": "20634",
              "name": "Верещагино",
              "count": 3
            },
            {
              "id": "20250",
              "name": "Кунгур",
              "count": 3
            },
            {
              "id": "20626",
              "name": "Гремячинск",
              "count": 2
            },
            {
              "id": "20236",
              "name": "Добрянка",
              "count": 2
            },
            {
              "id": "11109",
              "name": "Краснокамск",
              "count": 2
            },
            {
              "id": "20712",
              "name": "Чусовой",
              "count": 2
            },
            {
              "id": "20249",
              "name": "Красновишерск",
              "count": 1
            },
            {
              "id": "11076",
              "name": "Кудымкар",
              "count": 1
            }
          ]
        },
        {
          "id": "11282",
          "name": "Кемеровская область (Кузбасс)",
          "count": 606,
          "chld": [
            {
              "id": "64",
              "name": "Кемерово",
              "count": 235
            },
            {
              "id": "237",
              "name": "Новокузнецк",
              "count": 168
            },
            {
              "id": "11291",
              "name": "Прокопьевск",
              "count": 52
            },
            {
              "id": "11277",
              "name": "Белово",
              "count": 13
            },
            {
              "id": "11287",
              "name": "Междуреченск",
              "count": 13
            },
            {
              "id": "11299",
              "name": "Юрга",
              "count": 10
            },
            {
              "id": "11288",
              "name": "Мыски",
              "count": 9
            },
            {
              "id": "11283",
              "name": "Киселёвск",
              "count": 8
            },
            {
              "id": "11285",
              "name": "Ленинск-Кузнецкий",
              "count": 7
            },
            {
              "id": "11280",
              "name": "Гурьевск",
              "count": 6
            },
            {
              "id": "11276",
              "name": "Анжеро-Судженск",
              "count": 5
            },
            {
              "id": "21703",
              "name": "Калтан",
              "count": 3
            },
            {
              "id": "11295",
              "name": "Таштагол",
              "count": 3
            },
            {
              "id": "11297",
              "name": "Топки",
              "count": 3
            },
            {
              "id": "11290",
              "name": "Осинники",
              "count": 2
            },
            {
              "id": "11278",
              "name": "Берёзовский",
              "count": 1
            },
            {
              "id": "11292",
              "name": "Полысаево",
              "count": 1
            },
            {
              "id": "20645",
              "name": "Тайга",
              "count": 1
            }
          ]
        },
        {
          "id": "11318",
          "name": "Омская область",
          "count": 573,
          "chld": [
            {
              "id": "66",
              "name": "Омск",
              "count": 550
            },
            {
              "id": "20112",
              "name": "Тара",
              "count": 1
            }
          ]
        },
        {
          "id": "11457",
          "name": "Хабаровский край",
          "count": 530,
          "chld": [
            {
              "id": "76",
              "name": "Хабаровск",
              "count": 435
            },
            {
              "id": "11453",
              "name": "Комсомольск-на-Амуре",
              "count": 27
            },
            {
              "id": "11451",
              "name": "Амурск",
              "count": 5
            },
            {
              "id": "11454",
              "name": "Николаевск-на-Амуре",
              "count": 3
            },
            {
              "id": "20166",
              "name": "Вяземский",
              "count": 1
            }
          ]
        },
        {
          "id": "11176",
          "name": "Тюменская область",
          "count": 525,
          "chld": [
            {
              "id": "55",
              "name": "Тюмень",
              "count": 436
            },
            {
              "id": "11175",
              "name": "Тобольск",
              "count": 10
            },
            {
              "id": "11178",
              "name": "Ялуторовск",
              "count": 9
            },
            {
              "id": "11173",
              "name": "Ишим",
              "count": 6
            },
            {
              "id": "20647",
              "name": "Заводоуковск",
              "count": 1
            }
          ]
        },
        {
          "id": "11235",
          "name": "Алтайский край",
          "count": 521,
          "chld": [
            {
              "id": "197",
              "name": "Барнаул",
              "count": 355
            },
            {
              "id": "975",
              "name": "Бийск",
              "count": 40
            },
            {
              "id": "11251",
              "name": "Рубцовск",
              "count": 18
            },
            {
              "id": "11240",
              "name": "Заринск",
              "count": 5
            },
            {
              "id": "11242",
              "name": "Камень-на-Оби",
              "count": 5
            },
            {
              "id": "11246",
              "name": "Новоалтайск",
              "count": 3
            },
            {
              "id": "11252",
              "name": "Славгород",
              "count": 3
            },
            {
              "id": "11236",
              "name": "Белокуриха",
              "count": 2
            },
            {
              "id": "11239",
              "name": "Горняк",
              "count": 2
            },
            {
              "id": "11234",
              "name": "Алейск",
              "count": 1
            },
            {
              "id": "20080",
              "name": "Яровое",
              "count": 1
            }
          ]
        },
        {
          "id": "10693",
          "name": "Калужская область",
          "count": 520,
          "chld": [
            {
              "id": "6",
              "name": "Калуга",
              "count": 309
            },
            {
              "id": "967",
              "name": "Обнинск",
              "count": 94
            },
            {
              "id": "20200",
              "name": "Киров",
              "count": 33
            },
            {
              "id": "20197",
              "name": "Балабаново",
              "count": 8
            },
            {
              "id": "10697",
              "name": "Малоярославец",
              "count": 7
            },
            {
              "id": "10696",
              "name": "Кондрово",
              "count": 4
            },
            {
              "id": "20208",
              "name": "Таруса",
              "count": 4
            },
            {
              "id": "20201",
              "name": "Людиново",
              "count": 2
            },
            {
              "id": "10698",
              "name": "Боровск",
              "count": 1
            },
            {
              "id": "37153",
              "name": "Ермолино",
              "count": 1
            },
            {
              "id": "20198",
              "name": "Жиздра",
              "count": 1
            },
            {
              "id": "20205",
              "name": "Сосенский",
              "count": 1
            }
          ]
        },
        {
          "id": "10658",
          "name": "Владимирская область",
          "count": 513,
          "chld": [
            {
              "id": "192",
              "name": "Владимир",
              "count": 297
            },
            {
              "id": "10664",
              "name": "Ковров",
              "count": 41
            },
            {
              "id": "10656",
              "name": "Александров",
              "count": 32
            },
            {
              "id": "10668",
              "name": "Муром",
              "count": 24
            },
            {
              "id": "10665",
              "name": "Кольчугино",
              "count": 11
            },
            {
              "id": "10661",
              "name": "Гусь-Хрустальный",
              "count": 9
            },
            {
              "id": "10663",
              "name": "Киржач",
              "count": 8
            },
            {
              "id": "10671",
              "name": "Суздаль",
              "count": 7
            },
            {
              "id": "10669",
              "name": "Петушки",
              "count": 6
            },
            {
              "id": "37129",
              "name": "Покров",
              "count": 5
            },
            {
              "id": "10670",
              "name": "Собинка",
              "count": 4
            },
            {
              "id": "103813",
              "name": "Карабаново",
              "count": 3
            },
            {
              "id": "37143",
              "name": "Лакинск",
              "count": 2
            },
            {
              "id": "20757",
              "name": "Юрьев-Польский",
              "count": 2
            },
            {
              "id": "10659",
              "name": "Вязники",
              "count": 1
            },
            {
              "id": "10660",
              "name": "Гороховец",
              "count": 1
            },
            {
              "id": "20678",
              "name": "Радужный",
              "count": 1
            },
            {
              "id": "103814",
              "name": "Струнино",
              "count": 1
            }
          ]
        },
        {
          "id": "11153",
          "name": "Ульяновская область",
          "count": 512,
          "chld": [
            {
              "id": "195",
              "name": "Ульяновск",
              "count": 465
            },
            {
              "id": "11155",
              "name": "Димитровград",
              "count": 40
            },
            {
              "id": "20069",
              "name": "Инза",
              "count": 1
            },
            {
              "id": "20070",
              "name": "Новоульяновск",
              "count": 1
            }
          ]
        },
        {
          "id": "10776",
          "name": "Рязанская область",
          "count": 481,
          "chld": [
            {
              "id": "11",
              "name": "Рязань",
              "count": 434
            },
            {
              "id": "10773",
              "name": "Касимов",
              "count": 14
            },
            {
              "id": "10778",
              "name": "Скопин",
              "count": 5
            },
            {
              "id": "10775",
              "name": "Ряжск",
              "count": 2
            },
            {
              "id": "20631",
              "name": "Михайлов",
              "count": 1
            },
            {
              "id": "10774",
              "name": "Рыбное",
              "count": 1
            }
          ]
        },
        {
          "id": "10857",
          "name": "Калининградская область",
          "count": 442,
          "chld": [
            {
              "id": "22",
              "name": "Калининград",
              "count": 386
            },
            {
              "id": "10860",
              "name": "Советск",
              "count": 5
            },
            {
              "id": "20135",
              "name": "Багратионовск",
              "count": 4
            },
            {
              "id": "20144",
              "name": "Пионерский",
              "count": 4
            },
            {
              "id": "10858",
              "name": "Балтийск",
              "count": 2
            },
            {
              "id": "20140",
              "name": "Краснознаменск",
              "count": 2
            },
            {
              "id": "10859",
              "name": "Светлогорск",
              "count": 2
            },
            {
              "id": "20147",
              "name": "Светлый",
              "count": 2
            },
            {
              "id": "20139",
              "name": "Зеленоградск",
              "count": 1
            },
            {
              "id": "20148",
              "name": "Славск",
              "count": 1
            },
            {
              "id": "20149",
              "name": "Черняховск",
              "count": 1
            }
          ]
        },
        {
          "id": "11084",
          "name": "Оренбургская область",
          "count": 439,
          "chld": [
            {
              "id": "48",
              "name": "Оренбург",
              "count": 339
            },
            {
              "id": "11086",
              "name": "Бузулук",
              "count": 15
            },
            {
              "id": "11091",
              "name": "Орск",
              "count": 14
            },
            {
              "id": "11090",
              "name": "Новотроицк",
              "count": 10
            },
            {
              "id": "11087",
              "name": "Гай",
              "count": 3
            },
            {
              "id": "20031",
              "name": "Абдулино",
              "count": 2
            },
            {
              "id": "20032",
              "name": "Медногорск",
              "count": 2
            },
            {
              "id": "11085",
              "name": "Бугуруслан",
              "count": 1
            },
            {
              "id": "11088",
              "name": "Кувандык",
              "count": 1
            },
            {
              "id": "11094",
              "name": "Соль-Илецк",
              "count": 1
            },
            {
              "id": "20033",
              "name": "Сорочинск",
              "count": 1
            },
            {
              "id": "20034",
              "name": "Ясный",
              "count": 1
            }
          ]
        },
        {
          "id": "11095",
          "name": "Пензенская область",
          "count": 433,
          "chld": [
            {
              "id": "49",
              "name": "Пенза",
              "count": 366
            },
            {
              "id": "11101",
              "name": "Кузнецк",
              "count": 7
            },
            {
              "id": "11106",
              "name": "Сердобск",
              "count": 5
            },
            {
              "id": "11099",
              "name": "Каменка",
              "count": 4
            },
            {
              "id": "11104",
              "name": "Нижний Ломов",
              "count": 1
            }
          ]
        },
        {
          "id": "11193",
          "name": "Ханты-Мансийский автономный округ - Югра",
          "count": 416,
          "chld": [
            {
              "id": "973",
              "name": "Сургут",
              "count": 181
            },
            {
              "id": "1091",
              "name": "Нижневартовск",
              "count": 66
            },
            {
              "id": "11177",
              "name": "Югорск",
              "count": 14
            },
            {
              "id": "11182",
              "name": "Мегион",
              "count": 12
            },
            {
              "id": "57",
              "name": "Ханты-Мансийск",
              "count": 12
            },
            {
              "id": "11184",
              "name": "Нефтеюганск",
              "count": 11
            },
            {
              "id": "11190",
              "name": "Советский",
              "count": 7
            },
            {
              "id": "11180",
              "name": "Когалым",
              "count": 6
            },
            {
              "id": "20676",
              "name": "Лянтор",
              "count": 5
            },
            {
              "id": "11186",
              "name": "Нягань",
              "count": 4
            },
            {
              "id": "11181",
              "name": "Лангепас",
              "count": 3
            },
            {
              "id": "11189",
              "name": "Радужный",
              "count": 2
            },
            {
              "id": "11192",
              "name": "Урай",
              "count": 2
            },
            {
              "id": "11188",
              "name": "Пыть-Ях",
              "count": 1
            }
          ]
        },
        {
          "id": "10645",
          "name": "Белгородская область",
          "count": 399,
          "chld": [
            {
              "id": "4",
              "name": "Белгород",
              "count": 240
            },
            {
              "id": "10649",
              "name": "Старый Оскол",
              "count": 47
            },
            {
              "id": "20192",
              "name": "Алексеевка",
              "count": 21
            },
            {
              "id": "10646",
              "name": "Губкин",
              "count": 13
            },
            {
              "id": "10647",
              "name": "Новый Оскол",
              "count": 4
            },
            {
              "id": "20587",
              "name": "Строитель",
              "count": 3
            },
            {
              "id": "20193",
              "name": "Валуйки",
              "count": 2
            },
            {
              "id": "20196",
              "name": "Шебекино",
              "count": 2
            }
          ]
        },
        {
          "id": "10650",
          "name": "Брянская область",
          "count": 392,
          "chld": [
            {
              "id": "191",
              "name": "Брянск",
              "count": 324
            },
            {
              "id": "20191",
              "name": "Унеча",
              "count": 4
            },
            {
              "id": "10651",
              "name": "Дятьково",
              "count": 3
            },
            {
              "id": "10653",
              "name": "Клинцы",
              "count": 2
            },
            {
              "id": "21701",
              "name": "Сельцо",
              "count": 2
            },
            {
              "id": "10652",
              "name": "Жуковка",
              "count": 1
            },
            {
              "id": "10654",
              "name": "Новозыбков",
              "count": 1
            },
            {
              "id": "20188",
              "name": "Стародуб",
              "count": 1
            },
            {
              "id": "26899",
              "name": "Фокино",
              "count": 1
            }
          ]
        },
        {
          "id": "11010",
          "name": "Республика Дагестан",
          "count": 375,
          "chld": [
            {
              "id": "28",
              "name": "Махачкала",
              "count": 273
            },
            {
              "id": "11007",
              "name": "Дербент",
              "count": 18
            },
            {
              "id": "11008",
              "name": "Каспийск",
              "count": 15
            },
            {
              "id": "11009",
              "name": "Кизляр",
              "count": 10
            },
            {
              "id": "11011",
              "name": "Хасавюрт",
              "count": 7
            },
            {
              "id": "21521",
              "name": "Избербаш",
              "count": 6
            },
            {
              "id": "11006",
              "name": "Буйнакск",
              "count": 5
            },
            {
              "id": "21732",
              "name": "Дагестанские Огни",
              "count": 1
            }
          ]
        },
        {
          "id": "11156",
          "name": "Чувашская Республика",
          "count": 363,
          "chld": [
            {
              "id": "45",
              "name": "Чебоксары",
              "count": 325
            },
            {
              "id": "37133",
              "name": "Новочебоксарск",
              "count": 27
            },
            {
              "id": "20073",
              "name": "Алатырь",
              "count": 3
            },
            {
              "id": "20078",
              "name": "Шумерля",
              "count": 1
            }
          ]
        },
        {
          "id": "10712",
          "name": "Липецкая область",
          "count": 363,
          "chld": [
            {
              "id": "9",
              "name": "Липецк",
              "count": 320
            },
            {
              "id": "10713",
              "name": "Елец",
              "count": 17
            },
            {
              "id": "20212",
              "name": "Задонск",
              "count": 12
            },
            {
              "id": "20210",
              "name": "Грязи",
              "count": 3
            },
            {
              "id": "20211",
              "name": "Данков",
              "count": 1
            }
          ]
        },
        {
          "id": "11443",
          "name": "Республика Саха (Якутия)",
          "count": 351,
          "chld": [
            {
              "id": "74",
              "name": "Якутск",
              "count": 310
            },
            {
              "id": "11437",
              "name": "Нерюнгри",
              "count": 9
            },
            {
              "id": "11436",
              "name": "Мирный",
              "count": 8
            },
            {
              "id": "11432",
              "name": "Алдан",
              "count": 3
            },
            {
              "id": "31005",
              "name": "Томмот",
              "count": 2
            },
            {
              "id": "11435",
              "name": "Ленск",
              "count": 1
            }
          ]
        },
        {
          "id": "10842",
          "name": "Архангельская область",
          "count": 346,
          "chld": [
            {
              "id": "20",
              "name": "Архангельск",
              "count": 226
            },
            {
              "id": "10849",
              "name": "Северодвинск",
              "count": 77
            },
            {
              "id": "10846",
              "name": "Котлас",
              "count": 8
            },
            {
              "id": "10845",
              "name": "Коряжма",
              "count": 7
            },
            {
              "id": "20115",
              "name": "Мирный",
              "count": 4
            },
            {
              "id": "10843",
              "name": "Вельск",
              "count": 3
            }
          ]
        },
        {
          "id": "10853",
          "name": "Вологодская область",
          "count": 345,
          "chld": [
            {
              "id": "21",
              "name": "Вологда",
              "count": 172
            },
            {
              "id": "968",
              "name": "Череповец",
              "count": 98
            },
            {
              "id": "20124",
              "name": "Сокол",
              "count": 7
            },
            {
              "id": "10856",
              "name": "Устюжна",
              "count": 5
            },
            {
              "id": "10854",
              "name": "Великий Устюг",
              "count": 4
            },
            {
              "id": "20120",
              "name": "Вытегра",
              "count": 3
            },
            {
              "id": "10855",
              "name": "Тотьма",
              "count": 3
            },
            {
              "id": "20121",
              "name": "Грязовец",
              "count": 2
            },
            {
              "id": "20118",
              "name": "Бабаево",
              "count": 1
            },
            {
              "id": "20125",
              "name": "Харовск",
              "count": 1
            }
          ]
        },
        {
          "id": "11353",
          "name": "Томская область",
          "count": 333,
          "chld": [
            {
              "id": "67",
              "name": "Томск",
              "count": 285
            },
            {
              "id": "11351",
              "name": "Северск",
              "count": 14
            },
            {
              "id": "11352",
              "name": "Стрежевой",
              "count": 4
            },
            {
              "id": "11349",
              "name": "Колпашево",
              "count": 1
            }
          ]
        },
        {
          "id": "21949",
          "name": "Забайкальский край",
          "count": 326,
          "chld": [
            {
              "id": "68",
              "name": "Чита",
              "count": 292
            },
            {
              "id": "11358",
              "name": "Краснокаменск",
              "count": 9
            },
            {
              "id": "11361",
              "name": "Нерчинск",
              "count": 1
            }
          ]
        },
        {
          "id": "10705",
          "name": "Курская область",
          "count": 325,
          "chld": [
            {
              "id": "8",
              "name": "Курск",
              "count": 284
            },
            {
              "id": "10710",
              "name": "Железногорск",
              "count": 20
            },
            {
              "id": "20707",
              "name": "Курчатов",
              "count": 2
            },
            {
              "id": "20637",
              "name": "Льгов",
              "count": 2
            },
            {
              "id": "10706",
              "name": "Дмитриев",
              "count": 1
            }
          ]
        },
        {
          "id": "11070",
          "name": "Кировская область",
          "count": 309,
          "chld": [
            {
              "id": "46",
              "name": "Киров",
              "count": 282
            },
            {
              "id": "20020",
              "name": "Вятские Поляны",
              "count": 5
            },
            {
              "id": "11071",
              "name": "Кирово-Чепецк",
              "count": 5
            },
            {
              "id": "20023",
              "name": "Котельнич",
              "count": 1
            },
            {
              "id": "20028",
              "name": "Омутнинск",
              "count": 1
            }
          ]
        },
        {
          "id": "10795",
          "name": "Смоленская область",
          "count": 309,
          "chld": [
            {
              "id": "12",
              "name": "Смоленск",
              "count": 268
            },
            {
              "id": "10792",
              "name": "Рославль",
              "count": 6
            },
            {
              "id": "10794",
              "name": "Сафоново",
              "count": 4
            },
            {
              "id": "10801",
              "name": "Ярцево",
              "count": 4
            },
            {
              "id": "10783",
              "name": "Гагарин",
              "count": 3
            },
            {
              "id": "10782",
              "name": "Вязьма",
              "count": 2
            },
            {
              "id": "10786",
              "name": "Дорогобуж",
              "count": 2
            },
            {
              "id": "10785",
              "name": "Десногорск",
              "count": 1
            }
          ]
        },
        {
          "id": "10687",
          "name": "Ивановская область",
          "count": 261,
          "chld": [
            {
              "id": "5",
              "name": "Иваново",
              "count": 218
            },
            {
              "id": "10689",
              "name": "Кинешма",
              "count": 11
            },
            {
              "id": "37127",
              "name": "Приволжск",
              "count": 4
            },
            {
              "id": "20682",
              "name": "Фурманов",
              "count": 4
            },
            {
              "id": "20648",
              "name": "Южа",
              "count": 3
            },
            {
              "id": "37137",
              "name": "Наволоки",
              "count": 2
            },
            {
              "id": "10691",
              "name": "Шуя",
              "count": 2
            },
            {
              "id": "20597",
              "name": "Гаврилов Посад",
              "count": 1
            },
            {
              "id": "20613",
              "name": "Пучеж",
              "count": 1
            },
            {
              "id": "20229",
              "name": "Тейково",
              "count": 1
            }
          ]
        },
        {
          "id": "10699",
          "name": "Костромская область",
          "count": 242,
          "chld": [
            {
              "id": "7",
              "name": "Кострома",
              "count": 201
            },
            {
              "id": "10704",
              "name": "Шарья",
              "count": 4
            },
            {
              "id": "10701",
              "name": "Волгореченск",
              "count": 2
            },
            {
              "id": "10702",
              "name": "Нерехта",
              "count": 2
            },
            {
              "id": "10700",
              "name": "Буй",
              "count": 1
            },
            {
              "id": "10703",
              "name": "Галич",
              "count": 1
            },
            {
              "id": "20219",
              "name": "Чухлома",
              "count": 1
            }
          ]
        },
        {
          "id": "10802",
          "name": "Тамбовская область",
          "count": 241,
          "chld": [
            {
              "id": "13",
              "name": "Тамбов",
              "count": 207
            },
            {
              "id": "10803",
              "name": "Мичуринск",
              "count": 11
            },
            {
              "id": "20696",
              "name": "Моршанск",
              "count": 3
            },
            {
              "id": "20663",
              "name": "Уварово",
              "count": 1
            }
          ]
        },
        {
          "id": "10772",
          "name": "Орловская область",
          "count": 231,
          "chld": [
            {
              "id": "10",
              "name": "Орёл",
              "count": 213
            },
            {
              "id": "10769",
              "name": "Ливны",
              "count": 8
            },
            {
              "id": "10770",
              "name": "Мценск",
              "count": 1
            }
          ]
        },
        {
          "id": "10897",
          "name": "Мурманская область",
          "count": 225,
          "chld": [
            {
              "id": "23",
              "name": "Мурманск",
              "count": 158
            },
            {
              "id": "10894",
              "name": "Апатиты",
              "count": 18
            },
            {
              "id": "10899",
              "name": "Снежногорск",
              "count": 8
            },
            {
              "id": "20156",
              "name": "Полярные Зори",
              "count": 3
            },
            {
              "id": "10898",
              "name": "Североморск",
              "count": 3
            },
            {
              "id": "10895",
              "name": "Кандалакша",
              "count": 2
            },
            {
              "id": "20152",
              "name": "Кировск",
              "count": 2
            },
            {
              "id": "20154",
              "name": "Кола",
              "count": 2
            },
            {
              "id": "20155",
              "name": "Оленегорск",
              "count": 2
            },
            {
              "id": "20151",
              "name": "Заполярный",
              "count": 1
            },
            {
              "id": "10896",
              "name": "Мончегорск",
              "count": 1
            }
          ]
        },
        {
          "id": "10946",
          "name": "Астраханская область",
          "count": 223,
          "chld": [
            {
              "id": "37",
              "name": "Астрахань",
              "count": 220
            }
          ]
        },
        {
          "id": "10939",
          "name": "Республика Коми",
          "count": 210,
          "chld": [
            {
              "id": "19",
              "name": "Сыктывкар",
              "count": 95
            },
            {
              "id": "10945",
              "name": "Ухта",
              "count": 28
            },
            {
              "id": "10944",
              "name": "Усинск",
              "count": 7
            },
            {
              "id": "10942",
              "name": "Печора",
              "count": 3
            },
            {
              "id": "10940",
              "name": "Воркута",
              "count": 2
            },
            {
              "id": "10941",
              "name": "Инта",
              "count": 2
            }
          ]
        },
        {
          "id": "10933",
          "name": "Республика Карелия",
          "count": 207,
          "chld": [
            {
              "id": "18",
              "name": "Петрозаводск",
              "count": 171
            },
            {
              "id": "10935",
              "name": "Костомукша",
              "count": 12
            },
            {
              "id": "20132",
              "name": "Суоярви",
              "count": 5
            },
            {
              "id": "20130",
              "name": "Питкяранта",
              "count": 4
            },
            {
              "id": "10936",
              "name": "Сегежа",
              "count": 3
            },
            {
              "id": "10938",
              "name": "Беломорск",
              "count": 2
            },
            {
              "id": "10934",
              "name": "Кондопога",
              "count": 2
            },
            {
              "id": "10937",
              "name": "Сортавала",
              "count": 2
            },
            {
              "id": "20128",
              "name": "Медвежьегорск",
              "count": 1
            },
            {
              "id": "20129",
              "name": "Олонец",
              "count": 1
            }
          ]
        },
        {
          "id": "11375",
          "name": "Амурская область",
          "count": 194,
          "chld": [
            {
              "id": "77",
              "name": "Благовещенск",
              "count": 158
            },
            {
              "id": "11387",
              "name": "Свободный",
              "count": 6
            },
            {
              "id": "11374",
              "name": "Белогорск",
              "count": 3
            },
            {
              "id": "11379",
              "name": "Зея",
              "count": 3
            },
            {
              "id": "11385",
              "name": "Райчихинск",
              "count": 1
            },
            {
              "id": "11391",
              "name": "Тында",
              "count": 1
            }
          ]
        },
        {
          "id": "11077",
          "name": "Республика Марий Эл",
          "count": 192,
          "chld": [
            {
              "id": "41",
              "name": "Йошкар-Ола",
              "count": 176
            },
            {
              "id": "20721",
              "name": "Волжск",
              "count": 9
            },
            {
              "id": "20630",
              "name": "Козьмодемьянск",
              "count": 1
            }
          ]
        },
        {
          "id": "11117",
          "name": "Республика Мордовия",
          "count": 176,
          "chld": [
            {
              "id": "42",
              "name": "Саранск",
              "count": 155
            },
            {
              "id": "20010",
              "name": "Рузаевка",
              "count": 8
            }
          ]
        },
        {
          "id": "10926",
          "name": "Псковская область",
          "count": 172,
          "chld": [
            {
              "id": "25",
              "name": "Псков",
              "count": 136
            },
            {
              "id": "10928",
              "name": "Великие Луки",
              "count": 21
            },
            {
              "id": "20641",
              "name": "Остров",
              "count": 2
            },
            {
              "id": "10931",
              "name": "Печоры",
              "count": 2
            },
            {
              "id": "10929",
              "name": "Дно",
              "count": 1
            },
            {
              "id": "20633",
              "name": "Опочка",
              "count": 1
            }
          ]
        },
        {
          "id": "11330",
          "name": "Республика Бурятия",
          "count": 154,
          "chld": [
            {
              "id": "198",
              "name": "Улан-Удэ",
              "count": 142
            },
            {
              "id": "11326",
              "name": "Гусиноозерск",
              "count": 2
            },
            {
              "id": "11327",
              "name": "Северобайкальск",
              "count": 1
            }
          ]
        },
        {
          "id": "11021",
          "name": "Республика Северная Осетия — Алания",
          "count": 150,
          "chld": [
            {
              "id": "33",
              "name": "Владикавказ",
              "count": 123
            },
            {
              "id": "11023",
              "name": "Беслан",
              "count": 3
            }
          ]
        },
        {
          "id": "10904",
          "name": "Новгородская область",
          "count": 143,
          "chld": [
            {
              "id": "24",
              "name": "Великий Новгород",
              "count": 107
            },
            {
              "id": "10906",
              "name": "Боровичи",
              "count": 22
            },
            {
              "id": "10923",
              "name": "Старая Русса",
              "count": 1
            },
            {
              "id": "10925",
              "name": "Чудово",
              "count": 1
            }
          ]
        },
        {
          "id": "11450",
          "name": "Сахалинская область",
          "count": 135,
          "chld": [
            {
              "id": "80",
              "name": "Южно-Сахалинск",
              "count": 99
            },
            {
              "id": "11447",
              "name": "Оха",
              "count": 7
            },
            {
              "id": "11445",
              "name": "Корсаков",
              "count": 4
            },
            {
              "id": "20158",
              "name": "Анива",
              "count": 2
            },
            {
              "id": "11448",
              "name": "Северо-Курильск",
              "count": 2
            },
            {
              "id": "20163",
              "name": "Углегорск",
              "count": 2
            },
            {
              "id": "20164",
              "name": "Холмск",
              "count": 2
            },
            {
              "id": "20598",
              "name": "Макаров",
              "count": 1
            },
            {
              "id": "20160",
              "name": "Невельск",
              "count": 1
            },
            {
              "id": "20162",
              "name": "Томари",
              "count": 1
            },
            {
              "id": "32087",
              "name": "Шахтерск",
              "count": 1
            }
          ]
        },
        {
          "id": "11158",
          "name": "Курганская область",
          "count": 124,
          "chld": [
            {
              "id": "53",
              "name": "Курган",
              "count": 105
            },
            {
              "id": "11159",
              "name": "Шадринск",
              "count": 11
            },
            {
              "id": "20640",
              "name": "Далматово",
              "count": 2
            }
          ]
        },
        {
          "id": "11013",
          "name": "Кабардино-Балкарская Республика",
          "count": 111,
          "chld": [
            {
              "id": "30",
              "name": "Нальчик",
              "count": 104
            },
            {
              "id": "20174",
              "name": "Прохладный",
              "count": 2
            }
          ]
        },
        {
          "id": "11232",
          "name": "Ямало-Ненецкий автономный округ",
          "count": 90,
          "chld": [
            {
              "id": "11230",
              "name": "Новый Уренгой",
              "count": 34
            },
            {
              "id": "58",
              "name": "Салехард",
              "count": 13
            },
            {
              "id": "11231",
              "name": "Ноябрьск",
              "count": 7
            },
            {
              "id": "11229",
              "name": "Надым",
              "count": 5
            },
            {
              "id": "23329",
              "name": "Лабытнанги",
              "count": 4
            },
            {
              "id": "11228",
              "name": "Губкинский",
              "count": 3
            },
            {
              "id": "11174",
              "name": "Муравленко",
              "count": 3
            },
            {
              "id": "20255",
              "name": "Тарко-Сале",
              "count": 1
            }
          ]
        },
        {
          "id": "11004",
          "name": "Республика Адыгея",
          "count": 74,
          "chld": [
            {
              "id": "1093",
              "name": "Майкоп",
              "count": 36
            }
          ]
        },
        {
          "id": "11024",
          "name": "Чеченская Республика",
          "count": 73,
          "chld": [
            {
              "id": "1106",
              "name": "Грозный",
              "count": 64
            },
            {
              "id": "11026",
              "name": "Гудермес",
              "count": 7
            },
            {
              "id": "20699",
              "name": "Урус-Мартан",
              "count": 1
            }
          ]
        },
        {
          "id": "11340",
          "name": "Республика Хакасия",
          "count": 73,
          "chld": [
            {
              "id": "1095",
              "name": "Абакан",
              "count": 45
            },
            {
              "id": "11342",
              "name": "Черногорск",
              "count": 11
            },
            {
              "id": "11341",
              "name": "Саяногорск",
              "count": 3
            }
          ]
        },
        {
          "id": "11398",
          "name": "Камчатский край",
          "count": 68,
          "chld": [
            {
              "id": "78",
              "name": "Петропавловск-Камчатский",
              "count": 42
            },
            {
              "id": "11395",
              "name": "Вилючинск",
              "count": 8
            },
            {
              "id": "11396",
              "name": "Елизово",
              "count": 5
            }
          ]
        },
        {
          "id": "11015",
          "name": "Республика Калмыкия",
          "count": 36,
          "chld": [
            {
              "id": "1094",
              "name": "Элиста",
              "count": 32
            }
          ]
        },
        {
          "id": "10231",
          "name": "Республика Алтай",
          "count": 35,
          "chld": [
            {
              "id": "11319",
              "name": "Горно-Алтайск",
              "count": 22
            }
          ]
        },
        {
          "id": "11012",
          "name": "Республика Ингушетия",
          "count": 33,
          "chld": [
            {
              "id": "1092",
              "name": "Назрань",
              "count": 9
            },
            {
              "id": "21702",
              "name": "Сунжа",
              "count": 3
            },
            {
              "id": "20179",
              "name": "Малгобек",
              "count": 1
            }
          ]
        },
        {
          "id": "11403",
          "name": "Магаданская область",
          "count": 27,
          "chld": [
            {
              "id": "79",
              "name": "Магадан",
              "count": 24
            }
          ]
        },
        {
          "id": "10233",
          "name": "Республика Тыва",
          "count": 24,
          "chld": [
            {
              "id": "11333",
              "name": "Кызыл",
              "count": 21
            }
          ]
        },
        {
          "id": "10243",
          "name": "Еврейская автономная область",
          "count": 17,
          "chld": [
            {
              "id": "11393",
              "name": "Биробиджан",
              "count": 15
            }
          ]
        },
        {
          "id": "11020",
          "name": "Карачаево-Черкесская Республика",
          "count": 14,
          "chld": [
            {
              "id": "1104",
              "name": "Черкесск",
              "count": 12
            }
          ]
        }
      ]
    },
    {
      "id": "149",
      "name": "Беларусь",
      "count": 1079,
      "chld": [
        {
          "id": "29630",
          "name": "Минская область",
          "count": 702,
          "chld": [
            {
              "id": "157",
              "name": "Минск",
              "count": 604
            },
            {
              "id": "21193",
              "name": "Борисов",
              "count": 3
            },
            {
              "id": "26034",
              "name": "Жодино",
              "count": 3
            },
            {
              "id": "26002",
              "name": "Логойск",
              "count": 2
            },
            {
              "id": "26001",
              "name": "Молодечно",
              "count": 2
            },
            {
              "id": "26951",
              "name": "Слуцк",
              "count": 2
            },
            {
              "id": "26018",
              "name": "Дзержинск",
              "count": 1
            },
            {
              "id": "26022",
              "name": "Крупки",
              "count": 1
            },
            {
              "id": "26003",
              "name": "Солигорск",
              "count": 1
            },
            {
              "id": "101627",
              "name": "Фаниполь",
              "count": 1
            }
          ]
        },
        {
          "id": "29631",
          "name": "Гомельская область",
          "count": 129,
          "chld": [
            {
              "id": "155",
              "name": "Гомель",
              "count": 102
            },
            {
              "id": "26008",
              "name": "Речица",
              "count": 4
            },
            {
              "id": "21274",
              "name": "Мозырь",
              "count": 3
            },
            {
              "id": "102233",
              "name": "Добруш",
              "count": 2
            },
            {
              "id": "26966",
              "name": "Жлобин",
              "count": 1
            },
            {
              "id": "102238",
              "name": "Наровля",
              "count": 1
            },
            {
              "id": "26027",
              "name": "Рогачёв",
              "count": 1
            }
          ]
        },
        {
          "id": "29634",
          "name": "Гродненская область",
          "count": 118,
          "chld": [
            {
              "id": "10274",
              "name": "Гродно",
              "count": 98
            },
            {
              "id": "26005",
              "name": "Слоним",
              "count": 2
            },
            {
              "id": "21144",
              "name": "Лида",
              "count": 1
            },
            {
              "id": "26836",
              "name": "Новогрудок",
              "count": 1
            },
            {
              "id": "101481",
              "name": "Островец",
              "count": 1
            }
          ]
        },
        {
          "id": "29629",
          "name": "Могилёвская область",
          "count": 99,
          "chld": [
            {
              "id": "158",
              "name": "Могилёв",
              "count": 77
            },
            {
              "id": "20729",
              "name": "Бобруйск",
              "count": 4
            },
            {
              "id": "26864",
              "name": "Кличев",
              "count": 1
            },
            {
              "id": "26015",
              "name": "Кричев",
              "count": 1
            },
            {
              "id": "26012",
              "name": "Осиповичи",
              "count": 1
            }
          ]
        },
      ]
    },
    {
      "id": "159",
      "name": "Казахстан",
      "count": 683,
      "chld": [
        {
          "id": "29406",
          "name": "Алматинская область",
          "count": 193,
          "chld": [
            {
              "id": "162",
              "name": "Алматы",
              "count": 187
            },
            {
              "id": "29456",
              "name": "Конаев",
              "count": 1
            }
          ]
        },
        {
          "id": "29403",
          "name": "Акмолинская область",
          "count": 79,
          "chld": [
            {
              "id": "163",
              "name": "Астана",
              "count": 70
            },
            {
              "id": "20809",
              "name": "Кокшетау",
              "count": 7
            },
            {
              "id": "29421",
              "name": "Атбасар",
              "count": 1
            }
          ]
        },
        {
          "id": "29411",
          "name": "Карагандинская область",
          "count": 59,
          "chld": [
            {
              "id": "164",
              "name": "Караганда",
              "count": 49
            },
            {
              "id": "35393",
              "name": "Темиртау",
              "count": 3
            },
            {
              "id": "29550",
              "name": "Шахтинск",
              "count": 2
            },
            {
              "id": "29544",
              "name": "Приозёрск",
              "count": 1
            }
          ]
        },
        {
          "id": "29415",
          "name": "Павлодарская область",
          "count": 35,
          "chld": [
            {
              "id": "190",
              "name": "Павлодар",
              "count": 30
            },
            {
              "id": "29590",
              "name": "Экибастуз",
              "count": 2
            }
          ]
        },
        {
          "id": "29417",
          "name": "Туркестанская область",
          "count": 27,
          "chld": [
            {
              "id": "221",
              "name": "Шымкент",
              "count": 25
            },
            {
              "id": "21106",
              "name": "Туркестан",
              "count": 1
            }
          ]
        },
        {
          "id": "29416",
          "name": "Северо-Казахстанская область",
          "count": 27,
          "chld": [
            {
              "id": "10298",
              "name": "Петропавловск",
              "count": 22
            }
          ]
        },
        {
          "id": "29408",
          "name": "Восточно-Казахстанская область",
          "count": 25,
          "chld": [
            {
              "id": "10306",
              "name": "Усть-Каменогорск",
              "count": 24
            },
            {
              "id": "29492",
              "name": "Алтай",
              "count": 1
            }
          ]
        },
        {
          "id": "218936",
          "name": "Область Улытау",
          "count": 21,
          "chld": [
            {
              "id": "10308",
              "name": "Жезказган",
              "count": 21
            }
          ]
        },
        {
          "id": "29412",
          "name": "Костанайская область",
          "count": 20,
          "chld": [
            {
              "id": "10295",
              "name": "Костанай",
              "count": 9
            },
            {
              "id": "10300",
              "name": "Рудный",
              "count": 6
            }
          ]
        },
        {
          "id": "29409",
          "name": "Жамбылская область",
          "count": 15,
          "chld": [
            {
              "id": "21094",
              "name": "Тараз",
              "count": 15
            }
          ]
        },
        {
          "id": "218938",
          "name": "Область Абай",
          "count": 10,
          "chld": [
            {
              "id": "165",
              "name": "Семей",
              "count": 10
            }
          ]
        },
        {
          "id": "29407",
          "name": "Атырауская область",
          "count": 8,
          "chld": [
            {
              "id": "10291",
              "name": "Атырау",
              "count": 8
            }
          ]
        },
        {
          "id": "29413",
          "name": "Кызылординская область",
          "count": 8,
          "chld": [
            {
              "id": "21050",
              "name": "Кызылорда",
              "count": 7
            }
          ]
        },
        {
          "id": "218918",
          "name": "Область Жетысу",
          "count": 7,
          "chld": [
            {
              "id": "10303",
              "name": "Талдыкорган",
              "count": 7
            }
          ]
        },
        {
          "id": "29414",
          "name": "Мангистауская область",
          "count": 7,
          "chld": [
            {
              "id": "29575",
              "name": "Актау",
              "count": 5
            }
          ]
        }
      ]
    },
    {
      "id": "96",
      "name": "Германия",
      "count": 682,
      "chld": [
        {
          "id": "103753",
          "name": "Гессен",
          "count": 198,
          "chld": [
            {
              "id": "100",
              "name": "Франкфурт-на-Майне",
              "count": 156
            },
            {
              "id": "100492",
              "name": "Висбаден",
              "count": 15
            },
            {
              "id": "115256",
              "name": "Бад-Наухайм",
              "count": 1
            },
            {
              "id": "115244",
              "name": "Бад-Хомбург",
              "count": 1
            },
            {
              "id": "115428",
              "name": "Вайтерштадт",
              "count": 1
            },
            {
              "id": "112340",
              "name": "Лимбург-на-Лане",
              "count": 1
            },
            {
              "id": "115255",
              "name": "Таунусштайн",
              "count": 1
            },
            {
              "id": "103786",
              "name": "Фульда",
              "count": 1
            }
          ]
        },
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 96,
          "chld": [
            {
              "id": "177",
              "name": "Берлин",
              "count": 84
            },
            {
              "id": "178",
              "name": "Гамбург",
              "count": 12
            }
          ]
        },
        {
          "id": "103750",
          "name": "Свободное государство Бавария",
          "count": 72,
          "chld": [
            {
              "id": "10412",
              "name": "Нюрнберг",
              "count": 36
            },
            {
              "id": "99",
              "name": "Мюнхен",
              "count": 12
            },
            {
              "id": "103773",
              "name": "Аугсбург",
              "count": 2
            },
            {
              "id": "115315",
              "name": "Швабах",
              "count": 2
            },
            {
              "id": "115526",
              "name": "Айхах",
              "count": 1
            },
            {
              "id": "112409",
              "name": "Бад-Тёльц",
              "count": 1
            },
            {
              "id": "103781",
              "name": "Байройт",
              "count": 1
            },
            {
              "id": "101738",
              "name": "Ингольштадт",
              "count": 1
            },
            {
              "id": "100475",
              "name": "Регенсбург",
              "count": 1
            },
            {
              "id": "115314",
              "name": "Фюрт",
              "count": 1
            },
            {
              "id": "112192",
              "name": "Швайнфурт",
              "count": 1
            },
            {
              "id": "112218",
              "name": "Штраубинг",
              "count": 1
            }
          ]
        },
        {
          "id": "103756",
          "name": "Северный Рейн-Вестфалия",
          "count": 60,
          "chld": [
            {
              "id": "20776",
              "name": "Эссен",
              "count": 10
            },
            {
              "id": "10408",
              "name": "Дюссельдорф",
              "count": 5
            },
            {
              "id": "98",
              "name": "Кёльн",
              "count": 4
            },
            {
              "id": "103771",
              "name": "Мюнстер",
              "count": 3
            },
            {
              "id": "115216",
              "name": "Бад-Эйнхаузен",
              "count": 2
            },
            {
              "id": "10402",
              "name": "Бонн",
              "count": 2
            },
            {
              "id": "103784",
              "name": "Бохум",
              "count": 2
            },
            {
              "id": "103785",
              "name": "Вупперталь",
              "count": 2
            },
            {
              "id": "100500",
              "name": "Дортмунд",
              "count": 2
            },
            {
              "id": "115209",
              "name": "Эльде",
              "count": 2
            },
            {
              "id": "103778",
              "name": "Ахен",
              "count": 1
            },
            {
              "id": "101429",
              "name": "Билефельд",
              "count": 1
            },
            {
              "id": "112378",
              "name": "Вальдбрёль",
              "count": 1
            },
            {
              "id": "112304",
              "name": "Вердоль",
              "count": 1
            },
            {
              "id": "115379",
              "name": "Гайленкирхен",
              "count": 1
            },
            {
              "id": "115214",
              "name": "Детмольд",
              "count": 1
            },
            {
              "id": "103788",
              "name": "Дуйсбург",
              "count": 1
            },
            {
              "id": "115135",
              "name": "Клеве",
              "count": 1
            },
            {
              "id": "115139",
              "name": "Лангенфельд",
              "count": 1
            },
            {
              "id": "103799",
              "name": "Леверкузен",
              "count": 1
            },
            {
              "id": "115235",
              "name": "Люнен",
              "count": 1
            },
            {
              "id": "115372",
              "name": "Мехерних",
              "count": 1
            },
            {
              "id": "115141",
              "name": "Монхайм-на-Рейне",
              "count": 1
            },
            {
              "id": "109600",
              "name": "Мёнхенгладбах",
              "count": 1
            },
            {
              "id": "100541",
              "name": "Падерборн",
              "count": 1
            },
            {
              "id": "115175",
              "name": "Пульхайм",
              "count": 1
            },
            {
              "id": "115473",
              "name": "Радеформвальд",
              "count": 1
            },
            {
              "id": "115142",
              "name": "Ратинген",
              "count": 1
            },
            {
              "id": "115189",
              "name": "Санкт-Августин",
              "count": 1
            },
            {
              "id": "115159",
              "name": "Фёрде",
              "count": 1
            },
            {
              "id": "112190",
              "name": "Хамм",
              "count": 1
            }
          ]
        },
        {
          "id": "103759",
          "name": "Свободное государство Саксония",
          "count": 59,
          "chld": [
            {
              "id": "10407",
              "name": "Дрезден",
              "count": 30
            },
            {
              "id": "10410",
              "name": "Лейпциг",
              "count": 4
            },
            {
              "id": "100509",
              "name": "Хемниц",
              "count": 2
            },
            {
              "id": "112363",
              "name": "Вурцен",
              "count": 1
            },
            {
              "id": "112232",
              "name": "Хойерсверда",
              "count": 1
            }
          ]
        },
        {
          "id": "103751",
          "name": "Бранденбург",
          "count": 32,
          "chld": [
            {
              "id": "103809",
              "name": "Франкфурт-на-Одере",
              "count": 28
            },
            {
              "id": "10417",
              "name": "Потсдам",
              "count": 1
            },
            {
              "id": "112291",
              "name": "Финстервальде",
              "count": 1
            }
          ]
        },
        {
          "id": "103749",
          "name": "Баден-Вюртемберг",
          "count": 32,
          "chld": [
            {
              "id": "101",
              "name": "Штутгарт",
              "count": 5
            },
            {
              "id": "103765",
              "name": "Мангейм",
              "count": 3
            },
            {
              "id": "112213",
              "name": "Ален",
              "count": 2
            },
            {
              "id": "112411",
              "name": "Гёппинген",
              "count": 2
            },
            {
              "id": "97",
              "name": "Хайдельберг",
              "count": 2
            },
            {
              "id": "112247",
              "name": "Бад-Мергентхайм",
              "count": 1
            },
            {
              "id": "10400",
              "name": "Баден-Баден",
              "count": 1
            },
            {
              "id": "112314",
              "name": "Зигмаринген",
              "count": 1
            },
            {
              "id": "115289",
              "name": "Зинсхайм",
              "count": 1
            },
            {
              "id": "103782",
              "name": "Оффенбург",
              "count": 1
            },
            {
              "id": "115291",
              "name": "Пфорцхайм",
              "count": 1
            },
            {
              "id": "115296",
              "name": "Радольфцелль",
              "count": 1
            },
            {
              "id": "115301",
              "name": "Ройтлинген",
              "count": 1
            },
            {
              "id": "103777",
              "name": "Ротвайль",
              "count": 1
            },
            {
              "id": "115668",
              "name": "Санкт-Георген",
              "count": 1
            },
            {
              "id": "101424",
              "name": "Тюбинген",
              "count": 1
            },
            {
              "id": "115294",
              "name": "Филлинген-Швеннинген",
              "count": 1
            },
            {
              "id": "115267",
              "name": "Херренберг",
              "count": 1
            },
            {
              "id": "112354",
              "name": "Эбербах",
              "count": 1
            }
          ]
        },
        {
          "id": "103755",
          "name": "Нижняя Саксония",
          "count": 25,
          "chld": [
            {
              "id": "10405",
              "name": "Ганновер",
              "count": 4
            },
            {
              "id": "103774",
              "name": "Брауншвейг",
              "count": 2
            },
            {
              "id": "100526",
              "name": "Хильдесхайм",
              "count": 2
            },
            {
              "id": "115121",
              "name": "Ахим",
              "count": 1
            },
            {
              "id": "21083",
              "name": "Берген",
              "count": 1
            },
            {
              "id": "115129",
              "name": "Брамше",
              "count": 1
            },
            {
              "id": "112427",
              "name": "Вунсторф",
              "count": 1
            },
            {
              "id": "112202",
              "name": "Гослар",
              "count": 1
            },
            {
              "id": "115103",
              "name": "Зальцгиттер",
              "count": 1
            },
            {
              "id": "112226",
              "name": "Ильцен",
              "count": 1
            },
            {
              "id": "115111",
              "name": "Лангенхаген",
              "count": 1
            },
            {
              "id": "103798",
              "name": "Лер",
              "count": 1
            },
            {
              "id": "112205",
              "name": "Люнебург",
              "count": 1
            },
            {
              "id": "103793",
              "name": "Меппен",
              "count": 1
            },
            {
              "id": "112436",
              "name": "Нойштадт-ам-Рюбенберге",
              "count": 1
            },
            {
              "id": "103766",
              "name": "Ольденбург",
              "count": 1
            },
            {
              "id": "115123",
              "name": "Эмден",
              "count": 1
            }
          ]
        },
        {
          "id": "103757",
          "name": "Рейнланд-Пфальц",
          "count": 7,
          "chld": [
            {
              "id": "103790",
              "name": "Кайзерслаутерн",
              "count": 1
            },
            {
              "id": "114654",
              "name": "Франкенталь",
              "count": 1
            }
          ]
        },
        {
          "id": "103761",
          "name": "Шлезвиг-Гольштейн",
          "count": 4,
          "chld": [
            {
              "id": "103805",
              "name": "Нордерштедт",
              "count": 2
            },
            {
              "id": "112349",
              "name": "Вестерланд",
              "count": 1
            },
            {
              "id": "112285",
              "name": "Эльмсхорн",
              "count": 1
            }
          ]
        },
        {
          "id": "103752",
          "name": "Вольный ганзейский город Бремен",
          "count": 3,
          "chld": [
            {
              "id": "10403",
              "name": "Бремен",
              "count": 3
            }
          ]
        },
        {
          "id": "103760",
          "name": "Саксония-Анхальт",
          "count": 3,
          "chld": [
            {
              "id": "112308",
              "name": "Бернбург",
              "count": 2
            },
            {
              "id": "100489",
              "name": "Галле",
              "count": 1
            }
          ]
        },
        {
          "id": "103758",
          "name": "Саар",
          "count": 3,
          "chld": [
            {
              "id": "115321",
              "name": "Саарлуис",
              "count": 1
            },
            {
              "id": "115318",
              "name": "Фёльклинген",
              "count": 1
            }
          ]
        },
        {
          "id": "103762",
          "name": "Свободное государство Тюрингия",
          "count": 2,
          "chld": [
            {
              "id": "112220",
              "name": "Заальфельд",
              "count": 1
            },
            {
              "id": "103776",
              "name": "Эрфурт",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "84",
      "name": "США",
      "count": 399,
      "chld": [
        {
          "id": "29322",
          "name": "Штат Калифорния",
          "count": 56,
          "chld": [
            {
              "id": "200",
              "name": "Лос-Анджелес",
              "count": 28
            },
            {
              "id": "21756",
              "name": "Сан-Хосе",
              "count": 5
            },
            {
              "id": "90",
              "name": "Сан-Франциско",
              "count": 3
            },
            {
              "id": "102563",
              "name": "Ирвайн",
              "count": 1
            },
            {
              "id": "103322",
              "name": "Лонг-Бич",
              "count": 1
            },
            {
              "id": "102939",
              "name": "Пасадена",
              "count": 1
            },
            {
              "id": "103015",
              "name": "Розвилл",
              "count": 1
            },
            {
              "id": "103066",
              "name": "Роклин",
              "count": 1
            },
            {
              "id": "20765",
              "name": "Сакраменто",
              "count": 1
            },
            {
              "id": "21754",
              "name": "Сан-Диего",
              "count": 1
            },
            {
              "id": "102749",
              "name": "Саннивейл",
              "count": 1
            },
            {
              "id": "110653",
              "name": "Санта Моника",
              "count": 1
            },
            {
              "id": "110712",
              "name": "Саут-Лейк-Тахо",
              "count": 1
            },
            {
              "id": "102571",
              "name": "Фримонт",
              "count": 1
            },
            {
              "id": "103008",
              "name": "Хантингтон-Бич",
              "count": 1
            }
          ]
        },
        {
          "id": "29349",
          "name": "Штат Нью-Йорк",
          "count": 46,
          "chld": [
            {
              "id": "202",
              "name": "Нью-Йорк",
              "count": 34
            },
            {
              "id": "102604",
              "name": "Буффало",
              "count": 4
            },
            {
              "id": "110227",
              "name": "Кингстон",
              "count": 1
            },
            {
              "id": "113553",
              "name": "Осуиго",
              "count": 1
            },
            {
              "id": "102879",
              "name": "Ютика",
              "count": 1
            }
          ]
        },
        {
          "id": "29347",
          "name": "Штат Нью-Джерси",
          "count": 22,
          "chld": [
            {
              "id": "102694",
              "name": "Клифтон",
              "count": 10
            },
            {
              "id": "102590",
              "name": "Джерси-Сити",
              "count": 1
            },
            {
              "id": "102735",
              "name": "Норт Берген",
              "count": 1
            },
            {
              "id": "102946",
              "name": "Секаукус",
              "count": 1
            }
          ]
        },
        {
          "id": "29363",
          "name": "Штат Вирджиния",
          "count": 21,
          "chld": [
            {
              "id": "102820",
              "name": "Ашберн",
              "count": 9
            },
            {
              "id": "102624",
              "name": "Рестон",
              "count": 2
            },
            {
              "id": "102843",
              "name": "Стерлинг",
              "count": 2
            }
          ]
        },
        {
          "id": "29330",
          "name": "Штат Иллинойс",
          "count": 20,
          "chld": [
            {
              "id": "10131",
              "name": "Чикаго",
              "count": 16
            },
            {
              "id": "20993",
              "name": "Спрингфилд",
              "count": 1
            }
          ]
        },
        {
          "id": "29354",
          "name": "Штат Орегон",
          "count": 12,
          "chld": [
            {
              "id": "21215",
              "name": "Портленд",
              "count": 4
            },
            {
              "id": "110763",
              "name": "Даллс",
              "count": 1
            },
            {
              "id": "103052",
              "name": "Хиллсборо",
              "count": 1
            }
          ]
        },
        {
          "id": "103666",
          "name": "Округ Колумбия",
          "count": 11,
          "chld": [
            {
              "id": "87",
              "name": "Вашингтон",
              "count": 11
            }
          ]
        },
        {
          "id": "29364",
          "name": "Штат Вашингтон",
          "count": 11,
          "chld": [
            {
              "id": "91",
              "name": "Сиэтл",
              "count": 3
            },
            {
              "id": "103336",
              "name": "Кент",
              "count": 2
            },
            {
              "id": "102572",
              "name": "Белвью",
              "count": 1
            },
            {
              "id": "102908",
              "name": "Бремертон",
              "count": 1
            },
            {
              "id": "110504",
              "name": "Ошен Шорс",
              "count": 1
            },
            {
              "id": "102561",
              "name": "Редмонд",
              "count": 1
            }
          ]
        },
        {
          "id": "29326",
          "name": "Штат Флорида",
          "count": 9,
          "chld": [
            {
              "id": "10122",
              "name": "Майами",
              "count": 2
            },
            {
              "id": "102751",
              "name": "Нейплс",
              "count": 2
            },
            {
              "id": "103364",
              "name": "Норт Порт",
              "count": 1
            },
            {
              "id": "110527",
              "name": "Палм Кост",
              "count": 1
            },
            {
              "id": "102566",
              "name": "Тампа",
              "count": 1
            },
            {
              "id": "110754",
              "name": "Тарпон Спрингс",
              "count": 1
            }
          ]
        },
        {
          "id": "29338",
          "name": "Штат Массачусетс",
          "count": 8,
          "chld": [
            {
              "id": "223",
              "name": "Бостон",
              "count": 4
            },
            {
              "id": "109865",
              "name": "Брейнтри",
              "count": 2
            },
            {
              "id": "103163",
              "name": "Фолл-Ривер",
              "count": 1
            }
          ]
        },
        {
          "id": "29350",
          "name": "Штат Северная Каролина",
          "count": 8,
          "chld": [
            {
              "id": "109901",
              "name": "Кэри",
              "count": 2
            },
            {
              "id": "102871",
              "name": "Гринвилл",
              "count": 1
            },
            {
              "id": "110439",
              "name": "Маунт Эйри",
              "count": 1
            },
            {
              "id": "20751",
              "name": "Роли",
              "count": 1
            },
            {
              "id": "113530",
              "name": "Форест Сити",
              "count": 1
            }
          ]
        },
        {
          "id": "29360",
          "name": "Штат Техас",
          "count": 6,
          "chld": [
            {
              "id": "21772",
              "name": "Даллас",
              "count": 4
            },
            {
              "id": "103289",
              "name": "Кэти",
              "count": 1
            },
            {
              "id": "102914",
              "name": "Стаффорд",
              "count": 1
            }
          ]
        },
        {
          "id": "29320",
          "name": "Штат Аризона",
          "count": 6,
          "chld": [
            {
              "id": "102603",
              "name": "Тусон",
              "count": 3
            },
            {
              "id": "113306",
              "name": "Каса Гранде",
              "count": 2
            },
            {
              "id": "20959",
              "name": "Финикс",
              "count": 1
            }
          ]
        },
        {
          "id": "29327",
          "name": "Штат Джорджия",
          "count": 5,
          "chld": [
            {
              "id": "86",
              "name": "Атланта",
              "count": 4
            },
            {
              "id": "102705",
              "name": "Альфаретта",
              "count": 1
            }
          ]
        },
        {
          "id": "29352",
          "name": "Штат Огайо",
          "count": 5,
          "chld": [
            {
              "id": "20815",
              "name": "Колумбус",
              "count": 2
            },
            {
              "id": "113446",
              "name": "Беллефонтейн",
              "count": 1
            },
            {
              "id": "103089",
              "name": "Стронгсвилл",
              "count": 1
            }
          ]
        },
        {
          "id": "29355",
          "name": "Штат Пенсильвания",
          "count": 4,
          "chld": [
            {
              "id": "10129",
              "name": "Филадельфия",
              "count": 2
            },
            {
              "id": "102874",
              "name": "Йорк",
              "count": 1
            }
          ]
        },
        {
          "id": "29337",
          "name": "Штат Мэриленд",
          "count": 3,
          "chld": [
            {
              "id": "109862",
              "name": "Боуи",
              "count": 1
            }
          ]
        },
        {
          "id": "29332",
          "name": "Штат Айова",
          "count": 3,
          "chld": [
            {
              "id": "103193",
              "name": "Амес",
              "count": 1
            },
            {
              "id": "21464",
              "name": "Де-Мойн",
              "count": 1
            }
          ]
        },
        {
          "id": "29324",
          "name": "Штат Коннектикут",
          "count": 3,
          "chld": [
            {
              "id": "103011",
              "name": "Нью-Хейвен",
              "count": 1
            },
            {
              "id": "103354",
              "name": "Саутингтон",
              "count": 1
            }
          ]
        },
        {
          "id": "29366",
          "name": "Штат Висконсин",
          "count": 2,
          "chld": [
            {
              "id": "21268",
              "name": "Милуоки",
              "count": 2
            }
          ]
        },
        {
          "id": "29342",
          "name": "Штат Миссури",
          "count": 2,
          "chld": [
            {
              "id": "21011",
              "name": "Сент-Луис",
              "count": 1
            },
            {
              "id": "102722",
              "name": "Спрингфилд",
              "count": 1
            }
          ]
        },
        {
          "id": "29328",
          "name": "Штат Гавайи",
          "count": 1,
          "chld": [
            {
              "id": "10119",
              "name": "Гонолулу",
              "count": 1
            }
          ]
        },
        {
          "id": "29325",
          "name": "Штат Делавэр",
          "count": 1,
          "chld": [
            {
              "id": "102815",
              "name": "Нью Касл",
              "count": 1
            }
          ]
        },
        {
          "id": "29334",
          "name": "Штат Кентукки",
          "count": 1
        },
        {
          "id": "29323",
          "name": "Штат Колорадо",
          "count": 1,
          "chld": [
            {
              "id": "102617",
              "name": "Аврора",
              "count": 1
            }
          ]
        },
        {
          "id": "29340",
          "name": "Штат Миннесота",
          "count": 1
        },
        {
          "id": "29339",
          "name": "Штат Мичиган",
          "count": 1,
          "chld": [
            {
              "id": "110730",
              "name": "Стерлинг Хайтс",
              "count": 1
            }
          ]
        },
        {
          "id": "29346",
          "name": "Штат Нью-Гэмпшир",
          "count": 1
        },
        {
          "id": "29357",
          "name": "Штат Южная Каролина",
          "count": 1,
          "chld": [
            {
              "id": "109853",
              "name": "Блафтон",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "171",
      "name": "Узбекистан",
      "count": 358,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 317,
          "chld": [
            {
              "id": "10335",
              "name": "Ташкент",
              "count": 317
            }
          ]
        },
        {
          "id": "105813",
          "name": "Ташкентская область",
          "count": 4,
          "chld": [
            {
              "id": "114900",
              "name": "Чирчик",
              "count": 1
            }
          ]
        },
        {
          "id": "105804",
          "name": "Андижанская область",
          "count": 3,
          "chld": [
            {
              "id": "10329",
              "name": "Андижан",
              "count": 3
            }
          ]
        },
        {
          "id": "105805",
          "name": "Бухарская область",
          "count": 2,
          "chld": [
            {
              "id": "10330",
              "name": "Бухара",
              "count": 2
            }
          ]
        },
        {
          "id": "105806",
          "name": "Джизакская область",
          "count": 2,
          "chld": [
            {
              "id": "21606",
              "name": "Джизак",
              "count": 2
            }
          ]
        },
        {
          "id": "105807",
          "name": "Кашкадарьинская область",
          "count": 2,
          "chld": [
            {
              "id": "10331",
              "name": "Карши",
              "count": 2
            }
          ]
        },
        {
          "id": "105809",
          "name": "Наманганская область",
          "count": 2,
          "chld": [
            {
              "id": "21314",
              "name": "Наманган",
              "count": 1
            },
            {
              "id": "189922",
              "name": "Туракурган",
              "count": 1
            }
          ]
        },
        {
          "id": "105816",
          "name": "Республика Каракалпакстан",
          "count": 2,
          "chld": [
            {
              "id": "10337",
              "name": "Нукус",
              "count": 1
            },
            {
              "id": "101422",
              "name": "Турткуль",
              "count": 1
            }
          ]
        },
        {
          "id": "105811",
          "name": "Сурхандарьинская область",
          "count": 1,
          "chld": [
            {
              "id": "10338",
              "name": "Термез",
              "count": 1
            }
          ]
        },
        {
          "id": "105812",
          "name": "Сырдарьинская область",
          "count": 1,
          "chld": [
            {
              "id": "21605",
              "name": "Гулистан",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "118",
      "name": "Нидерланды",
      "count": 352,
      "chld": [
        {
          "id": "104308",
          "name": "Северная Голландия",
          "count": 226,
          "chld": [
            {
              "id": "10466",
              "name": "Амстердам",
              "count": 218
            },
            {
              "id": "144942",
              "name": "Харлем",
              "count": 5
            }
          ]
        },
        {
          "id": "104302",
          "name": "Провинция Гронинген",
          "count": 53,
          "chld": [
            {
              "id": "105145",
              "name": "Гронинген",
              "count": 52
            },
            {
              "id": "113105",
              "name": "Делфзейл",
              "count": 1
            }
          ]
        },
        {
          "id": "104303",
          "name": "Дренте",
          "count": 27,
          "chld": [
            {
              "id": "105146",
              "name": "Ассен",
              "count": 13
            }
          ]
        },
        {
          "id": "104312",
          "name": "Южная Голландия",
          "count": 16,
          "chld": [
            {
              "id": "20755",
              "name": "Роттердам",
              "count": 6
            },
            {
              "id": "21396",
              "name": "Гаага",
              "count": 2
            },
            {
              "id": "144941",
              "name": "Дордрехт",
              "count": 1
            }
          ]
        },
        {
          "id": "104310",
          "name": "Флеволанд",
          "count": 6,
          "chld": [
            {
              "id": "218625",
              "name": "Дронтен",
              "count": 5
            },
            {
              "id": "105150",
              "name": "Лелистад",
              "count": 1
            }
          ]
        },
        {
          "id": "104307",
          "name": "Северный Брабант",
          "count": 4,
          "chld": [
            {
              "id": "113106",
              "name": "Остерхаут",
              "count": 1
            },
            {
              "id": "105149",
              "name": "Хертогенбос",
              "count": 1
            },
            {
              "id": "20804",
              "name": "Эйндховен",
              "count": 1
            }
          ]
        },
        {
          "id": "104301",
          "name": "Гелдерланд",
          "count": 3,
          "chld": [
            {
              "id": "105143",
              "name": "Арнем",
              "count": 1
            }
          ]
        },
        {
          "id": "104305",
          "name": "Лимбург",
          "count": 2
        }
      ]
    },
    {
      "id": "207",
      "name": "Киргизия",
      "count": 334,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 294,
          "chld": [
            {
              "id": "10309",
              "name": "Бишкек",
              "count": 283
            },
            {
              "id": "10310",
              "name": "Ош",
              "count": 11
            }
          ]
        },
        {
          "id": "105947",
          "name": "Чуйская область",
          "count": 16,
          "chld": [
            {
              "id": "105948",
              "name": "Токмак",
              "count": 3
            },
            {
              "id": "112943",
              "name": "Кара-Балта",
              "count": 2
            }
          ]
        },
        {
          "id": "105946",
          "name": "Таласская область",
          "count": 4,
          "chld": [
            {
              "id": "38345",
              "name": "Талас",
              "count": 4
            }
          ]
        },
        {
          "id": "105942",
          "name": "Джалал-Абадская область",
          "count": 1,
          "chld": [
            {
              "id": "10312",
              "name": "Джалал-Абад",
              "count": 1
            }
          ]
        },
        {
          "id": "105943",
          "name": "Иссык-Кульская область",
          "count": 1,
          "chld": [
            {
              "id": "36948",
              "name": "Каракол",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "102",
      "name": "Великобритания",
      "count": 317,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 188,
          "chld": [
            {
              "id": "10393",
              "name": "Лондон",
              "count": 134
            },
            {
              "id": "104562",
              "name": "Кардифф",
              "count": 53
            },
            {
              "id": "145343",
              "name": "Райд",
              "count": 1
            }
          ]
        },
        {
          "id": "104623",
          "name": "Графство Беркшир",
          "count": 17,
          "chld": [
            {
              "id": "123254",
              "name": "Слау",
              "count": 16
            },
            {
              "id": "105764",
              "name": "Рединг",
              "count": 1
            }
          ]
        },
        {
          "id": "104624",
          "name": "Графство Кент",
          "count": 8
        },
        {
          "id": "104608",
          "name": "Графство Уэст-Мидлендс",
          "count": 3,
          "chld": [
            {
              "id": "21134",
              "name": "Бирмингем",
              "count": 2
            },
            {
              "id": "109581",
              "name": "Вулвергемптон",
              "count": 1
            }
          ]
        },
        {
          "id": "104618",
          "name": "Графство Бакингемшир",
          "count": 2,
          "chld": [
            {
              "id": "109554",
              "name": "Милтон-Кинс",
              "count": 2
            }
          ]
        },
        {
          "id": "104569",
          "name": "Графство Кередигион",
          "count": 2,
          "chld": [
            {
              "id": "145192",
              "name": "Аберистуит",
              "count": 2
            }
          ]
        },
        {
          "id": "104613",
          "name": "Графство Норфолк",
          "count": 2,
          "chld": [
            {
              "id": "104983",
              "name": "Норидж",
              "count": 2
            }
          ]
        },
        {
          "id": "104555",
          "name": "Область Эдинбург",
          "count": 2,
          "chld": [
            {
              "id": "10397",
              "name": "Эдинбург",
              "count": 2
            }
          ]
        },
        {
          "id": "104611",
          "name": "Графство Нортгемптоншир",
          "count": 2,
          "chld": [
            {
              "id": "114443",
              "name": "Корби",
              "count": 1
            }
          ]
        },
        {
          "id": "104603",
          "name": "Графство Большой Манчестер",
          "count": 1,
          "chld": [
            {
              "id": "10394",
              "name": "Манчестер",
              "count": 1
            }
          ]
        },
        {
          "id": "109537",
          "name": "Графство Восточный Йоркшир",
          "count": 1,
          "chld": [
            {
              "id": "109539",
              "name": "Кингстон-апон-Халл",
              "count": 1
            }
          ]
        },
        {
          "id": "104621",
          "name": "Графство Вустершир",
          "count": 1
        },
        {
          "id": "104620",
          "name": "Графство Глостершир",
          "count": 1,
          "chld": [
            {
              "id": "104988",
              "name": "Глостер",
              "count": 1
            }
          ]
        },
        {
          "id": "104597",
          "name": "Графство Западный Йоркшир",
          "count": 1,
          "chld": [
            {
              "id": "109504",
              "name": "Брэдфорд",
              "count": 1
            }
          ]
        },
        {
          "id": "104627",
          "name": "Графство Суррей",
          "count": 1,
          "chld": [
            {
              "id": "109580",
              "name": "Уокинг",
              "count": 1
            }
          ]
        },
        {
          "id": "104622",
          "name": "Графство Уилтшир",
          "count": 1,
          "chld": [
            {
              "id": "109571",
              "name": "Суиндон",
              "count": 1
            }
          ]
        },
        {
          "id": "104572",
          "name": "Графство Флинтшир",
          "count": 1
        },
        {
          "id": "104628",
          "name": "Графство Хэмпшир",
          "count": 1,
          "chld": [
            {
              "id": "109563",
              "name": "Саутгемптон",
              "count": 1
            }
          ]
        },
        {
          "id": "104605",
          "name": "Графство Чешир",
          "count": 1
        },
        {
          "id": "104601",
          "name": "Графство Южный Йоркшир",
          "count": 1,
          "chld": [
            {
              "id": "105768",
              "name": "Шеффилд",
              "count": 1
            }
          ]
        },
        {
          "id": "104546",
          "name": "Область Перт и Кинросс",
          "count": 1,
          "chld": [
            {
              "id": "105028",
              "name": "Перт",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "124",
      "name": "Франция",
      "count": 315,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 154,
          "chld": [
            {
              "id": "10502",
              "name": "Париж",
              "count": 154
            }
          ]
        },
        {
          "id": "152784",
          "name": "Нор",
          "count": 73,
          "chld": [
            {
              "id": "116115",
              "name": "Рубе",
              "count": 31
            },
            {
              "id": "105070",
              "name": "Лилль",
              "count": 12
            },
            {
              "id": "112059",
              "name": "Валансьен",
              "count": 1
            }
          ]
        },
        {
          "id": "152752",
          "name": "Нижний Рейн",
          "count": 10,
          "chld": [
            {
              "id": "21081",
              "name": "Страсбург",
              "count": 8
            },
            {
              "id": "144688",
              "name": "Оберне",
              "count": 1
            }
          ]
        },
        {
          "id": "152835",
          "name": "Эндр-э-Луара",
          "count": 3
        },
        {
          "id": "147714",
          "name": "Сен-Сен-Дени",
          "count": 3,
          "chld": [
            {
              "id": "123574",
              "name": "Сен-Дени",
              "count": 2
            },
            {
              "id": "147828",
              "name": "Ле-Ренси",
              "count": 1
            }
          ]
        },
        {
          "id": "147713",
          "name": "О-де-Сен",
          "count": 3,
          "chld": [
            {
              "id": "147764",
              "name": "Аньер-сюр-Сен",
              "count": 1
            },
            {
              "id": "147768",
              "name": "Исси-ле-Мулино",
              "count": 1
            },
            {
              "id": "147765",
              "name": "Курбевуа",
              "count": 1
            }
          ]
        },
        {
          "id": "152848",
          "name": "Верхняя Гаронна",
          "count": 2,
          "chld": [
            {
              "id": "10504",
              "name": "Тулуза",
              "count": 2
            }
          ]
        },
        {
          "id": "152701",
          "name": "Воклюз",
          "count": 2,
          "chld": [
            {
              "id": "144526",
              "name": "Оранж",
              "count": 2
            }
          ]
        },
        {
          "id": "152544",
          "name": "Ланд",
          "count": 2,
          "chld": [
            {
              "id": "144665",
              "name": "Дакс",
              "count": 2
            }
          ]
        },
        {
          "id": "153093",
          "name": "Лионская метрополия",
          "count": 2,
          "chld": [
            {
              "id": "10499",
              "name": "Лион",
              "count": 2
            }
          ]
        },
        {
          "id": "152839",
          "name": "Луаре",
          "count": 2
        },
        {
          "id": "152795",
          "name": "Уаза",
          "count": 2,
          "chld": [
            {
              "id": "153091",
              "name": "Крей",
              "count": 2
            }
          ]
        },
        {
          "id": "147720",
          "name": "Вал-д’Уаз",
          "count": 2,
          "chld": [
            {
              "id": "147799",
              "name": "Гуссенвиль",
              "count": 1
            }
          ]
        },
        {
          "id": "152654",
          "name": "Атлантическая Луара",
          "count": 1
        },
        {
          "id": "152697",
          "name": "Буш-дю-Рон",
          "count": 1,
          "chld": [
            {
              "id": "10501",
              "name": "Марсель",
              "count": 1
            }
          ]
        },
        {
          "id": "152754",
          "name": "Верхний Рейн",
          "count": 1,
          "chld": [
            {
              "id": "100488",
              "name": "Мюлуз",
              "count": 1
            }
          ]
        },
        {
          "id": "152581",
          "name": "Верхняя Вьенна",
          "count": 1,
          "chld": [
            {
              "id": "105067",
              "name": "Лимож",
              "count": 1
            }
          ]
        },
        {
          "id": "152575",
          "name": "Вьенна",
          "count": 1
        },
        {
          "id": "152566",
          "name": "Дё-Севр",
          "count": 1,
          "chld": [
            {
              "id": "152632",
              "name": "Партене",
              "count": 1
            }
          ]
        },
        {
          "id": "152606",
          "name": "Иль и Вилен",
          "count": 1,
          "chld": [
            {
              "id": "105064",
              "name": "Ренн",
              "count": 1
            }
          ]
        },
        {
          "id": "152739",
          "name": "Морбиан",
          "count": 1
        },
        {
          "id": "147840",
          "name": "Ньевр",
          "count": 1,
          "chld": [
            {
              "id": "112139",
              "name": "Невер",
              "count": 1
            }
          ]
        },
        {
          "id": "152837",
          "name": "Од",
          "count": 1,
          "chld": [
            {
              "id": "109109",
              "name": "Каркассон",
              "count": 1
            }
          ]
        },
        {
          "id": "152702",
          "name": "Приморские Альпы",
          "count": 1,
          "chld": [
            {
              "id": "21413",
              "name": "Канны",
              "count": 1
            }
          ]
        },
        {
          "id": "147716",
          "name": "Сент-э-Марн",
          "count": 1,
          "chld": [
            {
              "id": "147800",
              "name": "Понто-Комбо",
              "count": 1
            }
          ]
        },
        {
          "id": "152553",
          "name": "Шаранта Приморская",
          "count": 1,
          "chld": [
            {
              "id": "109123",
              "name": "Ла-Рошель",
              "count": 1
            }
          ]
        },
        {
          "id": "152594",
          "name": "Эн",
          "count": 1,
          "chld": [
            {
              "id": "112127",
              "name": "Ойонна",
              "count": 1
            }
          ]
        },
        {
          "id": "152980",
          "name": "Эр",
          "count": 1
        },
        {
          "id": "152853",
          "name": "Эро",
          "count": 1,
          "chld": [
            {
              "id": "144651",
              "name": "Безье",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "120",
      "name": "Польша",
      "count": 217,
      "chld": [
        {
          "id": "104111",
          "name": "Мазовецкое воеводство",
          "count": 120,
          "chld": [
            {
              "id": "10472",
              "name": "Варшава",
              "count": 113
            },
            {
              "id": "104804",
              "name": "Прушкув",
              "count": 3
            },
            {
              "id": "106437",
              "name": "Гродзиск-Мазовецки",
              "count": 1
            },
            {
              "id": "106703",
              "name": "Новы-Двур-Мазовецки",
              "count": 1
            },
            {
              "id": "104805",
              "name": "Пясечно",
              "count": 1
            },
            {
              "id": "104798",
              "name": "Седльце",
              "count": 1
            }
          ]
        },
        {
          "id": "104112",
          "name": "Нижнесилезское воеводство",
          "count": 24,
          "chld": [
            {
              "id": "21392",
              "name": "Вроцлав",
              "count": 22
            },
            {
              "id": "104849",
              "name": "Свидница",
              "count": 1
            }
          ]
        },
        {
          "id": "104117",
          "name": "Малопольское воеводство",
          "count": 19,
          "chld": [
            {
              "id": "10474",
              "name": "Краков",
              "count": 16
            },
            {
              "id": "106350",
              "name": "Бохня",
              "count": 1
            },
            {
              "id": "104811",
              "name": "Новы-Сонч",
              "count": 1
            },
            {
              "id": "104789",
              "name": "Тарнув",
              "count": 1
            }
          ]
        },
        {
          "id": "104123",
          "name": "Силезское воеводство",
          "count": 10,
          "chld": [
            {
              "id": "10473",
              "name": "Катовице",
              "count": 4
            },
            {
              "id": "104913",
              "name": "Хожув",
              "count": 2
            },
            {
              "id": "104919",
              "name": "Бельско-Бяла",
              "count": 1
            },
            {
              "id": "104915",
              "name": "Бытом",
              "count": 1
            },
            {
              "id": "104857",
              "name": "Рыбник",
              "count": 1
            },
            {
              "id": "104910",
              "name": "Чеховице-Дзедзице",
              "count": 1
            }
          ]
        },
        {
          "id": "104121",
          "name": "Поморское воеводство",
          "count": 6,
          "chld": [
            {
              "id": "21417",
              "name": "Гданьск",
              "count": 5
            },
            {
              "id": "104880",
              "name": "Мальборк",
              "count": 1
            }
          ]
        },
        {
          "id": "104124",
          "name": "Великопольское воеводство",
          "count": 6,
          "chld": [
            {
              "id": "10477",
              "name": "Познань",
              "count": 4
            },
            {
              "id": "108419",
              "name": "Мейска-Гурка",
              "count": 1
            }
          ]
        },
        {
          "id": "104113",
          "name": "Куявско-Поморское воеводство",
          "count": 6,
          "chld": [
            {
              "id": "104916",
              "name": "Быдгощ",
              "count": 4
            },
            {
              "id": "104838",
              "name": "Влоцлавек",
              "count": 1
            },
            {
              "id": "104842",
              "name": "Торунь",
              "count": 1
            }
          ]
        },
        {
          "id": "104125",
          "name": "Варминско-Мазурское воеводство",
          "count": 4,
          "chld": [
            {
              "id": "104905",
              "name": "Эльблонг",
              "count": 3
            },
            {
              "id": "104809",
              "name": "Ольштын",
              "count": 1
            }
          ]
        },
        {
          "id": "104126",
          "name": "Западно-Поморское воеводство",
          "count": 3,
          "chld": [
            {
              "id": "104846",
              "name": "Щецин",
              "count": 3
            }
          ]
        },
        {
          "id": "104118",
          "name": "Опольское воеводство",
          "count": 2,
          "chld": [
            {
              "id": "106552",
              "name": "Ключборк",
              "count": 2
            }
          ]
        },
        {
          "id": "104116",
          "name": "Лодзинское воеводство",
          "count": 2,
          "chld": [
            {
              "id": "10475",
              "name": "Лодзь",
              "count": 1
            },
            {
              "id": "106720",
              "name": "Опочно",
              "count": 1
            }
          ]
        },
        {
          "id": "104119",
          "name": "Подкарпатское воеводство",
          "count": 1,
          "chld": [
            {
              "id": "104800",
              "name": "Жешув",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "983",
      "name": "Турция",
      "count": 173,
      "chld": [
        {
          "id": "103728",
          "name": "Провинция Стамбул",
          "count": 55,
          "chld": [
            {
              "id": "11508",
              "name": "Стамбул",
              "count": 19
            },
            {
              "id": "115700",
              "name": "Кючюкчекмедже",
              "count": 1
            },
            {
              "id": "104767",
              "name": "Эсенъюрт",
              "count": 1
            }
          ]
        },
        {
          "id": "103675",
          "name": "Провинция Анталья",
          "count": 31,
          "chld": [
            {
              "id": "17307",
              "name": "Манавгат",
              "count": 10
            },
            {
              "id": "11511",
              "name": "Анталья",
              "count": 7
            },
            {
              "id": "104667",
              "name": "Серик",
              "count": 6
            },
            {
              "id": "11502",
              "name": "Аланья",
              "count": 4
            },
            {
              "id": "21091",
              "name": "Кемер",
              "count": 2
            },
            {
              "id": "17305",
              "name": "Аксу",
              "count": 1
            }
          ]
        },
        {
          "id": "103674",
          "name": "Провинция Анкара",
          "count": 24,
          "chld": [
            {
              "id": "11503",
              "name": "Анкара",
              "count": 7
            },
            {
              "id": "104782",
              "name": "Чанкая",
              "count": 2
            }
          ]
        },
        {
          "id": "103699",
          "name": "Провинция Кайсери",
          "count": 17,
          "chld": [
            {
              "id": "115836",
              "name": "Коджасинан",
              "count": 17
            }
          ]
        },
        {
          "id": "103697",
          "name": "Провинция Измир",
          "count": 12,
          "chld": [
            {
              "id": "11505",
              "name": "Измир",
              "count": 2
            }
          ]
        },
        {
          "id": "103715",
          "name": "Провинция Мерсин",
          "count": 5,
          "chld": [
            {
              "id": "103823",
              "name": "Мерсин",
              "count": 2
            },
            {
              "id": "115750",
              "name": "Гюльнар",
              "count": 1
            }
          ]
        },
        {
          "id": "103716",
          "name": "Провинция Мугла",
          "count": 3,
          "chld": [
            {
              "id": "103832",
              "name": "Мугла",
              "count": 2
            },
            {
              "id": "21257",
              "name": "Мармарис",
              "count": 1
            }
          ]
        },
        {
          "id": "103706",
          "name": "Провинция Коджаэли",
          "count": 2,
          "chld": [
            {
              "id": "103841",
              "name": "Гебзе",
              "count": 2
            }
          ]
        },
        {
          "id": "103736",
          "name": "Провинция Чанаккале",
          "count": 2,
          "chld": [
            {
              "id": "103873",
              "name": "Чанаккале",
              "count": 2
            }
          ]
        },
        {
          "id": "103743",
          "name": "Провинция Эдирне",
          "count": 2,
          "chld": [
            {
              "id": "11509",
              "name": "Эдирне",
              "count": 2
            }
          ]
        },
        {
          "id": "103748",
          "name": "Провинция Ялова",
          "count": 2,
          "chld": [
            {
              "id": "103866",
              "name": "Ялова",
              "count": 2
            }
          ]
        },
        {
          "id": "103669",
          "name": "Провинция Адана",
          "count": 1,
          "chld": [
            {
              "id": "11501",
              "name": "Адана",
              "count": 1
            }
          ]
        },
        {
          "id": "103690",
          "name": "Провинция Газиантеп",
          "count": 1,
          "chld": [
            {
              "id": "103825",
              "name": "Газиантеп",
              "count": 1
            }
          ]
        },
        {
          "id": "103729",
          "name": "Провинция Текирдаг",
          "count": 1,
          "chld": [
            {
              "id": "21033",
              "name": "Текирдаг",
              "count": 1
            }
          ]
        },
        {
          "id": "103739",
          "name": "Провинция Шанлыурфа",
          "count": 1,
          "chld": [
            {
              "id": "103870",
              "name": "Шанлыурфа",
              "count": 1
            }
          ]
        },
        {
          "id": "103742",
          "name": "Провинция Ыспарта",
          "count": 1,
          "chld": [
            {
              "id": "103846",
              "name": "Ыспарта",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "208",
      "name": "Молдова",
      "count": 144,
      "chld": [
        {
          "id": "104927",
          "name": "Левобережье Днестра",
          "count": 105,
          "chld": [
            {
              "id": "10317",
              "name": "Тирасполь",
              "count": 97
            },
            {
              "id": "100518",
              "name": "Дубоссары",
              "count": 1
            }
          ]
        },
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 27,
          "chld": [
            {
              "id": "10313",
              "name": "Кишинев",
              "count": 22
            },
            {
              "id": "10315",
              "name": "Бендеры",
              "count": 5
            }
          ]
        }
      ]
    },
    {
      "id": "167",
      "name": "Азербайджан",
      "count": 123,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 105,
          "chld": [
            {
              "id": "10253",
              "name": "Баку",
              "count": 98
            },
            {
              "id": "105881",
              "name": "Ленкорань",
              "count": 5
            },
            {
              "id": "10255",
              "name": "Сумгаит",
              "count": 1
            },
            {
              "id": "105885",
              "name": "Шеки",
              "count": 1
            }
          ]
        },
      ]
    },
    {
      "id": "169",
      "name": "Грузия",
      "count": 119,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 91,
          "chld": [
            {
              "id": "10277",
              "name": "Тбилиси",
              "count": 91
            }
          ]
        },
        {
          "id": "122054",
          "name": "Автономная Республика Аджария",
          "count": 15,
          "chld": [
            {
              "id": "10278",
              "name": "Батуми",
              "count": 14
            },
            {
              "id": "101399",
              "name": "Кобулети",
              "count": 1
            }
          ]
        },
        {
          "id": "122062",
          "name": "Квемо-Картли",
          "count": 1,
          "chld": [
            {
              "id": "10285",
              "name": "Рустави",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "204",
      "name": "Испания",
      "count": 119,
      "chld": [
        {
          "id": "104187",
          "name": "Каталония",
          "count": 42,
          "chld": [
            {
              "id": "10429",
              "name": "Барселона",
              "count": 36
            },
            {
              "id": "109289",
              "name": "Пинеда-де-Мар",
              "count": 3
            },
            {
              "id": "145437",
              "name": "Сан-Фелиу-де-Гишольс",
              "count": 2
            }
          ]
        },
        {
          "id": "104188",
          "name": "Провинция Мадрид",
          "count": 24,
          "chld": [
            {
              "id": "10435",
              "name": "Мадрид",
              "count": 19
            }
          ]
        },
        {
          "id": "104178",
          "name": "Андалусия",
          "count": 10,
          "chld": [
            {
              "id": "10437",
              "name": "Малага",
              "count": 5
            },
            {
              "id": "10438",
              "name": "Марбелья",
              "count": 1
            },
            {
              "id": "10440",
              "name": "Севилья",
              "count": 1
            },
            {
              "id": "123329",
              "name": "Чиклана-де-ла-Фронтера",
              "count": 1
            }
          ]
        },
        {
          "id": "104182",
          "name": "Провинция Валенсия",
          "count": 9,
          "chld": [
            {
              "id": "10430",
              "name": "Валенсия",
              "count": 3
            },
            {
              "id": "20835",
              "name": "Аликанте",
              "count": 1
            },
            {
              "id": "116443",
              "name": "Гандия",
              "count": 1
            },
            {
              "id": "145186",
              "name": "Гвардамар-дель-Сегура",
              "count": 1
            },
            {
              "id": "123345",
              "name": "Дения",
              "count": 1
            }
          ]
        },
        {
          "id": "21616",
          "name": "Балеарские острова",
          "count": 5,
          "chld": [
            {
              "id": "105092",
              "name": "Пальма",
              "count": 4
            }
          ]
        },
        {
          "id": "104181",
          "name": "Страна Басков",
          "count": 5,
          "chld": [
            {
              "id": "109265",
              "name": "Бильбао",
              "count": 2
            },
            {
              "id": "109295",
              "name": "Сан-Себастьян",
              "count": 2
            }
          ]
        },
        {
          "id": "104189",
          "name": "Провинция Мурсия",
          "count": 2,
          "chld": [
            {
              "id": "100951",
              "name": "Мурсия",
              "count": 2
            }
          ]
        },
        {
          "id": "104179",
          "name": "Арагон",
          "count": 2,
          "chld": [
            {
              "id": "20946",
              "name": "Сарагоса",
              "count": 1
            }
          ]
        },
        {
          "id": "21618",
          "name": "Канарские острова",
          "count": 2,
          "chld": [
            {
              "id": "100494",
              "name": "Лас-Пальмас-де-Гран-Канария",
              "count": 1
            },
            {
              "id": "120779",
              "name": "Пуэрто-де-ла-Крус",
              "count": 1
            }
          ]
        },
        {
          "id": "104185",
          "name": "Кастилия-Ла-Манча",
          "count": 1
        }
      ]
    },
    {
      "id": "123",
      "name": "Финляндия",
      "count": 114,
      "chld": [
        {
          "id": "104357",
          "name": "Уусимаа",
          "count": 103,
          "chld": [
            {
              "id": "10493",
              "name": "Хельсинки",
              "count": 100
            },
            {
              "id": "155421",
              "name": "Вантаа",
              "count": 2
            }
          ]
        },
        {
          "id": "105117",
          "name": "Пирканмаа",
          "count": 4,
          "chld": [
            {
              "id": "101514",
              "name": "Тампере",
              "count": 3
            }
          ]
        },
        {
          "id": "104353",
          "name": "Варсинайс-Суоми",
          "count": 1,
          "chld": [
            {
              "id": "10494",
              "name": "Турку",
              "count": 1
            }
          ]
        },
        {
          "id": "104352",
          "name": "Кески-Суоми",
          "count": 1,
          "chld": [
            {
              "id": "105123",
              "name": "Ювяскюля",
              "count": 1
            }
          ]
        },
        {
          "id": "105108",
          "name": "Похьойс-Похьянмаа",
          "count": 1,
          "chld": [
            {
              "id": "100531",
              "name": "Оулу",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "181",
      "name": "Израиль",
      "count": 113,
      "chld": [
        {
          "id": "103905",
          "name": "Тель-Авивский АО",
          "count": 62,
          "chld": [
            {
              "id": "131",
              "name": "Тель-Авив",
              "count": 47
            },
            {
              "id": "218692",
              "name": "Рамат-Ган",
              "count": 5
            },
            {
              "id": "108938",
              "name": "Герцлия",
              "count": 3
            },
            {
              "id": "114768",
              "name": "Холон",
              "count": 2
            },
            {
              "id": "101443",
              "name": "Бат-Ям",
              "count": 1
            },
            {
              "id": "218691",
              "name": "Бнэй-Брак",
              "count": 1
            },
            {
              "id": "218690",
              "name": "Рамат-ха-Шарон",
              "count": 1
            }
          ]
        },
        {
          "id": "103901",
          "name": "Хайфский АО",
          "count": 16,
          "chld": [
            {
              "id": "132",
              "name": "Хайфа",
              "count": 8
            },
            {
              "id": "100548",
              "name": "Хадера",
              "count": 6
            },
            {
              "id": "218687",
              "name": "Кирьят Бьялика",
              "count": 2
            }
          ]
        },
        {
          "id": "103900",
          "name": "Центральный АО",
          "count": 16,
          "chld": [
            {
              "id": "116114",
              "name": "Ришон-ле-Цион",
              "count": 6
            },
            {
              "id": "101739",
              "name": "Петах-Тиква",
              "count": 5
            },
            {
              "id": "218672",
              "name": "Ган Явне",
              "count": 1
            },
            {
              "id": "218679",
              "name": "Лод",
              "count": 1
            },
            {
              "id": "101512",
              "name": "Нетания",
              "count": 1
            },
            {
              "id": "101646",
              "name": "Реховот",
              "count": 1
            },
            {
              "id": "218682",
              "name": "Шохам",
              "count": 1
            }
          ]
        },
        {
          "id": "103903",
          "name": "Иерусалимский АО",
          "count": 6,
          "chld": [
            {
              "id": "130",
              "name": "Иерусалим",
              "count": 6
            }
          ]
        },
        {
          "id": "103904",
          "name": "Южный АО",
          "count": 4,
          "chld": [
            {
              "id": "129",
              "name": "Беэр-Шева",
              "count": 2
            },
            {
              "id": "101509",
              "name": "Кирьят-Гат",
              "count": 1
            },
            {
              "id": "101665",
              "name": "Нетивот",
              "count": 1
            }
          ]
        },
        {
          "id": "103902",
          "name": "Северный АО",
          "count": 4,
          "chld": [
            {
              "id": "116180",
              "name": "Акко",
              "count": 1
            },
            {
              "id": "108935",
              "name": "Маалот-Таршиха",
              "count": 1
            },
            {
              "id": "101570",
              "name": "Нагария",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "205",
      "name": "Италия",
      "count": 100,
      "chld": [
        {
          "id": "104222",
          "name": "Ломбардия",
          "count": 30,
          "chld": [
            {
              "id": "10448",
              "name": "Милан",
              "count": 20
            },
            {
              "id": "114898",
              "name": "Галларате",
              "count": 3
            },
            {
              "id": "145117",
              "name": "Порлецца",
              "count": 1
            }
          ]
        },
        {
          "id": "104220",
          "name": "Лацио",
          "count": 16,
          "chld": [
            {
              "id": "10445",
              "name": "Рим",
              "count": 15
            }
          ]
        },
        {
          "id": "104225",
          "name": "Пьемонт",
          "count": 11,
          "chld": [
            {
              "id": "10449",
              "name": "Турин",
              "count": 10
            },
            {
              "id": "101744",
              "name": "Новара",
              "count": 1
            }
          ]
        },
        {
          "id": "104213",
          "name": "Абруццо",
          "count": 6,
          "chld": [
            {
              "id": "101408",
              "name": "Ланчано",
              "count": 2
            },
            {
              "id": "145050",
              "name": "Франкавилла-аль-Маре",
              "count": 1
            }
          ]
        },
        {
          "id": "104228",
          "name": "Тоскана",
          "count": 4,
          "chld": [
            {
              "id": "10461",
              "name": "Флоренция",
              "count": 3
            },
            {
              "id": "123504",
              "name": "Прато",
              "count": 1
            }
          ]
        },
        {
          "id": "104232",
          "name": "Эмилия-Романья",
          "count": 4,
          "chld": [
            {
              "id": "10444",
              "name": "Болонья",
              "count": 1
            },
            {
              "id": "111969",
              "name": "Модена",
              "count": 1
            },
            {
              "id": "101518",
              "name": "Парма",
              "count": 1
            }
          ]
        },
        {
          "id": "104219",
          "name": "Кампания",
          "count": 2,
          "chld": [
            {
              "id": "100947",
              "name": "Салерно",
              "count": 2
            }
          ]
        },
        {
          "id": "104226",
          "name": "Сардиния",
          "count": 2,
          "chld": [
            {
              "id": "10453",
              "name": "Кальяри",
              "count": 2
            }
          ]
        },
        {
          "id": "104217",
          "name": "Венето",
          "count": 2,
          "chld": [
            {
              "id": "10452",
              "name": "Верона",
              "count": 1
            },
            {
              "id": "101431",
              "name": "Тревизо",
              "count": 1
            }
          ]
        },
        {
          "id": "104214",
          "name": "Апулия",
          "count": 1,
          "chld": [
            {
              "id": "21035",
              "name": "Бари",
              "count": 1
            }
          ]
        },
        {
          "id": "104221",
          "name": "Лигурия",
          "count": 1,
          "chld": [
            {
              "id": "10447",
              "name": "Генуя",
              "count": 1
            }
          ]
        },
        {
          "id": "104227",
          "name": "Сицилия",
          "count": 1,
          "chld": [
            {
              "id": "10456",
              "name": "Палермо",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "117",
      "name": "Литва",
      "count": 92,
      "chld": [
        {
          "id": "104260",
          "name": "Вильнюсский уезд",
          "count": 65,
          "chld": [
            {
              "id": "11475",
              "name": "Вильнюс",
              "count": 60
            },
            {
              "id": "104643",
              "name": "Лентварис",
              "count": 4
            },
            {
              "id": "101066",
              "name": "Пабраде",
              "count": 1
            }
          ]
        },
        {
          "id": "104262",
          "name": "Клайпедский уезд",
          "count": 6,
          "chld": [
            {
              "id": "11477",
              "name": "Клайпеда",
              "count": 5
            }
          ]
        },
        {
          "id": "104261",
          "name": "Каунасский уезд",
          "count": 3,
          "chld": [
            {
              "id": "11476",
              "name": "Каунас",
              "count": 2
            },
            {
              "id": "112961",
              "name": "Йонава",
              "count": 1
            }
          ]
        },
        {
          "id": "104264",
          "name": "Паневежский уезд",
          "count": 2,
          "chld": [
            {
              "id": "112971",
              "name": "Биржай",
              "count": 1
            },
            {
              "id": "101527",
              "name": "Паневежис",
              "count": 1
            }
          ]
        },
        {
          "id": "104267",
          "name": "Утенский уезд",
          "count": 1,
          "chld": [
            {
              "id": "109150",
              "name": "Висагинас",
              "count": 1
            }
          ]
        },
        {
          "id": "104268",
          "name": "Шяуляйский уезд",
          "count": 1,
          "chld": [
            {
              "id": "20814",
              "name": "Шяуляй",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "168",
      "name": "Армения",
      "count": 88,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 69,
          "chld": [
            {
              "id": "10262",
              "name": "Ереван",
              "count": 69
            }
          ]
        },
        {
          "id": "105789",
          "name": "Область Арагацотн",
          "count": 2,
          "chld": [
            {
              "id": "116117",
              "name": "Апаран",
              "count": 1
            },
            {
              "id": "105799",
              "name": "Аштарак",
              "count": 1
            }
          ]
        },
        {
          "id": "105791",
          "name": "Область Армавир",
          "count": 2,
          "chld": [
            {
              "id": "116122",
              "name": "Вагаршапат",
              "count": 1
            }
          ]
        },
        {
          "id": "105796",
          "name": "Область Сюник",
          "count": 2,
          "chld": [
            {
              "id": "116140",
              "name": "Горис",
              "count": 1
            },
            {
              "id": "116143",
              "name": "Мегри",
              "count": 1
            }
          ]
        },
        {
          "id": "105790",
          "name": "Область Арарат",
          "count": 1,
          "chld": [
            {
              "id": "105800",
              "name": "Арташат",
              "count": 1
            }
          ]
        },
        {
          "id": "105793",
          "name": "Область Котайк",
          "count": 1,
          "chld": [
            {
              "id": "116132",
              "name": "Чаренцаван",
              "count": 1
            }
          ]
        },
        {
          "id": "105795",
          "name": "Область Ширак",
          "count": 1,
          "chld": [
            {
              "id": "10259",
              "name": "Гюмри",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "125",
      "name": "Чехия",
      "count": 74,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 31,
          "chld": [
            {
              "id": "10511",
              "name": "Прага",
              "count": 31
            }
          ]
        },
        {
          "id": "104393",
          "name": "Южноморавский край",
          "count": 9,
          "chld": [
            {
              "id": "10509",
              "name": "Брно",
              "count": 9
            }
          ]
        },
        {
          "id": "104383",
          "name": "Среднечешский край",
          "count": 9,
          "chld": [
            {
              "id": "107231",
              "name": "Млада-Болеслав",
              "count": 3
            },
            {
              "id": "107306",
              "name": "Подебрады",
              "count": 2
            },
            {
              "id": "107155",
              "name": "Кладно",
              "count": 1
            },
            {
              "id": "107171",
              "name": "Кралупы над Влтавоу",
              "count": 1
            }
          ]
        },
        {
          "id": "104386",
          "name": "Карловарский край",
          "count": 3,
          "chld": [
            {
              "id": "107467",
              "name": "Хеб",
              "count": 2
            },
            {
              "id": "10510",
              "name": "Карловы Вары",
              "count": 1
            }
          ]
        },
        {
          "id": "104391",
          "name": "Оломоуцкий край",
          "count": 2,
          "chld": [
            {
              "id": "105087",
              "name": "Оломоуц",
              "count": 2
            }
          ]
        },
        {
          "id": "104389",
          "name": "Краловеградецкий край",
          "count": 1,
          "chld": [
            {
              "id": "105085",
              "name": "Градец-Кралове",
              "count": 1
            }
          ]
        },
        {
          "id": "104388",
          "name": "Либерецкий край",
          "count": 1,
          "chld": [
            {
              "id": "105084",
              "name": "Либерец",
              "count": 1
            }
          ]
        },
        {
          "id": "104392",
          "name": "Моравскосилезский край",
          "count": 1,
          "chld": [
            {
              "id": "107395",
              "name": "Студенка",
              "count": 1
            }
          ]
        },
        {
          "id": "104390",
          "name": "Пардубицкий край",
          "count": 1,
          "chld": [
            {
              "id": "105086",
              "name": "Пардубице",
              "count": 1
            }
          ]
        },
        {
          "id": "104385",
          "name": "Пльзенский край",
          "count": 1,
          "chld": [
            {
              "id": "100482",
              "name": "Клатови",
              "count": 1
            }
          ]
        },
        {
          "id": "104387",
          "name": "Устецкий край",
          "count": 1,
          "chld": [
            {
              "id": "107143",
              "name": "Йирков",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "95",
      "name": "Канада",
      "count": 74,
      "chld": [
        {
          "id": "29372",
          "name": "Провинция Онтарио",
          "count": 22,
          "chld": [
            {
              "id": "10115",
              "name": "Торонто",
              "count": 10
            },
            {
              "id": "103581",
              "name": "Торнхилл",
              "count": 6
            },
            {
              "id": "103566",
              "name": "Берлингтон",
              "count": 1
            },
            {
              "id": "103569",
              "name": "Лондон",
              "count": 1
            },
            {
              "id": "103563",
              "name": "Миссиссага",
              "count": 1
            },
            {
              "id": "103593",
              "name": "Ньюмаркет",
              "count": 1
            },
            {
              "id": "103597",
              "name": "Пикеринг",
              "count": 1
            },
            {
              "id": "103620",
              "name": "Ричмонд-Хилл",
              "count": 1
            }
          ]
        },
        {
          "id": "29371",
          "name": "Провинция Манитоба",
          "count": 11,
          "chld": [
            {
              "id": "21380",
              "name": "Виннипег",
              "count": 10
            }
          ]
        },
        {
          "id": "29373",
          "name": "Провинция Квебек",
          "count": 11,
          "chld": [
            {
              "id": "103634",
              "name": "Лаваль",
              "count": 4
            },
            {
              "id": "21276",
              "name": "Монреаль",
              "count": 4
            },
            {
              "id": "103664",
              "name": "Верден",
              "count": 1
            }
          ]
        },
        {
          "id": "29368",
          "name": "Провинция Британская Колумбия",
          "count": 8,
          "chld": [
            {
              "id": "21353",
              "name": "Ванкувер",
              "count": 3
            },
            {
              "id": "103570",
              "name": "Нью-Вестминстер",
              "count": 3
            },
            {
              "id": "103623",
              "name": "Дельта",
              "count": 1
            },
            {
              "id": "103609",
              "name": "Ричмонд",
              "count": 1
            }
          ]
        },
        {
          "id": "29369",
          "name": "Провинция Альберта",
          "count": 5,
          "chld": [
            {
              "id": "21457",
              "name": "Калгари",
              "count": 2
            },
            {
              "id": "103605",
              "name": "Гранд-Прейри",
              "count": 1
            },
            {
              "id": "103615",
              "name": "Летбридж",
              "count": 1
            },
            {
              "id": "20806",
              "name": "Эдмонтон",
              "count": 1
            }
          ]
        },
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "10114",
              "name": "Оттава",
              "count": 1
            }
          ]
        },
        {
          "id": "29375",
          "name": "Провинция Новая Шотландия",
          "count": 1,
          "chld": [
            {
              "id": "21403",
              "name": "Галифакс",
              "count": 1
            }
          ]
        },
        {
          "id": "29374",
          "name": "Провинция Нью-Брансуик",
          "count": 1,
          "chld": [
            {
              "id": "71609",
              "name": "Сент-Джон",
              "count": 1
            }
          ]
        },
        {
          "id": "29377",
          "name": "Провинция Ньюфаундленд и Лабрадор",
          "count": 1
        }
      ]
    },
    {
      "id": "113",
      "name": "Австрия",
      "count": 65,
      "chld": [
        {
          "id": "104136",
          "name": "Земля Вена",
          "count": 40,
          "chld": [
            {
              "id": "10371",
              "name": "Вена",
              "count": 40
            }
          ]
        },
        {
          "id": "104131",
          "name": "Верхняя Австрия",
          "count": 3,
          "chld": [
            {
              "id": "21163",
              "name": "Линц",
              "count": 3
            }
          ]
        },
        {
          "id": "104133",
          "name": "Штирия",
          "count": 3,
          "chld": [
            {
              "id": "105220",
              "name": "Грац",
              "count": 3
            }
          ]
        },
        {
          "id": "104129",
          "name": "Каринтия",
          "count": 1,
          "chld": [
            {
              "id": "105218",
              "name": "Клагенфурт",
              "count": 1
            }
          ]
        },
        {
          "id": "104130",
          "name": "Нижняя Австрия",
          "count": 1,
          "chld": [
            {
              "id": "115045",
              "name": "Винер-Нойштадт",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "127",
      "name": "Швеция",
      "count": 65,
      "chld": [
        {
          "id": "104437",
          "name": "Лен Стокгольм",
          "count": 32,
          "chld": [
            {
              "id": "10519",
              "name": "Стокгольм",
              "count": 30
            }
          ]
        },
        {
          "id": "104448",
          "name": "Лен Вестра-Гёталанд",
          "count": 3,
          "chld": [
            {
              "id": "112005",
              "name": "Бурос",
              "count": 1
            },
            {
              "id": "10518",
              "name": "Гётеборг",
              "count": 1
            }
          ]
        },
        {
          "id": "104440",
          "name": "Лен Эстергётланд",
          "count": 1,
          "chld": [
            {
              "id": "105154",
              "name": "Линчёпинг",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "209",
      "name": "Таджикистан",
      "count": 62,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 43,
          "chld": [
            {
              "id": "10318",
              "name": "Душанбе",
              "count": 43
            }
          ]
        },
        {
          "id": "105968",
          "name": "Согдийская область",
          "count": 7,
          "chld": [
            {
              "id": "10322",
              "name": "Худжанд",
              "count": 7
            }
          ]
        }
      ]
    },
    {
      "id": "115",
      "name": "Болгария",
      "count": 52,
      "chld": [
        {
          "id": "104156",
          "name": "Городская область София",
          "count": 22,
          "chld": [
            {
              "id": "10379",
              "name": "София",
              "count": 22
            }
          ]
        },
        {
          "id": "104160",
          "name": "Варненская область",
          "count": 8,
          "chld": [
            {
              "id": "10381",
              "name": "Варна",
              "count": 8
            }
          ]
        },
        {
          "id": "104138",
          "name": "Бургасская область",
          "count": 6,
          "chld": [
            {
              "id": "10380",
              "name": "Бургас",
              "count": 6
            }
          ]
        },
        {
          "id": "104149",
          "name": "Пловдивская область",
          "count": 4,
          "chld": [
            {
              "id": "10385",
              "name": "Пловдив",
              "count": 3
            }
          ]
        },
        {
          "id": "104157",
          "name": "Софийская область",
          "count": 3
        },
        {
          "id": "104137",
          "name": "Благоевградская область",
          "count": 1
        },
        {
          "id": "104161",
          "name": "Великотырновская область",
          "count": 1,
          "chld": [
            {
              "id": "21362",
              "name": "Велико-Тырново",
              "count": 1
            }
          ]
        },
        {
          "id": "104139",
          "name": "Добричская область",
          "count": 1,
          "chld": [
            {
              "id": "105137",
              "name": "Добрич",
              "count": 1
            }
          ]
        },
        {
          "id": "104148",
          "name": "Плевенская область",
          "count": 1,
          "chld": [
            {
              "id": "21305",
              "name": "Плевен",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "134",
      "name": "Китай",
      "count": 44,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 8,
          "chld": [
            {
              "id": "10599",
              "name": "Шанхай",
              "count": 5
            },
            {
              "id": "10590",
              "name": "Пекин",
              "count": 2
            },
            {
              "id": "21073",
              "name": "Тяньцзинь",
              "count": 1
            }
          ]
        },
        {
          "id": "10584",
          "name": "Гонконг (Сянган)",
          "count": 5,
          "chld": [
            {
              "id": "11514",
              "name": "Гонконг",
              "count": 5
            }
          ]
        },
        {
          "id": "114552",
          "name": "Гуандун",
          "count": 4,
          "chld": [
            {
              "id": "109371",
              "name": "Шэньчжэнь",
              "count": 4
            }
          ]
        },
        {
          "id": "114568",
          "name": "Цзянсу",
          "count": 3,
          "chld": [
            {
              "id": "98538",
              "name": "Нанкин",
              "count": 1
            },
            {
              "id": "10598",
              "name": "Чанчжоу",
              "count": 1
            },
            {
              "id": "111586",
              "name": "Янчжоу",
              "count": 1
            }
          ]
        },
        {
          "id": "114574",
          "name": "Шаньси",
          "count": 2,
          "chld": [
            {
              "id": "21090",
              "name": "Тайюань",
              "count": 2
            }
          ]
        },
        {
          "id": "114572",
          "name": "Шэньси",
          "count": 2,
          "chld": [
            {
              "id": "10591",
              "name": "Сиань",
              "count": 2
            }
          ]
        },
        {
          "id": "114563",
          "name": "Хэйлунцзян",
          "count": 2,
          "chld": [
            {
              "id": "114617",
              "name": "Дацин",
              "count": 1
            },
            {
              "id": "10596",
              "name": "Харбин",
              "count": 1
            }
          ]
        },
        {
          "id": "114555",
          "name": "Сычуань",
          "count": 1,
          "chld": [
            {
              "id": "20897",
              "name": "Чэнду",
              "count": 1
            }
          ]
        },
        {
          "id": "114557",
          "name": "Фуцзянь",
          "count": 1,
          "chld": [
            {
              "id": "114945",
              "name": "Путянь",
              "count": 1
            }
          ]
        },
        {
          "id": "114571",
          "name": "Чжэцзян",
          "count": 1,
          "chld": [
            {
              "id": "109370",
              "name": "Нинбо",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "180",
      "name": "Сербия",
      "count": 43,
      "chld": [
        {
          "id": "104492",
          "name": "Округ Белград",
          "count": 30,
          "chld": [
            {
              "id": "10522",
              "name": "Белград",
              "count": 30
            }
          ]
        },
        {
          "id": "105392",
          "name": "Южно-Бачский округ",
          "count": 6,
          "chld": [
            {
              "id": "105422",
              "name": "Нови-Сад",
              "count": 6
            }
          ]
        },
        {
          "id": "105393",
          "name": "Косовский округ",
          "count": 1,
          "chld": [
            {
              "id": "21311",
              "name": "Приштина",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "126",
      "name": "Швейцария",
      "count": 40,
      "chld": [
        {
          "id": "104396",
          "name": "Кантон Цюрих",
          "count": 13,
          "chld": [
            {
              "id": "10515",
              "name": "Цюрих",
              "count": 8
            },
            {
              "id": "114656",
              "name": "Винтертур",
              "count": 1
            }
          ]
        },
        {
          "id": "104417",
          "name": "Кантон Во",
          "count": 5,
          "chld": [
            {
              "id": "10520",
              "name": "Лозанна",
              "count": 3
            }
          ]
        },
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 4,
          "chld": [
            {
              "id": "10513",
              "name": "Берн",
              "count": 4
            }
          ]
        },
        {
          "id": "104420",
          "name": "Кантон Женева",
          "count": 4,
          "chld": [
            {
              "id": "10514",
              "name": "Женева",
              "count": 3
            }
          ]
        },
        {
          "id": "104416",
          "name": "Кантон Тичино",
          "count": 3,
          "chld": [
            {
              "id": "109082",
              "name": "Лугано",
              "count": 3
            }
          ]
        },
        {
          "id": "104406",
          "name": "Кантон Золотурн",
          "count": 2
        },
        {
          "id": "104412",
          "name": "Кантон Санкт-Галлен",
          "count": 2
        },
        {
          "id": "104397",
          "name": "Кантон Берн",
          "count": 2,
          "chld": [
            {
              "id": "114659",
              "name": "Тун",
              "count": 1
            }
          ]
        },
        {
          "id": "104404",
          "name": "Кантон Цуг",
          "count": 1,
          "chld": [
            {
              "id": "105201",
              "name": "Цуг",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "119",
      "name": "Норвегия",
      "count": 39,
      "chld": [
        {
          "id": "104322",
          "name": "Фюльке Осло",
          "count": 19,
          "chld": [
            {
              "id": "10467",
              "name": "Осло",
              "count": 19
            }
          ]
        },
        {
          "id": "104326",
          "name": "Вестфолл",
          "count": 8,
          "chld": [
            {
              "id": "105173",
              "name": "Тёнсберг",
              "count": 8
            }
          ]
        },
        {
          "id": "104321",
          "name": "Ругаланд",
          "count": 1,
          "chld": [
            {
              "id": "113951",
              "name": "Хёугесунн",
              "count": 1
            }
          ]
        },
        {
          "id": "104317",
          "name": "Сёр-Трёнделаг",
          "count": 1,
          "chld": [
            {
              "id": "101419",
              "name": "Тронхейм",
              "count": 1
            }
          ]
        },
        {
          "id": "104327",
          "name": "Телемарк",
          "count": 1
        },
        {
          "id": "104314",
          "name": "Тромс",
          "count": 1,
          "chld": [
            {
              "id": "101407",
              "name": "Тромсё",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "135",
      "name": "Южная Корея",
      "count": 37,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 25,
          "chld": [
            {
              "id": "10634",
              "name": "Пусан",
              "count": 12
            },
            {
              "id": "10635",
              "name": "Сеул",
              "count": 8
            },
            {
              "id": "101446",
              "name": "Инчхон",
              "count": 3
            },
            {
              "id": "47156",
              "name": "Кванджу",
              "count": 1
            },
            {
              "id": "114234",
              "name": "Тэджон",
              "count": 1
            }
          ]
        },
        {
          "id": "114957",
          "name": "Кёнгидо",
          "count": 4,
          "chld": [
            {
              "id": "114996",
              "name": "Коян",
              "count": 2
            },
            {
              "id": "114970",
              "name": "Ансон",
              "count": 1
            },
            {
              "id": "115012",
              "name": "Пхаджу",
              "count": 1
            }
          ]
        },
        {
          "id": "114955",
          "name": "Чхунчхон-Намдо",
          "count": 1,
          "chld": [
            {
              "id": "114235",
              "name": "Чхонан",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "210",
      "name": "Объединённые Арабские Эмираты",
      "count": 35,
      "chld": [
        {
          "id": "121479",
          "name": "Эмират Дубай",
          "count": 22,
          "chld": [
            {
              "id": "11499",
              "name": "Дубай",
              "count": 22
            }
          ]
        },
        {
          "id": "121477",
          "name": "Эмират Абу-Даби",
          "count": 7,
          "chld": [
            {
              "id": "11498",
              "name": "Абу-Даби",
              "count": 7
            }
          ]
        },
        {
          "id": "121483",
          "name": "Эмират Шарджа",
          "count": 4,
          "chld": [
            {
              "id": "20843",
              "name": "Шарджа",
              "count": 4
            }
          ]
        },
        {
          "id": "121478",
          "name": "Эмират Аджман",
          "count": 1,
          "chld": [
            {
              "id": "41195",
              "name": "Аджман",
              "count": 1
            }
          ]
        },
        {
          "id": "121482",
          "name": "Эмират Эль-Фуджайра",
          "count": 1,
          "chld": [
            {
              "id": "41198",
              "name": "Эль-Фуджайра",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "170",
      "name": "Туркменистан",
      "count": 32,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 22,
          "chld": [
            {
              "id": "10324",
              "name": "Ашхабад",
              "count": 22
            }
          ]
        },
        {
          "id": "105952",
          "name": "Лебапский велаят",
          "count": 2,
          "chld": [
            {
              "id": "10325",
              "name": "Туркменабад",
              "count": 2
            }
          ]
        },
        {
          "id": "105953",
          "name": "Марыйский велаят",
          "count": 2,
          "chld": [
            {
              "id": "101477",
              "name": "Мары",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "10077",
      "name": "Румыния",
      "count": 28,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 22,
          "chld": [
            {
              "id": "10487",
              "name": "Бухарест",
              "count": 22
            }
          ]
        },
        {
          "id": "105320",
          "name": "Долж",
          "count": 1,
          "chld": [
            {
              "id": "105373",
              "name": "Крайова",
              "count": 1
            }
          ]
        },
        {
          "id": "105340",
          "name": "Жудец Тулча",
          "count": 1,
          "chld": [
            {
              "id": "105370",
              "name": "Тулча",
              "count": 1
            }
          ]
        },
        {
          "id": "105322",
          "name": "Илфов",
          "count": 1
        }
      ]
    },
    {
      "id": "116",
      "name": "Венгрия",
      "count": 28,
      "chld": [
        {
          "id": "104483",
          "name": "Пешт",
          "count": 19,
          "chld": [
            {
              "id": "10398",
              "name": "Будапешт",
              "count": 19
            }
          ]
        },
        {
          "id": "104474",
          "name": "Боршод-Абауй-Земплен",
          "count": 1
        },
        {
          "id": "104487",
          "name": "Ваш",
          "count": 1
        },
        {
          "id": "104488",
          "name": "Медье Веспрем",
          "count": 1,
          "chld": [
            {
              "id": "105302",
              "name": "Веспрем",
              "count": 1
            }
          ]
        },
        {
          "id": "104485",
          "name": "Сабольч-Сатмар-Берег",
          "count": 1
        },
        {
          "id": "104478",
          "name": "Хайду-Бихар",
          "count": 1,
          "chld": [
            {
              "id": "21466",
              "name": "Дебрецен",
              "count": 1
            }
          ]
        },
        {
          "id": "104480",
          "name": "Яс-Надькун-Сольнок",
          "count": 1
        }
      ]
    },
    {
      "id": "10093",
      "name": "Вьетнам",
      "count": 28,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 18,
          "chld": [
            {
              "id": "10552",
              "name": "Ханой",
              "count": 8
            },
            {
              "id": "10553",
              "name": "Хошимин",
              "count": 7
            },
            {
              "id": "10543",
              "name": "Дананг",
              "count": 1
            },
            {
              "id": "114516",
              "name": "Кантхо",
              "count": 1
            },
            {
              "id": "20923",
              "name": "Хайфон",
              "count": 1
            }
          ]
        },
        {
          "id": "145629",
          "name": "Провинция Бариа-Вунгтау",
          "count": 1,
          "chld": [
            {
              "id": "114524",
              "name": "Вунгтау",
              "count": 1
            }
          ]
        },
        {
          "id": "145659",
          "name": "Провинция Намдинь",
          "count": 1,
          "chld": [
            {
              "id": "21317",
              "name": "Намдинь",
              "count": 1
            }
          ]
        },
        {
          "id": "145660",
          "name": "Провинция Нгеан",
          "count": 1,
          "chld": [
            {
              "id": "21381",
              "name": "Винь",
              "count": 1
            }
          ]
        },
        {
          "id": "145667",
          "name": "Провинция Тхыатхьен-Хюэ",
          "count": 1,
          "chld": [
            {
              "id": "20905",
              "name": "Хюэ",
              "count": 1
            }
          ]
        },
        {
          "id": "145671",
          "name": "Провинция Хайзыонг",
          "count": 1,
          "chld": [
            {
              "id": "145707",
              "name": "Хайзыонг",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "246",
      "name": "Греция",
      "count": 26,
      "chld": [
        {
          "id": "104165",
          "name": "Аттика",
          "count": 14,
          "chld": [
            {
              "id": "10418",
              "name": "Афины",
              "count": 14
            }
          ]
        },
        {
          "id": "104167",
          "name": "Центральная Македония",
          "count": 8,
          "chld": [
            {
              "id": "10424",
              "name": "Салоники",
              "count": 8
            }
          ]
        },
        {
          "id": "104169",
          "name": "Восточная Македония и Фракия",
          "count": 2,
          "chld": [
            {
              "id": "109163",
              "name": "Александруполис",
              "count": 1
            },
            {
              "id": "112456",
              "name": "Ксанти",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "994",
      "name": "Индия",
      "count": 26,
      "chld": [
        {
          "id": "114631",
          "name": "Махараштра",
          "count": 13,
          "chld": [
            {
              "id": "10558",
              "name": "Мумбаи",
              "count": 12
            }
          ]
        },
        {
          "id": "108941",
          "name": "Керала",
          "count": 3,
          "chld": [
            {
              "id": "112568",
              "name": "Кожикоде",
              "count": 1
            },
            {
              "id": "20983",
              "name": "Тируванантапурам",
              "count": 1
            },
            {
              "id": "112571",
              "name": "Триссур",
              "count": 1
            }
          ]
        },
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 2,
          "chld": [
            {
              "id": "10562",
              "name": "Дели",
              "count": 2
            }
          ]
        },
        {
          "id": "114627",
          "name": "Западная Бенгалия",
          "count": 1,
          "chld": [
            {
              "id": "20005",
              "name": "Калькутта",
              "count": 1
            }
          ]
        },
        {
          "id": "114635",
          "name": "Орисса",
          "count": 1,
          "chld": [
            {
              "id": "10556",
              "name": "Баласор",
              "count": 1
            }
          ]
        },
        {
          "id": "115669",
          "name": "Телангана",
          "count": 1,
          "chld": [
            {
              "id": "20934",
              "name": "Хайдарабад",
              "count": 1
            }
          ]
        },
        {
          "id": "114643",
          "name": "Уттар-Прадеш",
          "count": 1,
          "chld": [
            {
              "id": "10566",
              "name": "Лакхнау",
              "count": 1
            }
          ]
        },
        {
          "id": "114642",
          "name": "Уттаракханд",
          "count": 1,
          "chld": [
            {
              "id": "10563",
              "name": "Дехрадун",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21610",
      "name": "Черногория",
      "count": 26,
      "chld": [
        {
          "id": "105454",
          "name": "Община Подгорица",
          "count": 12,
          "chld": [
            {
              "id": "21611",
              "name": "Подгорица",
              "count": 12
            }
          ]
        },
        {
          "id": "105444",
          "name": "Община Будва",
          "count": 11,
          "chld": [
            {
              "id": "13458",
              "name": "Будва",
              "count": 11
            }
          ]
        }
      ]
    },
    {
      "id": "10083",
      "name": "Хорватия",
      "count": 25,
      "chld": [
        {
          "id": "105471",
          "name": "Загребская жупания",
          "count": 24,
          "chld": [
            {
              "id": "10507",
              "name": "Загреб",
              "count": 24
            }
          ]
        },
        {
          "id": "105484",
          "name": "Задарская жупания",
          "count": 1,
          "chld": [
            {
              "id": "105499",
              "name": "Задар",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "93",
      "name": "Аргентина",
      "count": 25,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 20,
          "chld": [
            {
              "id": "10133",
              "name": "Буэнос-Айрес",
              "count": 20
            }
          ]
        },
        {
          "id": "122071",
          "name": "Провинция Буэнос-Айрес",
          "count": 2,
          "chld": [
            {
              "id": "21256",
              "name": "Мар-дель-Плата",
              "count": 2
            }
          ]
        },
        {
          "id": "122075",
          "name": "Провинция Кордова",
          "count": 1,
          "chld": [
            {
              "id": "20837",
              "name": "Кордова",
              "count": 1
            }
          ]
        },
        {
          "id": "122087",
          "name": "Провинция Сальта",
          "count": 1,
          "chld": [
            {
              "id": "20772",
              "name": "Сальта",
              "count": 1
            }
          ]
        },
        {
          "id": "122089",
          "name": "Провинция Сан-Луис",
          "count": 1,
          "chld": [
            {
              "id": "109429",
              "name": "Сан-Луис",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "20574",
      "name": "Кипр",
      "count": 25,
      "chld": [
        {
          "id": "105221",
          "name": "Район Никосия",
          "count": 11,
          "chld": [
            {
              "id": "20575",
              "name": "Никосия",
              "count": 11
            }
          ]
        },
        {
          "id": "105224",
          "name": "Район Лимассол",
          "count": 8,
          "chld": [
            {
              "id": "21159",
              "name": "Лимассол",
              "count": 8
            }
          ]
        },
        {
          "id": "105223",
          "name": "Район Ларнака",
          "count": 5,
          "chld": [
            {
              "id": "10421",
              "name": "Ларнака",
              "count": 5
            }
          ]
        },
        {
          "id": "105225",
          "name": "Район Пафос",
          "count": 1,
          "chld": [
            {
              "id": "21242",
              "name": "Пафос",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "995",
      "name": "Таиланд",
      "count": 23,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 11,
          "chld": [
            {
              "id": "10620",
              "name": "Бангкок",
              "count": 11
            }
          ]
        },
        {
          "id": "122005",
          "name": "Провинция Пхукет",
          "count": 5,
          "chld": [
            {
              "id": "10622",
              "name": "Пхукет",
              "count": 4
            },
            {
              "id": "10624",
              "name": "Ко-Мапрао",
              "count": 1
            }
          ]
        },
        {
          "id": "121968",
          "name": "Провинция Кхонкэн",
          "count": 1,
          "chld": [
            {
              "id": "114402",
              "name": "Кхонкэн",
              "count": 1
            }
          ]
        },
        {
          "id": "121982",
          "name": "Провинция Прачуапкхирикхан",
          "count": 1,
          "chld": [
            {
              "id": "109044",
              "name": "Хуахин",
              "count": 1
            }
          ]
        },
        {
          "id": "121955",
          "name": "Провинция Пхетчабун",
          "count": 1,
          "chld": [
            {
              "id": "109056",
              "name": "Пхетчабун",
              "count": 1
            }
          ]
        },
        {
          "id": "121988",
          "name": "Провинция Сарабури",
          "count": 1,
          "chld": [
            {
              "id": "116361",
              "name": "Сарабури",
              "count": 1
            }
          ]
        },
        {
          "id": "122010",
          "name": "Провинция Транг",
          "count": 1,
          "chld": [
            {
              "id": "114416",
              "name": "Транг",
              "count": 1
            }
          ]
        },
        {
          "id": "122020",
          "name": "Провинция Убонратчатхани",
          "count": 1,
          "chld": [
            {
              "id": "114406",
              "name": "Убонратчатхани",
              "count": 1
            }
          ]
        },
        {
          "id": "121997",
          "name": "Провинция Чонбури",
          "count": 1,
          "chld": [
            {
              "id": "21341",
              "name": "Паттайя",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "114",
      "name": "Бельгия",
      "count": 21,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 15,
          "chld": [
            {
              "id": "10376",
              "name": "Брюссель",
              "count": 13
            },
            {
              "id": "111183",
              "name": "Турне",
              "count": 1
            },
            {
              "id": "111176",
              "name": "Тюрнхаут",
              "count": 1
            }
          ]
        },
        {
          "id": "105773",
          "name": "Провинция Антверпен",
          "count": 3,
          "chld": [
            {
              "id": "10375",
              "name": "Антверпен",
              "count": 3
            }
          ]
        },
        {
          "id": "105779",
          "name": "Провинция Льеж",
          "count": 2,
          "chld": [
            {
              "id": "10377",
              "name": "Льеж",
              "count": 2
            }
          ]
        },
        {
          "id": "105782",
          "name": "Валлонский Брабант",
          "count": 1
        }
      ]
    },
    {
      "id": "1056",
      "name": "Египет",
      "count": 20,
      "chld": [
        {
          "id": "121455",
          "name": "Мухафаза Каир",
          "count": 9,
          "chld": [
            {
              "id": "11485",
              "name": "Каир",
              "count": 9
            }
          ]
        },
        {
          "id": "121446",
          "name": "Мухафаза Красное Море",
          "count": 4,
          "chld": [
            {
              "id": "11486",
              "name": "Хургада",
              "count": 4
            }
          ]
        },
        {
          "id": "121452",
          "name": "Мухафаза Эль-Гиза",
          "count": 3,
          "chld": [
            {
              "id": "114930",
              "name": "Гиза",
              "count": 3
            }
          ]
        },
        {
          "id": "121454",
          "name": "Мухафаза Эль-Минья",
          "count": 1,
          "chld": [
            {
              "id": "111898",
              "name": "Эль-Минья",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "203",
      "name": "Дания",
      "count": 19,
      "chld": [
        {
          "id": "104466",
          "name": "Столичная область",
          "count": 7
        },
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 5,
          "chld": [
            {
              "id": "10425",
              "name": "Копенгаген",
              "count": 5
            }
          ]
        },
        {
          "id": "104469",
          "name": "Центральная Ютландия",
          "count": 5,
          "chld": [
            {
              "id": "101685",
              "name": "Орхус",
              "count": 2
            }
          ]
        },
        {
          "id": "104467",
          "name": "Зеландия",
          "count": 1,
          "chld": [
            {
              "id": "6170",
              "name": "Роскилле",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10105",
      "name": "Сингапур",
      "count": 18,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 10,
          "chld": [
            {
              "id": "10619",
              "name": "Сингапур",
              "count": 10
            }
          ]
        }
      ]
    },
    {
      "id": "137",
      "name": "Япония",
      "count": 17,
      "chld": [
        {
          "id": "121393",
          "name": "Префектура Токио",
          "count": 8,
          "chld": [
            {
              "id": "10636",
              "name": "Токио",
              "count": 8
            }
          ]
        },
        {
          "id": "121403",
          "name": "Префектура Айти",
          "count": 2,
          "chld": [
            {
              "id": "111975",
              "name": "Нагоя",
              "count": 2
            }
          ]
        },
        {
          "id": "121382",
          "name": "Префектура Хоккайдо",
          "count": 2,
          "chld": [
            {
              "id": "10642",
              "name": "Саппоро",
              "count": 1
            },
            {
              "id": "112891",
              "name": "Хакодате",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "94",
      "name": "Бразилия",
      "count": 16,
      "chld": [
        {
          "id": "29315",
          "name": "Штат Сан-Паулу",
          "count": 7,
          "chld": [
            {
              "id": "20924",
              "name": "Сан-Паулу",
              "count": 5
            },
            {
              "id": "109460",
              "name": "Кампинас",
              "count": 1
            }
          ]
        },
        {
          "id": "29309",
          "name": "Штат Рио-де-Жанейро",
          "count": 3,
          "chld": [
            {
              "id": "21221",
              "name": "Рио-де-Жанейро",
              "count": 2
            },
            {
              "id": "111242",
              "name": "Нова-Фрибургу",
              "count": 1
            }
          ]
        },
        {
          "id": "29303",
          "name": "Штат Минас-Жерайс",
          "count": 1,
          "chld": [
            {
              "id": "109485",
              "name": "Уберландия",
              "count": 1
            }
          ]
        },
        {
          "id": "29307",
          "name": "Штат Пернамбуку",
          "count": 1,
          "chld": [
            {
              "id": "105728",
              "name": "Ресифи",
              "count": 1
            }
          ]
        },
        {
          "id": "29297",
          "name": "Штат Сеара",
          "count": 1,
          "chld": [
            {
              "id": "105735",
              "name": "Форталеза",
              "count": 1
            }
          ]
        },
        {
          "id": "29316",
          "name": "Штат Сержипи",
          "count": 1
        }
      ]
    },
    {
      "id": "121",
      "name": "Словакия",
      "count": 14,
      "chld": [
        {
          "id": "104510",
          "name": "Братиславский край",
          "count": 9,
          "chld": [
            {
              "id": "10489",
              "name": "Братислава",
              "count": 9
            }
          ]
        },
        {
          "id": "104516",
          "name": "Трнавский край",
          "count": 4,
          "chld": [
            {
              "id": "105432",
              "name": "Трнава",
              "count": 3
            }
          ]
        }
      ]
    },
    {
      "id": "10063",
      "name": "Ирландия",
      "count": 13,
      "chld": [
        {
          "id": "105237",
          "name": "Графство Дублин",
          "count": 9,
          "chld": [
            {
              "id": "10426",
              "name": "Дублин",
              "count": 9
            }
          ]
        },
        {
          "id": "105239",
          "name": "Графство Килкенни",
          "count": 1,
          "chld": [
            {
              "id": "100534",
              "name": "Килкенни",
              "count": 1
            }
          ]
        },
        {
          "id": "105250",
          "name": "Графство Корк",
          "count": 1,
          "chld": [
            {
              "id": "3955",
              "name": "Корк",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10099",
      "name": "Монголия",
      "count": 12,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 11,
          "chld": [
            {
              "id": "10606",
              "name": "Улан-Батор",
              "count": 11
            }
          ]
        },
        {
          "id": "144483",
          "name": "Аймак Орхон",
          "count": 1,
          "chld": [
            {
              "id": "101644",
              "name": "Эрдэнэт",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10095",
      "name": "Индонезия",
      "count": 11,
      "chld": [
        {
          "id": "120805",
          "name": "Столичный округ Джакарта",
          "count": 6,
          "chld": [
            {
              "id": "10574",
              "name": "Джакарта",
              "count": 6
            }
          ]
        },
        {
          "id": "10572",
          "name": "Провинция Бали",
          "count": 1,
          "chld": [
            {
              "id": "97237",
              "name": "Нуса-Дуа",
              "count": 1
            }
          ]
        },
        {
          "id": "120806",
          "name": "Провинция Западная Ява",
          "count": 1,
          "chld": [
            {
              "id": "111965",
              "name": "Бандунг",
              "count": 1
            }
          ]
        },
        {
          "id": "120804",
          "name": "Провинция Острова Риау",
          "count": 1
        },
        {
          "id": "120821",
          "name": "Провинция Южный Сулавеси",
          "count": 1,
          "chld": [
            {
              "id": "21115",
              "name": "Макасар",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10536",
      "name": "Иран",
      "count": 11,
      "chld": [
        {
          "id": "121484",
          "name": "Остан Тегеран",
          "count": 5,
          "chld": [
            {
              "id": "11495",
              "name": "Тегеран",
              "count": 5
            }
          ]
        },
        {
          "id": "121507",
          "name": "Остан Исфахан",
          "count": 2,
          "chld": [
            {
              "id": "112699",
              "name": "Исфахан",
              "count": 2
            }
          ]
        },
        {
          "id": "121485",
          "name": "Остан Кум",
          "count": 2,
          "chld": [
            {
              "id": "112696",
              "name": "Кум",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "21203",
      "name": "Люксембург",
      "count": 9,
      "chld": [
        {
          "id": "105439",
          "name": "Округ Люксембург",
          "count": 8,
          "chld": [
            {
              "id": "21204",
              "name": "Люксембург",
              "count": 8
            }
          ]
        }
      ]
    },
    {
      "id": "211",
      "name": "Австралия",
      "count": 9,
      "chld": [
        {
          "id": "21761",
          "name": "Новый Южный Уэльс",
          "count": 3,
          "chld": [
            {
              "id": "10145",
              "name": "Сидней",
              "count": 2
            },
            {
              "id": "111035",
              "name": "Олбери",
              "count": 1
            }
          ]
        },
        {
          "id": "21769",
          "name": "Западная Австралия",
          "count": 2,
          "chld": [
            {
              "id": "21770",
              "name": "Перт",
              "count": 2
            }
          ]
        },
        {
          "id": "21763",
          "name": "Виктория",
          "count": 1,
          "chld": [
            {
              "id": "111031",
              "name": "Бендиго",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10068",
      "name": "Северная Македония",
      "count": 8,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 6,
          "chld": [
            {
              "id": "10463",
              "name": "Скопье",
              "count": 6
            }
          ]
        },
        {
          "id": "105555",
          "name": "Пелагонийский регион",
          "count": 2,
          "chld": [
            {
              "id": "112975",
              "name": "Прилеп",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "20271",
      "name": "Мексика",
      "count": 8,
      "chld": [
        {
          "id": "105662",
          "name": "Штат Мехико",
          "count": 2
        },
        {
          "id": "105679",
          "name": "Штат Халиско",
          "count": 2
        },
        {
          "id": "105683",
          "name": "Федеральный округ",
          "count": 1,
          "chld": [
            {
              "id": "20272",
              "name": "Мехико",
              "count": 1
            }
          ]
        },
        {
          "id": "105653",
          "name": "Штат Герреро",
          "count": 1,
          "chld": [
            {
              "id": "113052",
              "name": "Игуала-де-ла-Индепенденсия",
              "count": 1
            }
          ]
        },
        {
          "id": "105671",
          "name": "Штат Пуэбла",
          "count": 1,
          "chld": [
            {
              "id": "105700",
              "name": "Пуэбла-де-Сарагоса",
              "count": 1
            }
          ]
        },
        {
          "id": "105674",
          "name": "Штат Синалоа",
          "count": 1,
          "chld": [
            {
              "id": "105703",
              "name": "Кульякан",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "122",
      "name": "Словения",
      "count": 7,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 7,
          "chld": [
            {
              "id": "10492",
              "name": "Любляна",
              "count": 7
            }
          ]
        }
      ]
    },
    {
      "id": "29385",
      "name": "Тайвань",
      "count": 7,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 5,
          "chld": [
            {
              "id": "10592",
              "name": "Тайбэй",
              "count": 4
            },
            {
              "id": "112006",
              "name": "Тайчжун",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10108",
      "name": "Филиппины",
      "count": 7,
      "chld": [
        {
          "id": "144393",
          "name": "Столичный регион",
          "count": 4,
          "chld": [
            {
              "id": "10629",
              "name": "Манила",
              "count": 2
            },
            {
              "id": "114892",
              "name": "Макати",
              "count": 1
            },
            {
              "id": "114889",
              "name": "Мандалуйонг",
              "count": 1
            }
          ]
        },
        {
          "id": "144414",
          "name": "Провинция Батангас",
          "count": 1
        }
      ]
    },
    {
      "id": "10074",
      "name": "Португалия",
      "count": 7,
      "chld": [
        {
          "id": "104343",
          "name": "Округ Лиссабон",
          "count": 2,
          "chld": [
            {
              "id": "10478",
              "name": "Лиссабон",
              "count": 1
            },
            {
              "id": "8529",
              "name": "Эшторил",
              "count": 1
            }
          ]
        },
        {
          "id": "104334",
          "name": "Округ Брага",
          "count": 1,
          "chld": [
            {
              "id": "8547",
              "name": "Брага",
              "count": 1
            }
          ]
        },
        {
          "id": "104345",
          "name": "Округ Порту",
          "count": 1
        },
        {
          "id": "104347",
          "name": "Округ Сетубал",
          "count": 1,
          "chld": [
            {
              "id": "101762",
              "name": "Монтижу",
              "count": 1
            }
          ]
        },
        {
          "id": "104348",
          "name": "Округ Фару",
          "count": 1,
          "chld": [
            {
              "id": "123251",
              "name": "Картейра",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10535",
      "name": "Иордания",
      "count": 6,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 6,
          "chld": [
            {
              "id": "11494",
              "name": "Амман",
              "count": 5
            },
            {
              "id": "40266",
              "name": "Джераш",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10540",
      "name": "Саудовская Аравия",
      "count": 5,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 5,
          "chld": [
            {
              "id": "11500",
              "name": "Эр-Рияд",
              "count": 3
            },
            {
              "id": "40419",
              "name": "Даммам",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "10109",
      "name": "Шри-Ланка",
      "count": 5,
      "chld": [
        {
          "id": "144608",
          "name": "Западная провинция",
          "count": 3,
          "chld": [
            {
              "id": "10633",
              "name": "Коломбо",
              "count": 3
            }
          ]
        },
        {
          "id": "144606",
          "name": "Южная провинция",
          "count": 1
        }
      ]
    },
    {
      "id": "98552",
      "name": "Палестина",
      "count": 4
    },
    {
      "id": "10097",
      "name": "Малайзия",
      "count": 4,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 3,
          "chld": [
            {
              "id": "10604",
              "name": "Куала-Лумпур",
              "count": 3
            }
          ]
        },
        {
          "id": "121374",
          "name": "Штат Селангор",
          "count": 1,
          "chld": [
            {
              "id": "123497",
              "name": "Петалинг Джайя",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10098",
      "name": "Мальдивы",
      "count": 4,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 3,
          "chld": [
            {
              "id": "10605",
              "name": "Мале",
              "count": 3
            }
          ]
        }
      ]
    },
    {
      "id": "20975",
      "name": "Камбоджа",
      "count": 3,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 3,
          "chld": [
            {
              "id": "21316",
              "name": "Пномпень",
              "count": 3
            }
          ]
        }
      ]
    },
    {
      "id": "21486",
      "name": "Катар",
      "count": 3,
      "chld": [
        {
          "id": "219591",
          "name": "Эд-Доха",
          "count": 3,
          "chld": [
            {
              "id": "21487",
              "name": "Доха",
              "count": 3
            }
          ]
        }
      ]
    },
    {
      "id": "10017",
      "name": "Куба",
      "count": 3,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 3,
          "chld": [
            {
              "id": "10137",
              "name": "Гавана",
              "count": 2
            },
            {
              "id": "21354",
              "name": "Варадеро",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "20972",
      "name": "Лаос",
      "count": 3,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 2,
          "chld": [
            {
              "id": "21394",
              "name": "Вьентьян",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "10069",
      "name": "Мальта",
      "count": 3,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 2,
          "chld": [
            {
              "id": "122110",
              "name": "Слима",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "20862",
      "name": "Чили",
      "count": 3,
      "chld": [
        {
          "id": "123323",
          "name": "Столичная область",
          "count": 2,
          "chld": [
            {
              "id": "20963",
              "name": "Сантьяго",
              "count": 2
            }
          ]
        },
        {
          "id": "123325",
          "name": "Область Арика-и-Паринакота",
          "count": 1,
          "chld": [
            {
              "id": "20863",
              "name": "Арика",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "20826",
      "name": "Алжир",
      "count": 3,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 2,
          "chld": [
            {
              "id": "20828",
              "name": "Алжир",
              "count": 1
            },
            {
              "id": "101601",
              "name": "Аннаба",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "20785",
      "name": "Эквадор",
      "count": 3,
      "chld": [
        {
          "id": "123369",
          "name": "Провинция Гуаяс",
          "count": 1,
          "chld": [
            {
              "id": "21432",
              "name": "Гуаякиль",
              "count": 1
            }
          ]
        },
        {
          "id": "123378",
          "name": "Провинция Пичинча",
          "count": 1,
          "chld": [
            {
              "id": "20786",
              "name": "Кито",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10054",
      "name": "Албания",
      "count": 2,
      "chld": [
        {
          "id": "105602",
          "name": "Область Тирана",
          "count": 2,
          "chld": [
            {
              "id": "10374",
              "name": "Тирана",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "10090",
      "name": "Афганистан",
      "count": 2
    },
    {
      "id": "21156",
      "name": "Перу",
      "count": 2,
      "chld": [
        {
          "id": "105750",
          "name": "Регион Ла-Либертад",
          "count": 2,
          "chld": [
            {
              "id": "84501",
              "name": "Трухильо",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "10542",
      "name": "Сирия",
      "count": 2,
      "chld": [
        {
          "id": "144740",
          "name": "Столичная мухафаза",
          "count": 2,
          "chld": [
            {
              "id": "11512",
              "name": "Дамаск",
              "count": 2
            }
          ]
        }
      ]
    },
    {
      "id": "10091",
      "name": "Бангладеш",
      "count": 2,
      "chld": [
        {
          "id": "144720",
          "name": "Область Дакка",
          "count": 1,
          "chld": [
            {
              "id": "10530",
              "name": "Дакка",
              "count": 1
            }
          ]
        },
        {
          "id": "144722",
          "name": "Область Раджшахи",
          "count": 1,
          "chld": [
            {
              "id": "21508",
              "name": "Ишварди",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10057",
      "name": "Босния и Герцеговина",
      "count": 2,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 2,
          "chld": [
            {
              "id": "111208",
              "name": "Зеница",
              "count": 1
            },
            {
              "id": "10387",
              "name": "Сараево",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "20572",
      "name": "Ирак",
      "count": 2,
      "chld": [
        {
          "id": "144761",
          "name": "Мухафаза Мутанна",
          "count": 1
        },
        {
          "id": "144767",
          "name": "Мухафаза Нинава",
          "count": 1,
          "chld": [
            {
              "id": "40608",
              "name": "Мосул",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10020",
      "name": "Марокко",
      "count": 2,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 2,
          "chld": [
            {
              "id": "10138",
              "name": "Касабланка",
              "count": 1
            },
            {
              "id": "10139",
              "name": "Рабат",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10024",
      "name": "Тунис",
      "count": 2,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "10143",
              "name": "Тунис",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10021",
      "name": "ЮАР",
      "count": 2,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 2,
          "chld": [
            {
              "id": "21438",
              "name": "Йоханнесбург",
              "count": 1
            },
            {
              "id": "10140",
              "name": "Претория",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10088",
      "name": "Андорра",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "10524",
              "name": "Андорра-ла-Велья",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21184",
      "name": "Венесуэла",
      "count": 1,
      "chld": [
        {
          "id": "123181",
          "name": "Федеральный округ Венесуэлы",
          "count": 1,
          "chld": [
            {
              "id": "21185",
              "name": "Каракас",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21562",
      "name": "Восточный Тимор",
      "count": 1
    },
    {
      "id": "21175",
      "name": "Гондурас",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "109603",
              "name": "Сан-Педро-Сула",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21426",
      "name": "Гренада",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "104637",
              "name": "Сент-Джорджес",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21196",
      "name": "Замбия",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "21197",
              "name": "Лусака",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21551",
      "name": "Йемен",
      "count": 1,
      "chld": [
        {
          "id": "144781",
          "name": "Столичная Мухафаза",
          "count": 1,
          "chld": [
            {
              "id": "21569",
              "name": "Сана",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10537",
      "name": "Кувейт",
      "count": 1
    },
    {
      "id": "10538",
      "name": "Ливан",
      "count": 1,
      "chld": [
        {
          "id": "144745",
          "name": "Мухафаза Бейрут",
          "count": 1,
          "chld": [
            {
              "id": "11497",
              "name": "Бейрут",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21235",
      "name": "Мозамбик",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "21255",
              "name": "Мапуту",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21217",
      "name": "Намибия",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "21378",
              "name": "Виндхук",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10101",
      "name": "Непал",
      "count": 1,
      "chld": [
        {
          "id": "144621",
          "name": "Багмати",
          "count": 1,
          "chld": [
            {
              "id": "10613",
              "name": "Катманду",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "139",
      "name": "Новая Зеландия",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "21368",
              "name": "Окленд",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "10022",
      "name": "Сейшельские острова",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "10141",
              "name": "Виктория",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21208",
      "name": "Танзания",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "21484",
              "name": "Додома",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21230",
      "name": "Уганда",
      "count": 1,
      "chld": [
        {
          "id": "4294967294",
          "name": "Без области",
          "count": 1,
          "chld": [
            {
              "id": "114435",
              "name": "Энтеббе",
              "count": 1
            }
          ]
        }
      ]
    },
    {
      "id": "21289",
      "name": "Уругвай",
      "count": 1,
      "chld": [
        {
          "id": "123403",
          "name": "Департамент Монтевидео",
          "count": 1,
          "chld": [
            {
              "id": "21290",
              "name": "Монтевидео",
              "count": 1
            }
          ]
        }
      ]
    }
  ]