import { Modal, Typography, MenuItem } from "@material-ui/core";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FORMIK_INITIAL_VALUES = {
  staff: "",
};

const CreateSegmentSchema = Yup.object().shape({
  staff: Yup.string().required(),
});

function EditStaffModal({
  modal,
  handleCloseModal,
  confirm,
  setConfirm,
  client,
  handleEditManager,
  arrStaff,
}) {
  const formik = useFormik({
    initialValues: FORMIK_INITIAL_VALUES,
    validationSchema: CreateSegmentSchema,
    onSubmit: () => {
      formik.setValues(FORMIK_INITIAL_VALUES);
    },
  });
  return (
    <Modal
      open={modal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Привязать сотрудника
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Клиент: {client.id}
        </Typography>
        <Box sx={{ width: "100%" }}>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Сотрудник</InputLabel>
            <Select
              style={{
                height: 56,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              // sx={{ "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": { borderColor: "transparent" } }}
              label="Сотрудник"
              id="demo-select-small-label"
              value={formik.values.staff}
              onChange={(e) => formik.setFieldValue("staff", e.target.value)}
            >
              {arrStaff?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.full_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!confirm && (
          <div onClick={() => setConfirm(true)} className="editBtn">
            <span>Изменить</span>
          </div>
        )}
        {confirm && (
          <div className="btnGroup">
            <div
              className="resetBtn"
              onClick={() => {
                handleCloseModal();
                formik.setValues(FORMIK_INITIAL_VALUES);
              }}
            >
              <span>Отменить</span>
            </div>
            <div
              className="confirmBtn"
              onClick={() => handleEditManager(client.id, formik.values.staff)}
            >
              <span>Подтвердить</span>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
}

export default EditStaffModal;
