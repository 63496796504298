export const INIT_FILTER_VALUE = {
  date: {
    date_end: "",
    date_start: "",
  },
  deposite: {
    start: "",
    end: "",
  },
  balance: {
    start: "",
    end: "",
  },
  decrease: {
    start: "",
    end: "",
  },
  type: "",
  phone: "",
  inn: "",
  staff: "",
  staffArr: [],
  site: "",
  id: "",
  email: "",
  full_name: "",
  sort: "created_at",
};

export const filterDate = (oDate, arr) => {
  const tempDate = {
    date_end: oDate.date_end || new Date(3000, 10, 10),
    date_start: oDate.date_start || new Date(3, 10, 10),
  };
  const result = arr.filter((user) => {
    if (!oDate.date_start && !oDate.date_end) return true;

    const date = new Date(user?.created_at);
    const isInside = tempDate.date_start <= date && tempDate.date_end >= date;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterEmail = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.email.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterPhone = (keyword, arr) => {
  // eslint-disable-next-line no-useless-escape
  keyword = keyword.replace(/[\s\-\(\)]/g, "");
  const results = arr.filter((user) => {
    return user.phone_number?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterId = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.id?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterType = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.is_entity === keyword;
  });
  return results;
};
export const filterSite = (keyword, arr) => {
  const results = arr.filter((user) => {
    return user.site?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterInn = (keyword, arr) => {
  const results = arr.filter((user) => {
    return String(user.inn)?.toLowerCase().includes(keyword.toLowerCase());
  });
  return results;
};
export const filterBalance = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((user) => {
    if (!range.start && !range.end) return true;
    const isInside =
      tempRange.range_start <= user.balance_score &&
      tempRange.range_end >= user.balance_score;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterDeposit = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((user) => {
    if (!range.start && !range.end) return true;
    const isInside =
      tempRange.range_start <= user.deposits_amount &&
      tempRange.range_end >= user.deposits_amount;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterDecrease = (range, arr) => {
  const tempRange = {
    range_end: range.end || 10000000,
    range_start: range.start || 0,
  };
  const result = arr.filter((user) => {
    if (!range.start && !range.end) return true;
    const isInside =
      tempRange.range_start <= user.charges_amount &&
      tempRange.range_end >= user.charges_amount;
    if (isInside) return true;

    return false;
  });
  return result;
};
export const filterStaff = (keyword, arr, staff) => {
  const res = staff
    .filter((user) => {
      const name = user.full_name.split(" ")[0];
      const surname = user.full_name.split(" ")[1];
      if (keyword.includes(" ")) {
        return user.full_name.toLowerCase().startsWith(keyword.toLowerCase());
      }
      if (name.toLowerCase().startsWith(keyword.toLowerCase())) {
        return true;
      } else if (surname.toLowerCase().startsWith(keyword.toLowerCase())) {
        return true;
      }
      return false;
    })
    .map((el) => el.id);
  const result = arr.filter((el) => res.includes(el.manager_id));
  return result;
};
export const filterStaffArr = (keyword, arr, staff) => {
  const res = staff.filter((el) => keyword.includes(el.id)).map((el) => el.id);
  console.log(arr);
  const result = arr.filter((el) => res.includes(el.manager_id));
  return result;
};
export const filterFio = (keyword, arr) => {
  const results = arr.filter((user) => {
    const name = user.full_name.split(" ")[0];
    const surname = user.full_name.split(" ")[1];
    if (keyword.includes(" ")) {
      return user.full_name.toLowerCase().startsWith(keyword.toLowerCase());
    }
    if (name.toLowerCase().startsWith(keyword.toLowerCase())) {
      return true;
    } else if (surname.toLowerCase().startsWith(keyword.toLowerCase())) {
      return true;
    }
    return false;
  });
  return results;
};
export const sortClients = (type, arr) => {
  switch (type) {
    case "created_at":
      return [...arr].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    case "created_at asc":
      return [...arr].sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
    case "balance_score":
      return [...arr].sort((a, b) => b.balance_score - a.balance_score);
    case "balance_score asc":
      return [...arr].sort((a, b) => a.balance_score - b.balance_score);
    default:
      return arr;
  }
};
