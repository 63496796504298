export const regionsData = {
  "RU-*": "Вся Россия",
  "RU-MOW": "Москва",
  "RU-SPE": "Санкт-Петербург",
  "RU-AD": "Республика Адыгея (Адыгея)",
  "RU-AL": "Республика Алтай",
  "RU-BA": "Республика Башкортостан",
  "RU-BU": "Республика Бурятия",
  "RU-DA": "Республика Дагестан",
  "UA-14": "Донецкая Народная Республика",
  "RU-IN": "Республика Ингушетия",
  "RU-KB": "Кабардино-Балкарская Республика",
  "RU-KL": "Республика Калмыкия",
  "RU-KC": "Карачаево-Черкесская Республика",
  "RU-KR": "Республика Карелия",
  "RU-KO": "Республика Коми",
  "UA-43": "Республика Крым",
  "UA-09": "Луганская Народная Республика",
  "RU-ME": "Республика Марий Эл",
  "RU-MO": "Республика Мордовия",
  "RU-SA": "Республика Саха (Якутия)",
  "RU-SE": "Республика Северная Осетия — Алания",
  "RU-TA": "Республика Татарстан (Татарстан)",
  "RU-TY": "Республика Тыва",
  "RU-UD": "Удмуртская Республика",
  "RU-KK": "Республика Хакасия",
  "RU-CE": "Чеченская Республика",
  "RU-CU": "Чувашская Республика — Чувашия",
  "RU-ALT": "Алтайский край",
  "RU-ZAB": "Забайкальский край",
  "RU-KAM": "Камчатский край",
  "RU-KDA": "Краснодарский край",
  "RU-KYA": "Красноярский край",
  "RU-PER": "Пермский край",
  "RU-PRI": "Приморский край",
  "RU-STA": "Ставропольский край",
  "RU-KHA": "Хабаровский край",
  "RU-AMU": "Амурская область",
  "RU-ARK": "Архангельская область",
  "RU-AST": "Астраханская область",
  "RU-BEL": "Белгородская область",
  "RU-BRY": "Брянская область",
  "RU-VLA": "Владимирская область",
  "RU-VGG": "Волгоградская область",
  "RU-VLG": "Вологодская область",
  "RU-VOR": "Воронежская область",
  "UA-23": "Запорожская область",
  "RU-IVA": "Ивановская область",
  "RU-IRK": "Иркутская область",
  "RU-KGD": "Калининградская область",
  "RU-KLU": "Калужская область",
  "RU-KEM": "Кемеровская область — Кузбасс",
  "RU-KIR": "Кировская область",
  "RU-KOS": "Костромская область",
  "RU-KGN": "Курганская область",
  "RU-KRS": "Курская область",
  "RU-LEN": "Ленинградская область",
  "RU-LIP": "Липецкая область",
  "RU-MAG": "Магаданская область",
  "RU-MOS": "Московская область",
  "RU-MUR": "Мурманская область",
  "RU-NIZ": "Нижегородская область",
  "RU-NGR": "Новгородская область",
  "RU-NVS": "Новосибирская область",
  "RU-OMS": "Омская область",
  "RU-ORE": "Оренбургская область",
  "RU-ORL": "Орловская область",
  "RU-PNZ": "Пензенская область",
  "RU-PSK": "Псковская область",
  "RU-ROS": "Ростовская область",
  "RU-RYA": "Рязанская область",
  "RU-SAM": "Самарская область",
  "RU-SAR": "Саратовская область",
  "RU-SAK": "Сахалинская область",
  "RU-SVE": "Свердловская область",
  "RU-SMO": "Смоленская область",
  "RU-TAM": "Тамбовская область",
  "RU-TVE": "Тверская область",
  "RU-TOM": "Томская область",
  "RU-TUL": "Тульская область",
  "RU-TYU": "Тюменская область",
  "RU-ULY": "Ульяновская область",
  "UA-65": "Херсонская область",
  "RU-CHE": "Челябинская область",
  "RU-YAR": "Ярославская область",
  "UA-40": "Севастополь",
  "RU-YEV": "Еврейская автономная область",
  "RU-NEN": "Ненецкий автономный округ",
  "RU-KHM": "Ханты-Мансийский автономный округ — Югра",
  "RU-CHU": "Чукотский автономный округ",
  "RU-YAN": "Ямало-Ненецкий автономный округ",
  "KZ-BAY": "Байконур",
  "UA-63": "Харьковская область"
}