import { Modal, TextField, Typography, MenuItem } from "@material-ui/core";
import {
  Box,
  FormControl,
  FormHelperText,
  Select,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FORMIK_INITIAL_VALUES = {
  sum: "",
  type: "",
};

const CreateSegmentSchema = Yup.object().shape({
  sum: Yup.string().matches(/^\d+$/).required(),
  type: Yup.string().required(),
});
function numberWithSpaces(number) {
  if (number < 10000) return number;
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function DepositModal({
  modal,
  handleCloseModal,
  confirm,
  setConfirm,
  client,
  handleEditBalance,
}) {
  const formik = useFormik({
    initialValues: FORMIK_INITIAL_VALUES,
    validationSchema: CreateSegmentSchema,
    onSubmit: () => {
      handleCloseModal();
      handleEditBalance(
        client.balance_id,
        formik.values.sum,
        formik.values.type
      );
      formik.setValues(FORMIK_INITIAL_VALUES);
      //formik.setValues(FORMIK_INITIAL_VALUES);
    },
  });
  return (
    <Modal
      open={modal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Изменить баланс клиента
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{
              marginBottom: 20,
              marginTop: 20,
              color: "#42454C",
              opacity: "70%",
            }}
          >
            Клиент: {client.id?.slice(0, 8)} ({client.email})
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{
              marginBottom: 20,
              marginTop: 20,
              color: "#42454C",
              opacity: "70%",
            }}
          >
            Текущий баланс: {numberWithSpaces(client.balance_score)}
          </Typography>
          <Box style={{ marginBottom: 20 }} sx={{ width: "100%" }}>
            <FormControl fullWidth size="small">
              <Select
                style={{
                  height: 56,
                  background: "#FFFFFF",
                  borderColor: "transparent",
                }}
                displayEmpty
                renderValue={
                  formik.values.type !== ""
                    ? undefined
                    : () => (
                        <div style={{ color: "#aaa" }}>Пополнение/Списание</div>
                      )
                }
                id="demo-select-small-label"
                value={formik.values.type}
                error={formik.errors.type && formik.touched.type}
                onChange={(e) => formik.setFieldValue("type", e.target.value)}
              >
                <MenuItem value={"Пополнение"}>Пополнение</MenuItem>
                <MenuItem value={"Списание"}>Списание</MenuItem>
              </Select>
              {formik.errors.type && formik.touched.type && (
                <FormHelperText error={true}>Обязательное поле</FormHelperText>
              )}
            </FormControl>
          </Box>
          <FormControl style={{ width: "100%" }}>
            <TextField
              error={formik.errors.sum && formik.touched.sum}
              helperText={
                formik.errors.sum && formik.touched.sum ? (
                  "Только числовые значения"
                ) : (
                  <br />
                )
              }
              name="sum"
              onChange={formik.handleChange}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              placeholder="Введите сумму пополнения/списания"
            />
          </FormControl>
          {!confirm && (
            <div onClick={() => setConfirm(true)} className="editBtn">
              <button type="submit">Изменить</button>
            </div>
          )}
          {confirm && (
            <div className="btnGroup">
              <div
                className="resetBtn"
                onClick={() => {
                  handleCloseModal();
                  formik.setValues(FORMIK_INITIAL_VALUES);
                }}
              >
                <span>Отменить</span>
              </div>
              <div className="confirmBtn">
                <button type="submit">Подтвердить</button>
              </div>
            </div>
          )}
        </Box>
      </form>
    </Modal>
  );
}

export default DepositModal;
