import { Box, Modal, Typography } from "@material-ui/core";
import { API_HOST } from "../../../configs/main";
import { useSnackbar } from "notistack";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function BanModal({ BanModal, handleCloseBan, user }) {
  const { enqueueSnackbar } = useSnackbar();
  const handleBan = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/users/${user.id}`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        is_active: false,
      }),
    })
      .then(() =>
        enqueueSnackbar("Клиент заблокирован", {
          variant: "success",
          autoHideDuration: 1000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
      )
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: 1000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
      );
  };

  return (
    <Modal
      open={BanModal}
      onClose={handleCloseBan}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Деактивировать аккаунт клиента
        </Typography>
        <Typography
          id="modal-modal-description"
          style={{
            marginBottom: 20,
            marginTop: 20,
            color: "#42454C",
            opacity: "70%",
          }}
        >
          Клиент: {user?.id}
        </Typography>
        <div className="buttonGroup">
          <div
            className="resetButton"
            onClick={() => {
              handleCloseBan();
            }}
          >
            Отменить
          </div>
          <div
            className="confirmButton"
            onClick={() => {
              handleBan();
              handleCloseBan();
            }}
          >
            Подтвердить
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default BanModal;
