import { useEffect, useState } from "react";
import "./Staff.scss";
import {
  INIT_FILTER_VALUE,
  filterEmail,
  filterFio,
  filterRole,
} from "./filters";
import { MenuItem } from "@material-ui/core";
import { Box, FormControl, InputLabel, Select } from "@mui/material";
import EditRoleModal from "./EditRoleModal";
import AddStaffModal from "./AddStaffModal";
import BanModal from "./BanModal";
import { API_HOST } from "../../../configs/main";
import useAdminUser from "../../../data/useAdminUser";
import { ContentCopy } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import DataTable from "../Table/Datatable";

function removeEmpty(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object") {
      removeEmpty(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (obj[key] === undefined || obj[key] === null || obj[key] === "") {
      delete obj[key];
    }
  });
  return obj;
}
const TYPE_OF_ROLE = {
  ADMIN: "Администратор",
  SUPERUSER: "Суперюзер",
  MANAGER: "Менеджер",
};

function Staff() {
  const { enqueueSnackbar } = useSnackbar();
  const { role } = useAdminUser();
  const [filterArr, setFilter] = useState([]);
  const [arrStaff, setStaff] = useState([]);
  const [confirmRole, setConfirmRole] = useState(false);
  const [confirmUser, setConfirmUser] = useState(false);
  const [editRoleModal, setRoleModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [banModal, setBanModal] = useState(false);
  const [current, setCurent] = useState({});
  const [filtersValue, setValue] = useState(INIT_FILTER_VALUE);

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/employee`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({}),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          setStaff(data);
          setFilter(data);
        });
      }
    });
  }, []);

  const handleOpenRole = (el) => {
    setRoleModal(true);
    setCurent(el);
  };
  const handleOpenBan = (el) => {
    setBanModal(true);
    setCurent(el);
  };
  const handleCloseBan = () => {
    setBanModal(false);
  };
  const handleCloseRole = () => {
    setRoleModal(false);
    setConfirmRole(false);
  };
  const handleOpenUser = () => setAddUserModal(true);
  const handleCloseUser = () => {
    setAddUserModal(false);
    setConfirmUser(false);
  };

  const downloadFile = async () => {
    const filters = {
      full_name: filtersValue.name ? filtersValue.name : undefined,
      email: filtersValue.email ? filtersValue.email : undefined,
      role: filtersValue.role ? filtersValue.role : undefined,
    };
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const response = await fetch(`${API_HOST}/api/v1/admin/employee/download`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(removeEmpty(filters)),
    });
    if (response.ok) {
      const link = document.createElement("a");
      let filename = response.headers
        ?.get("content-disposition")
        .split("filename=")[1];
      const blob = await response.blob();
      if (filename === null) filename = "Employees.csv";
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleEditRole = (id, newRole) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    fetch(`${API_HOST}/api/v1/admin/employee/${id}`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({
        role: newRole,
      }),
    })
      .then((res) => {
        if (res.ok) {
          setValue(INIT_FILTER_VALUE);
          setFilter((prev) =>
            prev.map((el) => {
              if (el.id === id) {
                return { ...el, role: newRole };
              }
              return el;
            })
          );
        }
        enqueueSnackbar("Роль сотрудника изменена", {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        handleCloseRole();
      })
      .catch((error) =>
        enqueueSnackbar(error.message, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        })
      );
  };

  const handleAddStaff = async (staff) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    myHeaders.append("Content-Type", "application/json");
    try {
      const res = await fetch(`${API_HOST}/api/v1/admin/auth/registration`, {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          first_name: staff.first_name,
          surname: staff.surname,
          email: staff.email,
          role: staff.role,
        }),
      });
      setValue(INIT_FILTER_VALUE);
      setFilter(arrStaff);
      if (res.ok) {
        enqueueSnackbar("Сотрудник добавлен", {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      } else {
        const data = await res.json();
        enqueueSnackbar(data.detail[0].msg, {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
    handleCloseUser();
  };

  useEffect(() => {
    const result = Object.entries(filtersValue).reduce(
      (acc, [key, value]) => {
        switch (key) {
          case "name":
            if (value === "") return acc;
            return filterFio(value, acc);
          case "email":
            if (value === "") return acc;
            return filterEmail(value, acc);
          case "role":
            if (value === "") return acc;
            return filterRole(value, acc);
          default:
            return acc;
        }
      },
      [...arrStaff]
    );
    setFilter(result);
  }, [filtersValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = [
    {
      Header: "ID сотрудника",
      accessor: "id",
      maxWidth: 400,
      minWidth: 80,
      width: 150,
    },
    {
      Header: "Фамилия Имя",
      accessor: "full_name",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Логин (email)",
      accessor: "email",
      maxWidth: 400,
      minWidth: 80,
      width: 250,
    },
    {
      Header: "Подтв.",
      accessor: "is_email_confirmed",
      maxWidth: 400,
      minWidth: 80,
      width: 50,
    },
    {
      Header: "Роль (права)",
      accessor: "role",
      maxWidth: 400,
      minWidth: 80,
      width: 175,
    },
    {
      Header: "",
      accessor: "ban",
      maxWidth: 400,
      minWidth: 50,
      width: 150,
    },
  ];

  function getTableData() {
    return filterArr.map((el) => {
      return {
        id: (
          <div
            className="td"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span style={{ display: "flex" }}>
              {el.id.slice(0, 8)}
              <ContentCopy
                style={{ marginLeft: 10, cursor: "pointer" }}
                onClick={() => {
                  navigator.clipboard.writeText(el.id);
                  enqueueSnackbar("ID пользователя скопирован", {
                    variant: "success",
                    autoHideDuration: 1000,
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                }}
                className="copyIcon"
                fontSize="small"
              />
            </span>
            {!el.is_active && <span style={{ opacity: "70%" }}>blocked</span>}
          </div>
        ),
        full_name: (
          <div className="td">{el.full_name ? el.full_name : "-"}</div>
        ),
        email: <div className="td">{el.email ? el.email : "-"}</div>,
        is_email_confirmed: (
          <div className="td" style={{ width: 50 }}>
            {el.is_email_confirmed ? "+" : "-"}
          </div>
        ),
        role: (
          <div className="td">
            {TYPE_OF_ROLE[el.role]}
            {role === "SUPERUSER" && (
              <svg
                onClick={() => handleOpenRole(el)}
                style={{ marginLeft: 10, cursor: "pointer" }}
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.49968 1.25391C9.051 1.25391 8.60238 1.424 8.26237 1.76416L2.72868 7.29736C2.62369 7.40236 2.54679 7.53293 2.50603 7.67627L1.76433 10.2734C1.74603 10.3377 1.74524 10.4057 1.76204 10.4704C1.77883 10.535 1.8126 10.594 1.85985 10.6413C1.90709 10.6885 1.96611 10.7223 2.03078 10.7391C2.09545 10.7559 2.16344 10.7551 2.22771 10.7368L4.82536 9.99512L4.82585 9.99463C4.96823 9.9537 5.09848 9.87776 5.20378 9.77246L10.737 4.23877C11.4173 3.55849 11.4173 2.44444 10.737 1.76416C10.397 1.424 9.94835 1.25391 9.49968 1.25391ZM9.49968 1.99951C9.75488 1.99951 10.0097 2.09784 10.2062 2.29443H10.2067C10.6004 2.68815 10.6004 3.31478 10.2067 3.7085L9.72185 4.19336L8.30778 2.7793L8.79265 2.29443C8.98914 2.09784 9.24448 1.99951 9.49968 1.99951ZM7.77751 3.30957L9.19157 4.72363L4.67302 9.24219C4.65836 9.25675 4.6398 9.2679 4.61882 9.27393L2.67058 9.83057L3.22722 7.88184V7.88135C3.23295 7.86119 3.24345 7.84314 3.25896 7.82764L7.77751 3.30957Z"
                  fill="black"
                />
              </svg>
            )}
          </div>
        ),
        ban: (
          <div
            className="td ban"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {role === "SUPERUSER" && (
              <div
                className="td"
                onClick={() => handleOpenBan(el)}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Деактивировать
              </div>
            )}
          </div>
        ),
      };
    });
  }

  return (
    <div className="staff" style={{ width: "100%", paddingLeft: 0, height: "97%" }}>
      <div className="filters">
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, name: e.target.value }))
          }
          className="searchName"
          value={filtersValue.name}
          placeholder="Фамилия Имя сотрудника"
        />
        <input
          onChange={(e) =>
            setValue((prev) => ({ ...prev, email: e.target.value }))
          }
          className="searchEmail"
          value={filtersValue.email}
          placeholder="Email"
        />
        <Box sx={{ width: 175 }}>
          <FormControl fullWidth size="small">
            <InputLabel
              id="demo-select-small-label"
              sx={{ lineHeight: 1, opacity: "80%", fontSize: 14 }}
            >
              Роль (права)
            </InputLabel>
            <Select
              style={{
                height: 32,
                background: "#FFFFFF",
                borderColor: "transparent",
              }}
              sx={{
                "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
              label="Роль (права)"
              id="demo-select-small-label"
              value={filtersValue.role}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, role: e.target.value }))
              }
            >
              <MenuItem value={""}>Все сотрудники </MenuItem>
              <MenuItem value={"MANAGER"}>Менеджер</MenuItem>
              <MenuItem value={"ADMIN"}>Администратор</MenuItem>
              <MenuItem value={"SUPERUSER"}>Суперюзер</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {role === "SUPERUSER" && (
          <div onClick={() => handleOpenUser(true)} className="button">
            <span>Добавить сотрудника</span>
          </div>
        )}
        <AddStaffModal
          addUserModal={addUserModal}
          handleCloseUser={handleCloseUser}
          confirmUser={confirmUser}
          setConfirmUser={setConfirmUser}
          handleAddStaff={handleAddStaff}
        />
      </div>
      <div className="downald">
        <button onClick={() => downloadFile()} type="button">
          Скачать CSV
        </button>
      </div>
      <div style={{ overflowX: "auto", width: "100%", overflowY: "auto", height: "85%" }}>
        <DataTable data={getTableData()} columns={columns} />
      </div>
      <EditRoleModal
        editRoleModal={editRoleModal}
        handleCloseRole={handleCloseRole}
        confirmRole={confirmRole}
        setConfirmRole={setConfirmRole}
        handleEditRole={handleEditRole}
        el={current}
      />
      <BanModal
        BanModal={banModal}
        handleCloseBan={handleCloseBan}
        user={current}
      />
    </div>
  );
}

export default Staff;
