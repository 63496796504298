import React from "react";
import "./Reports.scss";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import deLocale from "date-fns/locale/ru";
import { TextField } from "@mui/material";
import { API_HOST } from "../../../configs/main";

function Report() {
  const [date, setDate] = React.useState(new Date());
  const downloadFile = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("admin_token")
    );
    const response = await fetch(
      `${API_HOST}/api/v1/admin/ord_report/download`,
      {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          month: date.getUTCMonth() + 1,
          year: date.getUTCFullYear(),
        }),
      }
    );
    if (response.ok) {
      const link = document.createElement("a");
      let filename = response.headers
        ?.get("content-disposition")
        .split("filename=")[1];
      const blob = await response.blob();
      if (filename === null) filename = "Reports.csv";
      link.download = filename;
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="reports">
      <div className="row">
        <div className="td">Отчеты для подачи в ОРД</div>
        <div className="check" />
        <div className="td">
          <div className="date">
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={deLocale}
            >
              <DatePicker
                style={{ window: 150, height: 39 }}
                views={["year", "month"]}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} error={false} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="check" />
        <div className="td" style={{ width: 175, textDecoration: "underline" }}>
          <button onClick={downloadFile}>Скачать CSV</button>
        </div>
      </div>
    </div>
  );
}

export default Report;
